import * as React from "react";
import { Formik, FormikErrors } from "formik";
import { IUser, IConfirmDialog } from "../../vm";
import {
  FormControl,
  Button,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { addAUser, updateAUser } from "../../services/account/UserService";
import { ToastContext } from "../common/ToastProvider";
import {
  USER_TYPE,
  EMP_TYPE,
  USER_CATEGORY,
  CAT_FOR_1099,
  COUNTRY_CODES,
  US_ZIP_CODE_REGEX,
  PHONE_REGEX,
} from "../../Constant";
import Loading from "../common/Loading";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
// import AsyncSelect from 'react-select/async';
import ReactSelect from "react-select";

const country_codes_values: Array<string> = JSON.parse(COUNTRY_CODES);
interface onCloseFunc {
  (data?: IUser, isAdd?: boolean): void;
}

interface IAddOrUpdateUserProps extends IConfirmDialog {
  onClose: onCloseFunc;
  user?: IUser;
  companyId: string;
}

const AddOrUpdateUser: React.FC<IAddOrUpdateUserProps> = ({
  onClose,
  user,
  companyId,
}) => {
  const { showToast } = React.useContext(ToastContext);
  const [isLoading, setLoading] = React.useState(false);
  const [isSubmited, setSubmitted] = React.useState(false);

  return (
    <div className="p-16">
      {isLoading && <Loading message="processing..." />}
      <Formik
        initialValues={
          user
            ? user
            : {
                firstName: "",
                middleName: "",
                lastName: "",
                companyId: companyId,
                email: "",
                phone: "",
                type: "user",
                userType: "w2",
                empType: "employee",
                isEnabled: true,
                address: {
                  street: "",
                  pin: "",
                  city: "",
                  country: "",
                  state: "",
                },
                category: "citizen",
                countryCode: "+1",
              }
        }
        validate={(values: IUser) => {
          const errors: FormikErrors<IUser> = {};
          if (!values.firstName) errors.firstName = "Required";
          if (!values.lastName) errors.lastName = "Required";
          if (!values.email) errors.email = "Required";
          if (values.phone && PHONE_REGEX.test(values.phone) === false) {
            errors.phone = "Please enter phone number of format 9876543210";
          }
          if (values.phone != null) {
            if (!values.countryCode) errors.countryCode = "Required";
          }

          if (values._id == null) {
            if (!values.type) errors.type = "Required";

            if (values.type === "user") {
              if (!values.userType || (values.userType as any) === "none")
                errors.userType = "Required";
              if (!values.empType || (values.empType as any) === "none")
                errors.empType = "Required";
            }
            if (!values.category) errors.category = "Required";

            if (values.userType === "1099") {
              if (!CAT_FOR_1099.includes(values.category as any)) {
                errors.category = "Required";
              }
            }
          }
          if (!values.address?.street) {
            errors.streetE = "Required";
          }
          if (!values.address?.city) {
            errors.cityE = "Required";
          }
          if (!values.address?.state) {
            errors.stateE = "Required";
          }
          if (!values.address?.country) {
            errors.countryE = "Required";
          }
          if (!values.address?.pin) {
            errors.pinE = "Required";
          }
          if (
            values.address&&values.address.pin &&
            US_ZIP_CODE_REGEX.test(values.address.pin) === false
          ) {
            errors.pinE = "Invalid zip code";
          }
          // address
          // if (!values?.address?.street) {
          //   if (!errors.address) errors.address = {};
          //   errors.address.street = 'Required';
          // }
          // if (!values?.address?.pin) {
          //   if (!errors.address) errors.address = {};
          //   errors.address.pin = 'Required';
          // }
          // if (!values?.address?.city) {
          //   if (!errors.address) errors.address = {};
          //   errors.address.city = 'Required';
          // }
          // if (!values?.address?.state) {
          //   if (!errors.address) errors.address = {};
          //   errors.address.state = 'Required';
          // }
          // if (!values?.address?.country) {
          //   if (!errors.address) errors.address = {};
          //   errors.address.country = 'Required';
          // }

          return errors;
        }}
        onSubmit={async (
          values: IUser,
          { setSubmitting }: { setSubmitting: Function }
        ) => {
          setLoading(true);
          
          console.log("user", values);
          const obj = { ...values };
          if (obj.type === "partner") {
            delete obj.empType;
            delete obj.userType;
          }
          delete obj.streetE
          delete obj.cityE
          delete obj.stateE
          delete obj.pinE
          delete obj.countryE
          // obj.companyId = companyId

          let result;
          if (obj._id) {
            result = await updateAUser(obj._id, obj);
          } else {
            result = await addAUser(obj);
          }

          if (result?.success) {
            onClose(result.data, obj._id ? false : true);

            showToast(
              `${obj._id ? "Updated" : "Added"} successfully`,
              "success"
            );
          } else {
            showToast(
              result?.message ? result.message : "Error while adding associate",
              "error"
            );
          }
          setSubmitting(false);
          setLoading(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="firstName"
                    label="First Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.firstName && touched.firstName ? true : false}
                    helperText={
                      errors.firstName && touched.firstName && errors.firstName
                    }
                    value={values.firstName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    size="small"
                    name="middleName"
                    label="Middle Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.middleName && touched.middleName ? true : false
                    }
                    helperText={
                      errors.middleName &&
                      touched.middleName &&
                      errors.middleName
                    }
                    value={values.middleName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="lastName"
                    label="Last Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.lastName && touched.lastName ? true : false}
                    helperText={
                      errors.lastName && touched.lastName && errors.lastName
                    }
                    value={values.lastName}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="email"
                    type="email"
                    label="Email"
                    autoComplete="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email ? true : false}
                    helperText={errors.email && touched.email && errors.email}
                    value={values.email}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={4}
                sm={3}
                className="custom-react-select-form mt-16i"
              >
                <InputLabel className="custom-react-select-label">
                  Country code
                </InputLabel>
                <ReactSelect
                  // isSearchable
                  value={{
                    value: values.countryCode,
                    label: values.countryCode,
                  }}
                  className="async-select react-select"
                  placeholder="Country code"
                  // autoload={true}
                  isClearable={false}
                  options={country_codes_values.map((x) => {
                    return {
                      label: x,
                      value: x,
                    };
                  })}
                  // defaultOptions={['+1', '+44', '+91'].map((x) => {
                  //   return {
                  //     label: x,
                  //     value: x,
                  //   };
                  // })}
                  onChange={(newValue: any) => {
                    console.log("newValue", newValue);
                    setFieldValue("countryCode", newValue?.value);
                  }}
                />
                {errors.countryCode && (
                  <Typography variant="caption" color="error">
                    &nbsp; &nbsp;{errors.countryCode}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={8} sm={9}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="phone"
                    label="Phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.phone && touched.phone ? true : false}
                    helperText={errors.phone && touched.phone && errors.phone}
                    value={values.phone}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="address.street"
                    label="Street"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      isSubmited&&errors?.streetE ? true : false
                    }
                    helperText={
                      isSubmited&&errors?.streetE && errors.streetE
                    }
                    // error={
                    //   errors?.address?.street && touched?.address?.street
                    //     ? true
                    //     : false
                    // }
                    // helperText={
                    //   errors?.address?.street &&
                    //   touched?.address?.street &&
                    //   errors.address.street
                    // }
                    value={values?.address?.street}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="address.pin"
                    label="Zip Code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      isSubmited&& errors?.pinE
                        ? true
                        : false
                    }
                    helperText={
                      isSubmited&&errors?.pinE &&
                      errors.pinE
                    }
                    // error={
                    //   errors?.address?.pin && touched?.address?.pin
                    //     ? true
                    //     : false
                    // }
                    // helperText={
                    //   errors?.address?.pin &&
                    //   touched?.address?.pin &&
                    //   errors.address.pin
                    // }
                    value={values?.address?.pin}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="address.city"
                    label="City"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      isSubmited&&errors?.cityE
                        ? true
                        : false
                    }
                    helperText={
                      isSubmited&& errors?.cityE &&
                      errors.cityE
                    }
                    // error={
                    //   errors?.address?.city && touched?.address?.city
                    //     ? true
                    //     : false
                    // }
                    // helperText={
                    //   errors?.address?.city &&
                    //   touched?.address?.city &&
                    //   errors.address.city
                    // }
                    value={values?.address?.city}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="address.state"
                    label="State"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      isSubmited&& errors?.stateE 
                        ? true
                        : false
                    }
                    helperText={
                      isSubmited&& errors?.stateE &&
                      errors.stateE
                    }
                    // error={
                    //   errors?.address?.state && touched?.address?.state
                    //     ? true
                    //     : false
                    // }
                    // helperText={
                    //   errors?.address?.state &&
                    //   touched?.address?.state &&
                    //   errors.address.state
                    // }
                    value={values?.address?.state}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="address.country"
                    label="Country"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      isSubmited&& errors?.countryE 
                        ? true
                        : false
                    }
                    helperText={
                      isSubmited&& errors?.countryE &&
                      errors.countryE
                    }
                    // error={
                    //   errors?.address?.country && touched?.address?.country
                    //     ? true
                    //     : false
                    // }
                    // helperText={
                    //   errors?.address?.country &&
                    //   touched?.address?.country &&
                    //   errors.address.country
                    // }
                    value={values?.address?.country}
                  />
                </FormControl>
              </Grid>

              {/* {values._id == null && (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl margin='normal' size='small' fullWidth required>
                    <InputLabel className='select-label' htmlFor='type'>
                      Type
                    </InputLabel>
                    <Select
                      error={errors.type && touched.type ? true : false}
                      value={values.type}
                      name='type'
                      onChange={handleChange}
                      inputProps={{
                        id: 'type',
                      }}>
                      {Object.keys(TYPE).map((index: string) => (
                        <MenuItem value={index} key={index}>
                          {TYPE[index]}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.type && touched.type && (
                      <Typography variant='caption' color='error'>
                        &nbsp; &nbsp;{errors.type}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              )} */}

              {values.type === "user" && (
                <>
                  {values._id == null && (
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <FormControl margin="normal" size="small" fullWidth>
                        <InputLabel className="select-label" htmlFor="userType">
                          User Type
                        </InputLabel>
                        <Select
                          error={
                            errors.userType && touched.userType ? true : false
                          }
                          value={values.userType}
                          name="userType"
                          onChange={handleChange}
                          inputProps={{
                            id: "userType",
                          }}
                        >
                          {/* <MenuItem value={'none'}>
                          <em>None</em>
                        </MenuItem> */}
                          {Object.keys(USER_TYPE).map((index: string) => (
                            <MenuItem value={index} key={index}>
                              {USER_TYPE[index]}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.userType && touched.userType && (
                          <Typography variant="caption" color="error">
                            &nbsp; &nbsp;{errors.userType}
                          </Typography>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl margin="normal" size="small" fullWidth>
                      <InputLabel className="select-label" htmlFor="empType">
                        Employee Type
                      </InputLabel>
                      <Select
                        error={errors.empType && touched.empType ? true : false}
                        value={values.empType}
                        name="empType"
                        onChange={handleChange}
                        inputProps={{
                          id: "empType",
                        }}
                      >
                        {/* <MenuItem value={'none'}>
                          <em>None</em>
                        </MenuItem> */}
                        {Object.keys(EMP_TYPE).map((index: string) => (
                          <MenuItem value={index} key={index}>
                            {EMP_TYPE[index]}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.empType && touched.empType && (
                        <Typography variant="caption" color="error">
                          &nbsp; &nbsp;{errors.empType}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                </>
              )}
              {values._id == null && (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl margin="normal" size="small" fullWidth required>
                    <InputLabel className="select-label" htmlFor="type">
                      Category
                    </InputLabel>
                    <Select
                      error={errors.category && touched.category ? true : false}
                      value={values.category}
                      name="category"
                      onChange={handleChange}
                      inputProps={{
                        id: "category",
                      }}
                    >
                      {Object.keys(USER_CATEGORY)
                        .filter((d) => {
                          if (values.userType === "1099") {
                            return CAT_FOR_1099.includes(d);
                          }
                          return true;
                        })
                        .map((index: string) => (
                          <MenuItem value={index} key={index}>
                            {USER_CATEGORY[index]}
                          </MenuItem>
                        ))}
                    </Select>
                    {errors.category && touched.category && (
                      <Typography variant="caption" color="error">
                        &nbsp; &nbsp;{errors.category}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              )}
              <Grid item className="custom-switch" xs={12} sm={6} md={6} lg={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isEnabled}
                      onChange={handleChange}
                      value="isEnabled"
                      name="isEnabled"
                    />
                  }
                  label="Enable"
                />
              </Grid>

              {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                <fieldset>
                  <legend>Address:</legend>
                  <Grid container spacing={1}> */}

              {/* </Grid>
                </fieldset>
              </Grid> */}
            </Grid>
            <br />
            <br />
            <br />

            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  className="cancel-btn"
                  color="secondary"
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className="add-btn"
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={()=>{if(!isSubmited){setSubmitted(true)}}}
                  disabled={isSubmitting}
                >
                  {user ? "Update" : "Add"}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default withConfirmDialogContext(AddOrUpdateUser);
