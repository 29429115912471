import { ILogin, IStandardAPIResponse } from '../../vm';
import { httpClient } from '../UtilService';

export async function loginToApp(
  cred: ILogin
): Promise<IStandardAPIResponse<{ token: string }>> {
  try {
    let res = await httpClient<{ token: string }>(`auth-admin`, 'POST', cred);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export const emailForgotPwdCode = async (obj: {
  email: string;
  companyId?: string;
}): Promise<IStandardAPIResponse<any>> => {
  try {
    let res = await httpClient<any>(
      `admins/forgot-password-email`,
      'POST',
      obj
    );
    return res;
  } catch (err) {
    return err;
  }
};
export const updateForgotPwd = async (obj: {
  email: string;
  companyId?: string;
  code: string;
  newPassword: string;
}): Promise<IStandardAPIResponse<any>> => {
  try {
    let res = await httpClient<any>(`admins/forgot-password`, 'PATCH', obj);
    return res;
  } catch (err) {
    return err;
  }
};

// export async function sendEmailResetPwd(obj: {
//   email: string;
// }): Promise<IStandardAPIResponse<string>> {
//   try {
//     let res = await httpClient<string>(
//       `forgot-password/send-email`,
//       'POST',
//       obj
//     );
//     return res;
//   } catch (err) {
//     return err && err.response ? err.response.data : undefined;
//   }
// }

// export async function verifyEmail(
//   data: string
// ): Promise<IStandardAPIResponse<string>> {
//   try {
//     let res = await httpClient<string>(
//       `forgot-password/verify-email?enc=${data}`,
//       'GET'
//     );
//     return res;
//   } catch (err) {
//     return err && err.response ? err.response.data : undefined;
//   }
// }
// export async function resetPassword(
//   data: any
// ): Promise<IStandardAPIResponse<string>> {
//   try {
//     let res = await httpClient<string>(
//       `forgot-password/reset?enc=${data.enc}`,
//       'PATCH',
//       { password: data.password }
//     );
//     return res;
//   } catch (err) {
//     return err && err.response ? err.response.data : undefined;
//   }
// }
