import { API_URL } from '../Constant';
import { IStandardAPIResponse } from '../vm';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const getUserFullName = (user: any) => {
  return (
    user?.firstName +
    (user?.middleName ? ' ' + user?.middleName : '') +
    (user?.lastName ? ' ' + user?.lastName : '')
  );
};

export const sanitizeUrl = (url: string) => {
  if (url[url.length - 1] === '/') {
    return url.slice(0, url.length - 1);
  }
  return url;
};

export const setToken = (token: string) => {
  localStorage.setItem('webAdminToken', token);
};
export const removeToken = () => {
  localStorage.removeItem('webAdminToken');
};
export const groupBy = (data: Array<any>, prop: string) => {
  return data.reduce(function (groups, item) {
    const val = item[prop];
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});
};

export function getToken() {
  let res = localStorage.getItem('webAdminToken');
  if (res === null || res === undefined) {
    return '';
  }
  return res;
}
export const parseJwt = (tokenParsed?: string) => {
  let token;
  if (!tokenParsed) {
    token = getToken();
  } else {
    token = tokenParsed;
  }
  if (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
  return undefined;
};

export const httpClient = async <T>(
  url: string,
  type: string,
  obj: any = undefined
): Promise<IStandardAPIResponse<T>> => {
  try {
    type = type.toUpperCase();
    if (type.toLowerCase() === 'get' && obj) {
      var params = Object.keys(obj)
        .map(function (key) {
          return key + '=' + obj[key];
        })
        .join('&');
      url += '?' + params;
      obj = undefined;
    }

    let res = await fetch(API_URL + url, {
      method: type.toUpperCase(),
      body: JSON.stringify(obj),
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        at: getToken(),
      },
    });
    return await res.json();
  } catch (error) {
    console.group(`API ${type} Error`);
    console.error(error);
    console.groupEnd();
    throw error;
  }
};
export const httpCustomUrlClient = async <T>(
  url: string,
  type: string,
  obj: any = undefined
) => {
  try {
    type = type.toUpperCase();
    if (type.toLowerCase() === 'get' && obj) {
      var params = Object.keys(obj)
        .map(function (key) {
          return key + '=' + obj[key];
        })
        .join('&');
      url += '?' + params;
      obj = undefined;
    }
    await fetch(url, {
      method: type.toUpperCase(),
      body: obj,
    });
    return null;
  } catch (error) {
    console.group(`Custom API ${type} Error`);
    console.error(error);
    console.groupEnd();
    throw error;
  }
};

export const isTokenExpired = () => {
  var token = getToken();
  if (token) {
    let user = parseJwt(token);
    var cur_time = new Date().getTime() / 1000;
    if (user && user.exp && cur_time < user.exp) {
      return false;
    }
    return true;
  } else {
    return true;
  }
};
export const isSuperAdminNotAuthenticated = () => {
  var token = getToken();
  if (token) {
    let user = parseJwt(token);
    var cur_time = new Date().getTime() / 1000;
    if (user && user.exp && cur_time < user.exp && [0, 2].includes(user.role)) {
      return false;
    }
    return true;
  } else {
    return true;
  }
};

export const removeNulls = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeNulls(obj[key]);
    } else if (obj[key] === '' || obj[key] === null) {
      delete obj[key];
    }
  });
  return obj;
};

export function debounce<F extends (...params: any[]) => void>(
  fn: Function,
  delay: number
) {
  let timeoutID: any = null;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutID);
    timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
  } as F;
}
// export const getSignedUrl = async (
//   fileNames: Array<string>,
//   contentType: string
// ): Promise<IStandardAPIResponse<any>> => {
//   try {
//     let res = await httpClient<any>(`upload-keys`, 'POST', {
//       filePaths: fileNames,
//     });
//     return res;
//   } catch (err) {
//     return err && err.response ? err.response.data : undefined;
//   }
// };

export async function deleteFiles(obj: {
  filePaths: string[];
}): Promise<IStandardAPIResponse> {
  try {
    let res = await httpClient(`remove-files`, 'PATCH', obj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getSignedUrls(obj: {
  filePaths: string[];
}): Promise<IStandardAPIResponse<string[]>> {
  try {
    let res = await httpClient<string[]>(`upload-keys`, 'POST', obj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export const uploadFile = async (fileName: any, url: string, file: File) => {
  try {
    const res = await httpCustomUrlClient<Array<string>>(url, 'PUT', file);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const getFileByBase64 = (base64: string, filename: any) => {
  const arr: any = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  return fetch(base64)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mime });
    });
};

export const exportExcelSheet = (csvData: any, fileName: any) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const isValidateEmail = (email: string) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export function formatPhoneNumber(phone: string,countryCode?:string) {
  if (countryCode) {
    return `${countryCode} (${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(-4)} `;
  } else {
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(-4)} `;
  }
}