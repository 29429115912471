import * as React from "react";
import Grid from "@material-ui/core/Grid";
// import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Loading from "../../common/Loading";
import Empty from "../../common/Empty";
import { ToastContext } from "../../common/ToastProvider";
import { withConfirmDialogContext } from "../../common/ConfirmDialogProvider";
import {
  IPartnerUser,
  IPartnerUserSearch,
  IDrawerOpen,
  IConfirmDialog,
} from "../../../vm";
import {
  searchPartnerUsers,
  removeAPartnerUser,
} from "../../../services/PartnerUserService";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Fab from "@material-ui/core/Fab";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { TableHederCell } from "../../common/Library";
import {
  Pencil,
  TrashCanOutline,
  ChevronRight,
  ChevronLeft,
} from "mdi-material-ui";
import { DotsVertical } from "mdi-material-ui";
import Menu from "@material-ui/core/Menu";

import CustomDrawer from "../../common/CustomDrawer";

import AddOrUpdatePartnerUser from "./AddOrUpdatePartnerUser";
import { formatPhoneNumber } from "../../../services/UtilService";

interface IPartnerUserMainProps extends IConfirmDialog {
  partnerId: string;
}

const PartnerUserMain: React.FC<IPartnerUserMainProps> = ({
  confirmDialog,
  partnerId,
}) => {
  const { showToast } = React.useContext(ToastContext);
  // const { companyId } = useParams();
  const [isLoading, setLoading] = React.useState(false);
  const [partnerUsers, setPartnerUsers] = React.useState([] as IPartnerUser[]);
  const [partnerUserDrawer, setPartnerUserDrawer] = React.useState({
    isOpen: false,
  } as IDrawerOpen);

  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );

  const [selectedTimeSheetIndex, setSelectedTimeSheetIndex] = React.useState<
    number
  >(-1);

  const [partnerUserSearch, setPartnerUserSearch] = React.useState({
    partnerId: partnerId,
    limit: 10,
    offset: 0,
  } as IPartnerUserSearch);

  React.useEffect(() => {
    getPartnerUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPartnerUsers = async (offset?: number) => {
    setLoading(true);
    const temp = { ...partnerUserSearch };
    if (offset != null) {
      temp.offset = offset;
    }
    setPartnerUserSearch({ ...temp });

    const result = await searchPartnerUsers(temp);

    if (result?.success) {
      setPartnerUsers(result.data);
    } else {
      showToast(
        result?.message ? result.message : "Error while getting contacts list",
        "error"
      );
    }
    setLoading(false);
  };

  const removePartnerUser = async (index: number) => {
    confirmDialog.show("Are you sure", "Do you want to delete?", async () => {
      setLoading(true);
      const usersList = [...partnerUsers];
      const result = await removeAPartnerUser(usersList[index]._id as string);
      if (result?.success) {
        usersList.splice(index, 1);
        setPartnerUsers(usersList);
        showToast(
         "Contact removed successfully",
          "success"
        );
      } else {
        showToast(
          result?.message ? result.message : "Error while removing contact",
          "error"
        );
      }
      setLoading(false);
    });
  };

  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setSelectedTimeSheetIndex(index);
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSelectedTimeSheetIndex(-1);
    setAnchorElMenu(null);
  };

  return (
    <div className="p-16">
      {isLoading && <Loading message="Loading..." />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="flex-end" alignItems="center">
            {/* <Grid item>
              <Typography variant='h5'>Users</Typography>
            </Grid> */}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className="add-btn"
                onClick={() => {
                  setPartnerUserDrawer({ isOpen: true });
                }}
              >
                Add Contact
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper className="card">
            <Grid container spacing={2}>
              <Grid item className="flex-grow">
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    name="search"
                    label="Search by name, email"
                    onChange={(event: any) => {
                      setPartnerUserSearch({
                        ...partnerUserSearch,
                        search: event.target.value,
                      });
                    }}
                    value={partnerUserSearch.search}
                  />
                </FormControl>
              </Grid>

              <Grid item>
                <Button
                  className="search-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => getPartnerUsers()}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          {partnerUsers.length === 0 ? (
            <Empty message="No Contacts Yet!!" />
          ) : (
            <TableContainer component={Paper} className="timesheet-table">
              <Table aria-label="Company table">
                <TableHead>
                  <TableRow>
                    <TableHederCell>Full Name</TableHederCell>
                    <TableHederCell>Email</TableHederCell>
                    <TableHederCell>Phone</TableHederCell>

                    <TableHederCell align="right">Actions</TableHederCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {partnerUsers.map((user, index: number) => (
                    <TableRow key={user._id}>
                      <TableCell component="th" scope="row">
                        {user.name}
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.phone?formatPhoneNumber(user.phone,user.countryCode) : "N/A"}</TableCell>

                      <TableCell align="right">
                        <Button
                          aria-controls="partner-user-menu"
                          aria-haspopup="true"
                          onClick={(e) => handleClickMenu(e, index)}
                        >
                          <DotsVertical />
                        </Button>
                        {/* <Grid container justify="flex-end">
                          <Grid item>
                            <Tooltip title="Update User">
                              <IconButton
                                color="secondary"
                                onClick={() =>
                                  setPartnerUserDrawer({
                                    isOpen: true,
                                    index: index,
                                  })
                                }
                              >
                                <Pencil />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Remove User">
                              <IconButton
                                color="primary"
                                onClick={() => removePartnerUser(index)}
                              >
                                <TrashCanOutline />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid> */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="flex-end" spacing={2}>
            {partnerUserSearch.offset > 0 && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() => {
                    getPartnerUsers(
                      partnerUserSearch.offset - partnerUserSearch.limit
                    );
                  }}
                >
                  <ChevronLeft />
                </Fab>
              </Grid>
            )}
            {partnerUsers.length >= partnerUserSearch.limit && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() => {
                    getPartnerUsers(
                      partnerUserSearch.offset + partnerUserSearch.limit
                    );
                  }}
                >
                  <ChevronRight />
                </Fab>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {partnerUserDrawer.isOpen && (
        <CustomDrawer
          title={`${partnerUserDrawer.index != null ? "Update" : "Add"} Contact`}
          onClose={() => {
            setPartnerUserDrawer({ isOpen: false });
          }}
        >
          <AddOrUpdatePartnerUser
            partnerId={partnerId}
            partner={partnerUsers[partnerUserDrawer.index as number]}
            onClose={(data?: IPartnerUser, isAdd?: boolean) => {
              if (data) {
                if (isAdd === true) {
                  setPartnerUsers([data, ...partnerUsers]);
                } else {
                  const temp = partnerUsers;
                  temp[partnerUserDrawer.index as number] = data;
                  setPartnerUsers(temp);
                }
              }
              setPartnerUserDrawer({ isOpen: false });
            }}
          />
        </CustomDrawer>
      )}

      <Menu
        id="partner-user-menu"
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            setPartnerUserDrawer({
              isOpen: true,
              index: selectedTimeSheetIndex,
            });
            handleCloseMenu();
          }}
        >
          <Pencil />
          &nbsp; Update
        </MenuItem>
        <MenuItem
          onClick={() => {
            removePartnerUser(selectedTimeSheetIndex);
            handleCloseMenu();
          }}
        >
          <TrashCanOutline />
          &nbsp; Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

export default withConfirmDialogContext(PartnerUserMain);
