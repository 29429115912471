import { IStandardAPIResponse, IProject } from '../../vm';
import { httpClient } from '../UtilService';

export async function addAProject(
  obj: IProject
): Promise<IStandardAPIResponse<IProject>> {
  try {
    let res = await httpClient<IProject>(`projects`, 'PUT', obj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function updateAProject(
  projectId: string,
  obj: IProject
): Promise<IStandardAPIResponse<IProject>> {
  try {
    let res = await httpClient<IProject>(`projects/${projectId}`, 'PATCH', obj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function removeAProject(
  projectId: string
): Promise<IStandardAPIResponse<IProject>> {
  try {
    let res = await httpClient<IProject>(`projects/${projectId}`, 'DELETE');
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function searchProjects(
  //   projectId: string,
  obj: any
): Promise<IStandardAPIResponse<Array<IProject>>> {
  try {
    let res = await httpClient<Array<IProject>>(`projects`, 'GET', obj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
