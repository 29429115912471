import * as React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import UpdateCompany from "./UpdateCompany";
import { useParams, useHistory } from "react-router-dom";
import UserMain from "../users/UserMain";
import AdminMain from "../admins/AdminMain";
import PartnerMain from "../partners/PartnerMain";
// import ProjectMain from "../projects/ProjectMain";
import HolidaysMain from "../holidays/HolidaysMain";
import { Grid, Typography } from "@material-ui/core";
import { ICompany } from "../../vm";
import { getACompany } from "../../services/CompanyService";
import { ToastContext } from "../common/ToastProvider";
import Loading from "../common/Loading";
import ConfigMain from "../companyConfig/ConfigMain";

const CompanyTab: React.FC = () => {
  const { tabName, companyId } = useParams();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = React.useState(tabName);

  const [companyDetails, setCompanyDetails] = React.useState<ICompany>(
    null as any
  );

  const [isLoading, setLoading] = React.useState(false);
  const { showToast } = React.useContext(ToastContext);

  // React.useLayoutEffect(() => {
  //   console.log('company tab useLayoutEffect');
  //   setSelectedTab(tabName);
  // }, [tabName]);

  React.useEffect(() => {
    const fetchCompany = async () => {
      setLoading(true);
      const result = await getACompany(companyId);
      if (result?.success) {
        setCompanyDetails(result.data as any);
      } else {
        showToast(
          result?.message ? result.message : "Error while fetching company",
          "error"
        );
      }
      setLoading(false);
    };
    fetchCompany();
  }, []);

  const updateCompanyDetails = (companyDetails: ICompany) => {
    setCompanyDetails(companyDetails);
  };
  return (
    <React.Fragment>
      {/* {isLoading && <Loading />} */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item>
              <Typography className="title" variant="h5">
                {companyDetails?.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => {
          setSelectedTab(newValue);
          history.replace(`/companies/${companyId}/${newValue}`);
        }}
        className="overflow-auto tabs-container"
        aria-label="companies tabs"
      >
        <Tab
          className={`tab-menu ${
            selectedTab === "details" ? "tab-selected" : ""
          }`}
          label="Details"
          value="details"
        />
        <Tab
          className={`tab-menu ${
            selectedTab === "users" ? "tab-selected" : ""
          }`}
          label="Associates"
          value="users"
        />
        <Tab
          className={`tab-menu ${
            selectedTab === "partners" ? "tab-selected" : ""
          }`}
          label="Partners"
          value="partners"
        />
        {/* <Tab
          className={`tab-menu ${
            selectedTab === "projects" ? "tab-selected" : ""
          }`}
          label="Projects"
          value="projects"
        /> */}
        <Tab
          className={`tab-menu ${
            selectedTab === "holidays" ? "tab-selected" : ""
          }`}
          label="Holidays"
          value="holidays"
        />
        <Tab
          className={`tab-menu ${
            selectedTab === "admins" ? "tab-selected" : ""
          }`}
          label="Admins"
          value="admins"
        />
        {/* <Tab
          className={`tab-menu ${
            selectedTab === "config" ? "tab-selected" : ""
          }`}
          label="Configuration"
          value="config"
        /> */}
      </Tabs>

      {selectedTab === "details" && (
        <Paper className="mt-16 max-width-900">
          <UpdateCompany updateCompanyDetails={updateCompanyDetails} />
        </Paper>
      )}
      {selectedTab === "users" && <UserMain />}
      {selectedTab === "admins" && <AdminMain />}
      {selectedTab === "partners" && <PartnerMain />}
      {/* {selectedTab === "projects" && <ProjectMain />} */}
      {selectedTab === "holidays" && <HolidaysMain />}
      {selectedTab === "config" && <ConfigMain />}
    </React.Fragment>
  );
};

export default CompanyTab;
