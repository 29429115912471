import { IAdmin, IStandardAPIResponse } from '../../vm';
import { httpClient } from '../UtilService';

export async function addASuperAdmin(
  user: IAdmin
): Promise<IStandardAPIResponse<IAdmin>> {
  try {
    let res = await httpClient<IAdmin>(`super-admins`, 'PUT', user);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function updateASuperAdmin(
  adminId: string,
  admin: IAdmin
): Promise<IStandardAPIResponse<IAdmin>> {
  try {
    let res = await httpClient<IAdmin>(
      `super-admins/${adminId}`,
      'PATCH',
      admin
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function removeASuperAdmin(
  adminId: string
): Promise<IStandardAPIResponse<IAdmin>> {
  try {
    let res = await httpClient<IAdmin>(`admins/${adminId}`, 'DELETE');
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function searchSuperAdmins(): Promise<
  IStandardAPIResponse<IAdmin[]>
> {
  try {
    let res = await httpClient<IAdmin[]>(`super-admins`, 'GET');
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
