import * as React from "react";
import Loading from "../common/Loading";
import {
  Grid,
  Typography,
  Paper,
  Theme,
  createStyles,
  withStyles,
  FormControl,
  TextField,
  Button,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { ClassProps, IEmailConfig } from "../../vm";
import { Formik, FormikErrors } from "formik";
import { sanitizeUrl } from "../../services/UtilService";
import { withToastContext, IToast } from "../common/ToastProvider";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { getEmailConfigs, addOrUpdateEmailConfig } from "../../services/CompanyConfigService";

export interface ConfigMainProps
  extends IToast,
    ClassProps,
    RouteComponentProps {}

export interface ConfigMainState {
  isLoading: boolean;
  configDetails: IEmailConfig;
}

class ConfigMain extends React.Component<ConfigMainProps, ConfigMainState> {
  constructor(props: ConfigMainProps) {
    super(props);
    this.state = {
      isLoading: false,
      configDetails: {
        email: "",
        companyId: "",
        TLS: false,
        host: "",
        password: "",
        port: "",
      },
    };
  }

  componentDidMount = async () => {
    let params: any = this.props.match.params;
    this.setState((prevState) => ({
      configDetails: {
        ...prevState.configDetails,
        companyId: params.companyId,
      },
    }));
    await this.getEmailConfigDetails(params.companyId);
    // getDetails();
  };

  getEmailConfigDetails = async (companyId: string) => {
    this.setState({ isLoading: true });
    let result = await getEmailConfigs(companyId);
    if (result?.success) {
      if(result.data){
        this.setState({ configDetails: result.data });
      }
    } else {
      this.props.showToast(
        result?.message || "Error while getting configuration details",
        "error"
      );
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className="ph-16">
          {this.state.isLoading && <Loading message="processing..." />}
          <br />
          <br />
          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography className="title" variant="h5">
                Configuration
              </Typography>
            </Grid> */}

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                <div className="w-100">
                  <Formik
                    enableReinitialize={true}
                    initialValues={this.state.configDetails}
                    validate={(values: IEmailConfig) => {
                      const errors: FormikErrors<IEmailConfig> = {};
                      if (!values.email) errors.email = "Required";
                      if (!values.host) errors.host = "Required";
                      // if (!values.companyId) errors.companyId = "Required";
                      if (!values.port) errors.port = "Required";
                      if (!values.password) errors.password = "Required";
                      return errors;
                    }}
                    onSubmit={async (
                      values: any,
                      { setSubmitting }: { setSubmitting: Function }
                    ) => {
                      this.setState({ isLoading: true });
                      const obj = { ...values };
                      // obj.url = sanitizeUrl(values.url as string);
                        const result = await addOrUpdateEmailConfig(obj);
                        if (result?.success) {
                          this.setState({ configDetails:result.data,isLoading: false  });
                          this.props.showToast(`Updated successfully`, "success");
                        } else {
                          this.props.showToast(
                            result?.message
                              ? result.message
                              : "Error while updating details",
                            "error"
                          );
                          // this.setState({ isLoading: false });
                        }
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      // setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControl margin="normal" required fullWidth>
                              <TextField
                                size="small"
                                name="email"
                                type="email"
                                label="Email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.email && touched.email ? true : false
                                }
                                helperText={
                                  errors.email && touched.email && errors.email
                                }
                                value={values.email}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControl margin="normal" required fullWidth>
                              <TextField
                                size="small"
                                name="password"
                                type="password"
                                label="Password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.password && touched.password
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.password &&
                                  touched.password &&
                                  errors.password
                                }
                                value={values.password}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControl margin="normal" required fullWidth>
                              <TextField
                                size="small"
                                name="host"
                                type="email"
                                label="Host"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.host && touched.host ? true : false
                                }
                                helperText={
                                  errors.host && touched.host && errors.host
                                }
                                value={values.host}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControl margin="normal" required fullWidth>
                              <TextField
                                size="small"
                                name="port"
                                label="Port"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.port && touched.port ? true : false
                                }
                                helperText={
                                  errors.port && touched.port && errors.port
                                }
                                value={values.port}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.TLS}
                                  onChange={handleChange}
                                  value="TLS"
                                  name="TLS"
                                />
                              }
                              label="TLS"
                            />
                          </Grid>
                        </Grid>
                        <br />
                        <Grid container justify="flex-end" spacing={2}>
                          {/* {this.state.configDetails._id&&<Grid item>
                            <Button
                              variant="outlined"
                              className="add-btn"
                              color="primary"
                              disabled={isSubmitting}
                            >
                              Test
                            </Button>
                          </Grid>} */}
                          <Grid item>
                            <Button
                              type="submit"
                              variant="contained"
                              className="add-btn"
                              color="primary"
                              disabled={isSubmitting}
                            >
                              {this.state.configDetails._id?"Update":"Save"}
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Formik>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    main: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      maxWidth: "600px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${
        theme.spacing() * 3
      }px`,
    },
    logo: {
      height: "86px",
    },
    avatar: {
      margin: theme.spacing(),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      //   width: "100%",
      marginTop: theme.spacing(),
    },
    submit: {
      marginTop: theme.spacing(3),
    },
  });

export default withRouter(withToastContext(withStyles(styles)(ConfigMain)));
