import React from 'react';
import { ICompany } from '../../vm';
import { getCompanyByOrigin } from '../../services/MiscService';
import { getOrganizationalDetails } from '../../services/account/AdminService';
// import { ToastContext } from './ToastProvider';
import CompanyNotFound from '../../pages/common/CompanyNotFound';
import Loading from './Loading';

export const CompanyContext = React.createContext<{ company: ICompany }>({
  company: {},
});

export const CompanyProvider: React.FC<any> = (props) => {
  // const { showToast } = React.useContext(ToastContext);
  const [company, setCompany] = React.useState({} as any);
  const [isNotFound, setNotFound] = React.useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = React.useState(false);

  React.useLayoutEffect(() => {
    console.log('why so');

    const asyncFunc = async () => {
      const origin = window.location.origin;
      if (origin !== 'http://localhost:3000') {
        // get organization details
        const res = await getOrganizationalDetails();
        if (res?.success === true) {
          if (res?.data?.url !== origin) {
            const result = await getCompanyByOrigin();
            if (result?.success) {
              setCompany(result.data);
            } else {
              setNotFound(true);
            }
          } else {
            setIsSuperAdmin(true);
          }
        } else {
          setNotFound(true);
        }
      } else {
        setIsSuperAdmin(true);
      }
    };
    asyncFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isNotFound === true) {
    return <CompanyNotFound />;
  }
  return (
    <CompanyContext.Provider value={{ company: company }}>
      {isSuperAdmin === true || company?._id != null ? (
        <>{props.children}</>
      ) : (
        <Loading message='Fetching Company Details...' />
      )}
    </CompanyContext.Provider>
  );
};
