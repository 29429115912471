import * as React from 'react';
import { searchCompanies, deleteACompany } from '../../services/CompanyService';
import { ToastContext } from '../common/ToastProvider';
import { ICompany, ICompanySearch, IAdmin } from '../../vm';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddCompanyAndAdmin from './AddCompanyAndAdmin';
import CustomDrawer from '../common/CustomDrawer';
import Loading from '../common/Loading';
import Empty from '../common/Empty';
import { useHistory } from 'react-router-dom';
import './company.css';
import CompanyCard from './CompanyCard';
import {
  Paper,
  FormControl,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Magnify } from 'mdi-material-ui';
import { parseJwt } from '../../services/UtilService';
import { ConfirmDialogContext } from '../common/ConfirmDialogProvider';

interface ICompanyMainProps {}

const CompanyMain: React.FC<ICompanyMainProps> = () => {
  const { showToast } = React.useContext(ToastContext);
  const { showConfirmDialog } = React.useContext(ConfirmDialogContext);
  const history = useHistory();
  const [companies, setCompanies] = React.useState([] as ICompany[]);
  const [isOpenCompanyAdminDrawer, setOpenCompanyAdminDrawer] = React.useState(
    false
  );

  const [companySearch, setCompanySearch] = React.useState(
    {} as ICompanySearch
  );

  const [isLoading, setLoading] = React.useState(false);

  const userData = parseJwt() as IAdmin;

  React.useEffect(() => {
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeHoliday = async (index: number) => {
    showConfirmDialog('Are you sure', 'Do you want to delete?', async () => {
      setLoading(true);

      const result = await deleteACompany(companies[index]._id as string);
      if (result && result.success) {
        const tempCompanies = [...companies];
        tempCompanies.splice(index, 1);
        setCompanies(tempCompanies);
        showToast("Company deleted succesfully","success");
      } else {
        showToast(
          result?.message ? result.message : 'Error while deleting company',
          'error'
        );
      }
      setLoading(false);
    });
  };

  const handleCompanySearch = (event: any) => {
    setCompanySearch({
      ...companySearch,
      [event.target.name]: event.target.value,
    });
  };

  const getCompanies = async () => {
    setLoading(true);
    const result = await searchCompanies(companySearch);
    if (result?.success) {
      setCompanies(result.data);
    } else {
      showToast(
        result?.message ? result.message : 'Error while getting company list',
        'error'
      );
    }
    setLoading(false);
  };
  const onViewCompany = (company: ICompany) => {
    history.push(`/companies/${company._id}/details`);
  };
  return (
    <React.Fragment>
      {isLoading && <Loading message='Loading...' />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify='space-between' alignItems='flex-start'>
            <Grid item>
              <Typography className='title' variant='h5'>
                Companies
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item className='flex-grow'>
              <Paper className='card'>
                <Grid container spacing={2}>
                  <Grid item className='flex-grow'>
                    <FormControl fullWidth>
                      <TextField
                        size='small'
                        name='name'
                        label=''
                        placeholder='Search'
                        variant='standard'
                        onChange={handleCompanySearch}
                        value={companySearch.name}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Magnify />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={getCompanies}
                      className='search-btn fs-14 fw-medium'>
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {userData.role !== 2 && (
              <Grid item>
                <Button
                  className='add-btn'
                  variant='contained'
                  color='primary'
                  onClick={() => setOpenCompanyAdminDrawer(true)}>
                  Add Company
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* <Grid container spacing={2}>
        {companies.map((company) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={company._id}>
            <CompanyCard company={company} />
          </Grid>
        ))}
      </Grid> */}
        {companies.length === 0 ? (
          <Empty message='No Companies Yet!!' />
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={2}>
              {companies.map((company, index) => (
                <Grid key={company._id} item lg={6} md={6} sm={6} xs={12}>
                  <CompanyCard
                    company={company}
                    onViewCompany={onViewCompany}
                    removeHoliday={() => removeHoliday(index)}
                  />
                </Grid>
              ))}
            </Grid>

            {/* <TableContainer component={Paper}>
              <Table aria-label="Company table">
                <TableHead>
                  <TableRow>
                    <TableHederCell>Name</TableHederCell>
                    <TableHederCell>Email</TableHederCell>
                    <TableHederCell>Company URL</TableHederCell>
                    <TableHederCell>User URL</TableHederCell>
                    <TableHederCell>Timesheet URL</TableHederCell>
                    <TableHederCell align='right'>Actions</TableHederCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {companies.map((company) => (
                    <TableRow key={company._id}>
                      <TableCell component="th" scope="row">
                        {company.name}
                      </TableCell>
                      <TableCell>{company.email}</TableCell>
                      <TableCell>
                        {company.url ? (
                          <Link
                            color="secondary"
                            href={company.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {company.url}
                          </Link>
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      <TableCell>
                        {company.customerUrl ? (
                          <Link
                            color="secondary"
                            href={company.customerUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {company.customerUrl}
                          </Link>
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      <TableCell>
                        {company.timesheetUrl ? (
                          <Link
                            color='secondary'
                            href={company.timesheetUrl}
                            target='_blank'
                            rel='noreferrer'>
                            {company.timesheetUrl}
                          </Link>
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                      <TableCell align='right'>
                        <Grid container justify='flex-end' spacing={2}>
                          <Grid item>
                            <Tooltip title="View Company">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  history.push(
                                    `/companies/${company._id}/details`
                                  )
                                }
                              >
                                View
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
          </Grid>
        )}
      </Grid>
      {isOpenCompanyAdminDrawer && (
        <CustomDrawer
          title='Add Company'
          onClose={() => {
            setOpenCompanyAdminDrawer(false);
          }}>
          <AddCompanyAndAdmin
            onClose={(data?: ICompany) => {
              if (data != null) {
                setCompanies([data, ...companies]);
              }
              setOpenCompanyAdminDrawer(false);
            }}
          />
        </CustomDrawer>
      )}
    </React.Fragment>
  );
};

export default CompanyMain;
