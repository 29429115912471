import * as React from "react";
import { ClassProps, ILogin } from "../../vm";
import {
  isTokenExpired,
  setToken,
  isValidateEmail,
} from "../../services/UtilService";
import { ToastContext } from "../common/ToastProvider";
import {
  withStyles,
  Theme,
  createStyles,
  Paper,
  Typography,
  FormControl,
  Button,
  TextField,
  Link,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import { Formik } from "formik";
import {
  loginToApp,
  emailForgotPwdCode,
  updateForgotPwd,
} from "../../services/account/AuthService";
import { useHistory } from "react-router-dom";
import { CompanyContext } from "../common/CompanyProvider";

interface ILoginMainProps extends ClassProps {}

const LoginMain: React.FC<ILoginMainProps> = ({ classes }) => {
  const { company } = React.useContext(CompanyContext);
  const history = useHistory();
  const { showToast } = React.useContext(ToastContext);
  const [showDialog, setShowDialog] = React.useState({
    isOpen: false,
    showUpdatePwd: false,
  });
  const [updatePwd, setUpdatePwd] = React.useState({
    email: "",
    newPassword: "",
    companyId: company?._id,
    confPassword: "",
    code: "",
    disableSendOtp: false,
  });

  const [error, setError] = React.useState({} as any);

  React.useEffect(() => {
    let hasTokenExpired = isTokenExpired();
    if (!hasTokenExpired) {
      history.push("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: any) => {
    setUpdatePwd({ ...updatePwd, [event.target.name]: event.target.value });
  };
  const sendVerificationCode = async () => {
    setUpdatePwd({ ...updatePwd, disableSendOtp: true });

    let result = await emailForgotPwdCode({
      email: updatePwd.email,
      companyId: company?._id,
    });

    if (result && result.success) {
      setShowDialog({ isOpen: true, showUpdatePwd: true });
      showToast("You'll receive a email with Code shortly.", "success");
    } else {
      showToast(result?.message || "Error while sending email", "error");
    }
    setUpdatePwd({ ...updatePwd, disableSendOtp: false });
  };

  const updatePassword = async () => {
    if (
      !updatePwd.newPassword ||
      !updatePwd.code ||
      !updatePwd.confPassword ||
      updatePwd.confPassword !== updatePwd.newPassword
    ) {
      setError({
        ...error,
        email: updatePwd.email ? "" : "Required",
        otp: updatePwd.code ? "" : "Required",
        newPassword: updatePwd.newPassword ? "" : "Required",
        confPassword: updatePwd.confPassword
          ? updatePwd.newPassword !== updatePwd.confPassword
            ? "password and confirm password mismatched"
            : ""
          : "Required",
      });

      return;
    }
    let result = await updateForgotPwd({
      code: updatePwd.code,
      email: updatePwd.email,
      newPassword: updatePwd.newPassword,
      companyId: company?._id,
    });
    if (result && result.success) {
      setShowDialog({ isOpen: false, showUpdatePwd: false });
      setUpdatePwd({
        email: "",
        newPassword: "",
        companyId: company?._id,
        confPassword: "",
        code: "",
        disableSendOtp: false,
      });
      showToast(
        "Password has been updated successfully. Please login.",
        "success"
      );
    } else {
      showToast(result?.message || "Error while updating password", "error");
    }
  };
  return (
    <main className={classes.main}>
      <Paper className={`${classes.paper} card`}>
        {/* <img src='images/logo.png' alt='' className={classes.logo} /> */}
        {/* <Avatar className={classes.avatar}>
          <Lock />
        </Avatar> */}
        <Typography
          className="un-auth-title text-primary fw-semi-bold"
          variant="h5"
        >
          Sign In
        </Typography>
        <br />
        <Formik
          initialValues={{
            email: "a@a.com",
            password: "123456",
            companyId: company?._id,
          }}
          validate={(values) => {
            let errors: any = {};
            if (!values.email) {
              errors.email = "Required";
            }
            if (!values.password) {
              errors.password = "Required";
            } else if (values.password.length <= 4) {
              errors.password = "Needs to be more than 4 characters";
            }
            return errors;
          }}
          onSubmit={async (values: ILogin, { setSubmitting }) => {
            let result = await loginToApp(values);
            if (result && result.success) {
              setToken(result.data.token);
              history.push("/dashboard");
            } else {
              showToast(result.message, "error");
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  size="small"
                  id="email"
                  name="email"
                  label="Email"
                  autoComplete="email"
                  autoFocus
                  onChange={handleChange}
                  error={errors.email && touched.email ? true : false}
                  helperText={errors.email && touched.email && errors.email}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  size="small"
                  name="password"
                  type="password"
                  label="Password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.password && touched.password ? true : false}
                  helperText={
                    errors.password && touched.password && errors.password
                  }
                  value={values.password}
                />
              </FormControl>
              <div className="text-right w-100">
                <Link
                  href="#"
                  color="secondary"
                  onClick={() => {
                    setShowDialog({ isOpen: true, showUpdatePwd: false });
                  }}
                >
                  Forgot password?
                </Link>
              </div>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={`${classes.submit} add-btn`}
                disabled={isSubmitting}
              >
                Sign in
              </Button>
            </form>
          )}
        </Formik>
        <br />
      </Paper>
      <Dialog
        onClose={() => {
          setShowDialog({ isOpen: false, showUpdatePwd: false });
        }}
        disableBackdropClick
        aria-labelledby="simple-dialog-title"
        open={showDialog.isOpen}
      >
        <DialogTitle id="simple-dialog-title ">Recover Password</DialogTitle>

        <div className="p-16">
          <Typography variant="subtitle2">
            Type your registered email in the field below to receive
            Verification Code.
          </Typography>
          <br />
          <TextField
            size="small"
            name="email"
            type="email"
            label="Email *"
            fullWidth
            style={{ marginBottom: 8 }}
            disabled={showDialog.showUpdatePwd}
            autoComplete="email"
            onChange={handleChange}
            error={error && error.email ? true : false}
            helperText={error.email}
            value={updatePwd.email}
          />

          {showDialog.showUpdatePwd && (
            <>
              <TextField
                size="small"
                name="code"
                label="CODE *"
                style={{ marginBottom: 8 }}
                fullWidth
                onChange={handleChange}
                error={error && error.code ? true : false}
                helperText={error.code}
                value={updatePwd.code}
              />
              <TextField
                size="small"
                name="newPassword"
                type="password"
                label="Password *"
                style={{ marginBottom: 8 }}
                fullWidth
                onChange={handleChange}
                error={error && error.newPassword ? true : false}
                helperText={error.newPassword}
                value={updatePwd.newPassword}
              />
              <TextField
                size="small"
                name="confPassword"
                type="password"
                label="Confirm Password *"
                style={{ marginBottom: 8 }}
                fullWidth
                onChange={handleChange}
                error={error && error.confPassword ? true : false}
                helperText={error.confPassword}
                value={updatePwd.confPassword}
              />
            </>
          )}
          <br />
          <br />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ marginRight: 16 }}
              variant="text"
              className="cancel-btn"
              onClick={() => {
                setShowDialog({ isOpen: false, showUpdatePwd: false });
              }}
            >
              Cancel
            </Button>

            {!showDialog.showUpdatePwd && (
              <Button
                variant="contained"
                color="primary"
                className="add-btn"
                disabled={
                  !isValidateEmail(updatePwd.email) || updatePwd.disableSendOtp
                }
                onClick={sendVerificationCode}
              >
                {updatePwd.disableSendOtp ? "sending Email..." : "Send Email"}
              </Button>
            )}
            {showDialog.showUpdatePwd && (
              <Button
                className="add-btn"
                variant="contained"
                color="primary"
                disabled={updatePwd.disableSendOtp}
                onClick={updatePassword}
              >
                Update Password
              </Button>
            )}
          </div>
        </div>
      </Dialog>
    </main>
  );
};
const styles = (theme: Theme) =>
  createStyles({
    main: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      // minHeight: "calc(100vh - 168px)",
      maxWidth: "600px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${
        theme.spacing() * 3
      }px`,
    },
    logo: {
      height: "86px",
    },
    avatar: {
      margin: theme.spacing(),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      //   width: "100%",
      marginTop: theme.spacing(),
    },
    submit: {
      marginTop: theme.spacing(3),
    },
  });

export default withStyles(styles)(LoginMain);
