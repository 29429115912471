import React, { ComponentType, FunctionComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
  isTokenExpired,
  isSuperAdminNotAuthenticated,
} from '../../services/UtilService';

interface IAuthenticatedRoute {
  component: ComponentType<any> | FunctionComponent<any>;
  path: string;
  exact: boolean;
}

export const AuthenticatedRoute: React.FC<IAuthenticatedRoute> = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isTokenExpired() ? (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
            search: `?redirect_to=${props.location.pathname}${props.location.search}${props.location.hash}`,
          }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);
export const SuperAdminAuthenticatedRoute: React.FC<IAuthenticatedRoute> = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      return isSuperAdminNotAuthenticated() ? (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
            search: `?redirect_to=${props.location.pathname}${props.location.search}${props.location.hash}`,
          }}
        />
      ) : (
        <Component {...props} />
      );
    }}
  />
);
