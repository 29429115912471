import * as React from "react";
import { Formik } from "formik";
import { IAdmin } from "../../vm";
import {
  FormControl,
  Button,
  TextField,
  Grid,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { addAAdmin, updateAAdmin } from "../../services/account/AdminService";
import { ToastContext } from "../common/ToastProvider";

import Loading from "../common/Loading";

interface onCloseFunc {
  (data?: IAdmin, isAdd?: boolean): void;
}

interface IAddOrUpdateAdminProps {
  onClose: onCloseFunc;
  admin?: IAdmin;
  companyId: string;
}

const AddOrUpdateAdmin: React.FC<IAddOrUpdateAdminProps> = ({
  onClose,
  admin,
  companyId,
}) => {
  const { showToast } = React.useContext(ToastContext);
  const [isLoading, setLoading] = React.useState(false);
  return (
    <div className="p-16">
      {isLoading && <Loading message="processing..." />}
      <Formik
        initialValues={
          admin
            ? admin
            : {
                firstName: "",
                lastName: "",
                companyIds: [companyId],
                email: "",
                password: "",
                isEnabled: true,
              }
        }
        validate={(values: IAdmin) => {
          const errors: any = {};
          if (!values.firstName) errors.firstName = "Required";
          if (!values.lastName) errors.lastName = "Required";
          if (!values.email) errors.email = "Required";
          if (values._id == null && !values.password)
            errors.password = "Required";

          return errors;
        }}
        onSubmit={async (
          values: IAdmin,
          { setSubmitting }: { setSubmitting: Function }
        ) => {
          setLoading(true);
          console.log("user", values);
          const obj = { ...values };

          let result;
          if (obj._id) {
            result = await updateAAdmin(obj._id, obj);
          } else {
            result = await addAAdmin(obj);
          }

          if (result?.success) {
            onClose(result.data, obj._id ? false : true);

            showToast(
              `${obj._id ? "Updated" : "Added"} successfully`,
              "success"
            );
          } else {
            showToast(
              result?.message ? result.message : "Error while adding admin",
              "error"
            );
          }
          setSubmitting(false);
          setLoading(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="firstName"
                    label="First Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.firstName && touched.firstName ? true : false}
                    helperText={
                      errors.firstName && touched.firstName && errors.firstName
                    }
                    value={values.firstName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="lastName"
                    label="Last Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.lastName && touched.lastName ? true : false}
                    helperText={
                      errors.lastName && touched.lastName && errors.lastName
                    }
                    value={values.lastName}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="email"
                    type="email"
                    label="Email"
                    autoComplete="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email ? true : false}
                    helperText={errors.email && touched.email && errors.email}
                    value={values.email}
                  />
                </FormControl>
              </Grid>

              {values._id == null && (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl margin="normal" required fullWidth>
                    <TextField
                      size="small"
                      name="password"
                      type="password"
                      label="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.password && touched.password ? true : false}
                      helperText={
                        errors.password && touched.password && errors.password
                      }
                      value={values.password}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isEnabled}
                      onChange={handleChange}
                      value="isEnabled"
                      name="isEnabled"
                    />
                  }
                  label="Enable"
                />
              </Grid>
            </Grid>
            <br />

            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  className="cancel-btn"
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="add-btn"
                  disabled={isSubmitting}
                >
                  {admin ? "Update" : "Add"}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddOrUpdateAdmin;
