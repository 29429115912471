import * as React from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import moment from "moment";
import {
  getDashForSuperAdmin,
  searchCompanies,
} from "../../services/CompanyService";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { parseJwt } from "../../services/UtilService";
// import CreatableSelect from 'react-select/creatable';
import ReactSelect from "react-select";
import { useHistory } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { CompanyContext } from "../common/CompanyProvider";
// import { KeyboardDatePicker } from '@material-ui/core';

const SuperAdminDash: React.FC = () => {
  const { company } = React.useContext(CompanyContext);
  const history = useHistory();
  const admin = parseJwt();
  const [search, setSearch] = React.useState({
    startDate: "",
    endDate: "",
    companyId: "",
  });
  const { showToast } = React.useContext(ToastContext);
  const [isLoading, setLoading] = React.useState(false);
  const [companies, setCompanies] = React.useState(
    [] as Array<{ value: string; label: string }>
  );

  const [report, setReport] = React.useState({
    "1099": 0,
    w2: 0,
    partners: 0,
    users: 0,
    "service-provider": 0,
    client: 0,
  });

  React.useLayoutEffect(() => {
    const asyncFunc = async () => {
      const temp: Array<{ value: string; label: string }> = [];
      if ([0, 2].includes(admin.role)) {
        const result = await searchCompanies();
        if (result?.success) {
          result?.data?.forEach((d) => {
            const obj = {
              value: d._id as string,
              label: d.name as string,
            };
            temp.push(obj);
          });
        }else{
          showToast(result.message||"Error while fetching companines","error")
        }
        setCompanies(temp);
      }
      const searchObj: any = {
        ...search,
        startDate: moment()
          .subtract(7 as number, "days")
          .format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),

        companyId: admin.role === 1 ? company._id : temp[0]?.value,
      };

      setSearch(searchObj);
      await searchDashForSuperAdmin(searchObj);
    };
    asyncFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchDashForSuperAdmin = async (searchObj?: any) => {
    setLoading(true);

    const temp = { ...search };

    const result = await getDashForSuperAdmin(searchObj ? searchObj : temp);
    if (result?.success) {
      // setUsers(result.data);
      setReport(result.data);
    } else {
      showToast(
        result?.message ? result.message : "Error while getting report",
        "error"
      );
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item>
              <Typography className="title" variant="h5">
                Dashboard
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper className="card">
            <Grid container alignItems="center" spacing={2}>
              {[0, 2].includes(admin.role) && (
                <Grid item className="flex-grow">
                  <InputLabel className="custom-react-select-label">
                    Company
                  </InputLabel>
                  <ReactSelect
                    // isClearable
                    value={
                      search.companyId
                        ? companies.find((d) => d.value === search.companyId)
                        : {}
                    }
                    placeholder="Select company"
                    className="async-select react-select"
                    onChange={(newValue: any) => {
                      // console.log('onChange', newValue);
                      setSearch({
                        ...search,
                        companyId: newValue?.value,
                      });
                    }}
                    options={companies}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    name="startDate"
                    type="date"
                    label="Start Date"
                    onChange={(event: any) => {
                      console.log("event", event.target.value);
                      setSearch({
                        ...search,
                        startDate: event.target.value,
                      });
                    }}
                    value={search.startDate}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    name="endDate"
                    type="date"
                    label="End Date"
                    onChange={(event: any) => {
                      console.log("event", event.target.value);
                      setSearch({
                        ...search,
                        endDate: event.target.value,
                      });
                    }}
                    value={search.endDate}
                  />
                </FormControl>
              </Grid>

              <Grid item>
                <Button
                  className="add-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    console.log("search", search);
                    searchDashForSuperAdmin();
                  }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <br />
      <br />

      <Grid container spacing={3}>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Card
            className="pointer"
            onClick={() => {
              history.push(
                `/companies/${search.companyId}/users?type=user&startDate=${search.startDate}&endDate=${search.endDate}`
              );
            }}
          >
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total Users
              </Typography>
              <Typography variant="h2">{report.users}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Card
            className="pointer"
            onClick={() => {
              history.push(
                `/companies/${search.companyId}/users?userType=w2&startDate=${search.startDate}&endDate=${search.endDate}`
              );
            }}
          >
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                W2 Users
              </Typography>
              <Typography variant="h2">{report.w2}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Card
            className="pointer"
            onClick={() => {
              history.push(
                `/companies/${search.companyId}/users?userType=1099&startDate=${search.startDate}&endDate=${search.endDate}`
              );
            }}
          >
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                1099 Users
              </Typography>
              <Typography variant="h2">{report["1099"]}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Card
            className="pointer"
            onClick={() => {
              history.push(
                `/companies/${search.companyId}/partners?type=service-provider`
              );
            }}
          >
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Service Providers
              </Typography>
              <Typography variant="h2">{report["service-provider"]}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Card
            className="pointer"
            onClick={() => {
              history.push(
                `/companies/${search.companyId}/partners?type=client`
              );
            }}
          >
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Clients
              </Typography>
              <Typography variant="h2">{report["client"]}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* <div className="report">
               
        <Card
          onClick={() => {
            history.push(
              `/companies/${search.companyId}/users?type=partner&startDate=${search.startDate}&endDate=${search.endDate}`
            );
          }}>
          <CardContent>
            <Typography color='textSecondary' gutterBottom>
              Partners
            </Typography>
            <Typography variant='h2'>{report.partners}</Typography>
          </CardContent>
        </Card> 
      </div>*/}
    </React.Fragment>
  );
};

// const useStyles = makeStyles({
//   root: {
//     maxWidth: 275,
//     minWidth: 250,
//     margin: 16,
//     cursor: "pointer",
//   },
// });

export default SuperAdminDash;
