import { IStandardAPIResponse, IProjectUser } from '../../vm';
import { httpClient } from '../UtilService';

export async function addAProjectUser(
  obj: IProjectUser
): Promise<IStandardAPIResponse<IProjectUser>> {
  try {
    let res = await httpClient<IProjectUser>(`project-users`, 'PUT', obj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function updateAProjectUser(
  userId: string,
  obj: IProjectUser
): Promise<IStandardAPIResponse<IProjectUser>> {
  try {
    let res = await httpClient<IProjectUser>(
      `project-users/${userId}`,
      'PATCH',
      obj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function removeAProjectUser(
  userId: string
): Promise<IStandardAPIResponse<IProjectUser>> {
  try {
    let res = await httpClient<IProjectUser>(
      `project-users/${userId}`,
      'DELETE'
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function searchProjectUsers(
  //   userId: string,
  obj: any
): Promise<IStandardAPIResponse<Array<IProjectUser>>> {
  try {
    let res = await httpClient<Array<IProjectUser>>(
      `project-users`,
      'GET',
      obj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
