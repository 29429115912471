import * as React from 'react';
import Paper from '@material-ui/core/Paper';

import { withStyles, Theme, createStyles, Typography } from '@material-ui/core';
import { ClassProps } from '../../vm';

interface CompanyNotFoundProps extends ClassProps {}

const CompanyNotFound: React.FC<CompanyNotFoundProps> = ({ classes }) => {
  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Typography variant='h3' gutterBottom>
          4
          <span role='img' aria-labelledby='lost' style={{ color: 'red' }}>
            🚫
          </span>
          4
        </Typography>
        <Typography variant='h5' gutterBottom>
          No such url associated with any company. Please contact administrator
          for any queries
        </Typography>
        <br />
        {/* <Fab component={MyLink} color='primary' aria-label='home'>
      <Home />
    </Fab> */}
      </Paper>
    </main>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      marginTop: theme.spacing(8),
      maxWidth: '600px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${
        theme.spacing() * 3
      }px`,
    },
  });

export default withStyles(styles)(CompanyNotFound);
