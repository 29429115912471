import * as React from "react";
// import { parseJwt } from '../../services/UtilService';
import SuperAdminDash from "../../components/dash/SuperAdminDash";
// import CompanyAdminDash from '../../components/dash/CompanyAdminDash';

const DashboardPage: React.FC = () => {
  // const admin = parseJwt();
  return <SuperAdminDash />;
  // <div className='p-16'>
  //   {/* {admin == null ? (
  //     <h4>Dashboard</h4>
  //   ) : admin.role === 0 ? (
  //     <SuperAdminDash />
  //   ) : (
  //     <CompanyAdminDash />
  //   )} */}
  // </div>
};

export default DashboardPage;
