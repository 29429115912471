import * as React from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
// import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Loading from "../common/Loading";
import Empty from "../common/Empty";
import { ToastContext } from "../common/ToastProvider";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import { IAdmin, IAdminSearch, IDrawerOpen, IConfirmDialog } from "../../vm";
import {
  searchAdmins,
  removeAAdmin,
} from "../../services/account/AdminService";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TableHederCell } from "../common/Library";
import { Pencil, TrashCanOutline } from "mdi-material-ui";
import CustomDrawer from "../common/CustomDrawer";
import AddOrUpdateAdmin from "./AddOrUpdateAdmin";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { DotsVertical } from "mdi-material-ui";

interface IAdminMainProps extends IConfirmDialog {}

const AdminMain: React.FC<IAdminMainProps> = ({ confirmDialog }) => {
  const { showToast } = React.useContext(ToastContext);
  const { companyId } = useParams();
  const [isLoading, setLoading] = React.useState(false);
  const [admins, setAdmins] = React.useState([] as IAdmin[]);
  const [adminDrawer, setAdminDrawer] = React.useState({
    isOpen: false,
  } as IDrawerOpen);

  const [adminSearch] = React.useState({
    companyId: "",
  } as IAdminSearch);

  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );

  const [selectedTimeSheetIndex, setSelectedTimeSheetIndex] = React.useState<
    number
  >(-1);

  React.useEffect(() => {
    getAdmins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAdmins = async () => {
    setLoading(true);
    const result = await searchAdmins({ ...adminSearch, companyId: companyId });
    if (result?.success) {
      setAdmins(result.data);
    } else {
      showToast(
        result?.message ? result.message : "Error while getting admin list",
        "error"
      );
    }
    setLoading(false);
  };

  const removeAdmin = async (index: number) => {
    confirmDialog.show("Are you sure", "Do you want to delete?", async () => {
      setLoading(true);
      const usersList = [...admins];
      const result = await removeAAdmin(usersList[index]._id as string);
      if (result?.success) {
        usersList.splice(index, 1);
        setAdmins(usersList);
      } else {
        showToast(
          result?.message ? result.message : "Error while removing admin",
          "error"
        );
      }
      setLoading(false);
    });
  };

  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setSelectedTimeSheetIndex(index);
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSelectedTimeSheetIndex(-1);
    setAnchorElMenu(null);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading message="Loading..." />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="flex-end" alignItems="center">
            {/* <Grid item>
              <Typography variant='h5'>Users</Typography>
            </Grid> */}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className="add-btn"
                onClick={() => {
                  setAdminDrawer({ isOpen: true });
                }}
              >
                Add Admin
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {admins.length === 0 ? (
            <Empty message="No Admins Yet!!"/>
          ) : (
            <TableContainer component={Paper} className="timesheet-table">
              <Table aria-label="Company table">
                <TableHead>
                  <TableRow>
                    <TableHederCell>Full Name</TableHederCell>
                    <TableHederCell>Email</TableHederCell>
                    <TableHederCell>Enabled</TableHederCell>

                    <TableHederCell align="right">Actions</TableHederCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {admins.map((admin, index: number) => (
                    <TableRow key={admin._id}>
                      <TableCell component="th" scope="row">
                        {admin.firstName +
                          (admin.lastName ? " " + admin.lastName : "")}
                      </TableCell>
                      <TableCell>{admin.email}</TableCell>
                      <TableCell>{admin.isEnabled ? "Yes" : "No"}</TableCell>

                      <TableCell align="right">
                        <Button
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(e) => handleClickMenu(e, index)}
                        >
                          <DotsVertical />
                        </Button>
                        {/* 
                        <Grid container justify="flex-end">
                          <Grid item>
                            <Tooltip title="Update Amin">
                              <IconButton
                                color="secondary"
                                onClick={() =>
                                  setAdminDrawer({
                                    isOpen: true,
                                    index: index,
                                  })
                                }
                              >
                                <Pencil />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Remove Admin">
                              <IconButton
                                color="primary"
                                onClick={() => removeAdmin(index)}
                              >
                                <TrashCanOutline />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
      {adminDrawer.isOpen && (
        <CustomDrawer
          title={`${adminDrawer.index != null ? "Update" : "Add"} Admin`}
          onClose={() => {
            setAdminDrawer({ isOpen: false });
          }}
        >
          <AddOrUpdateAdmin
            companyId={companyId}
            admin={admins[adminDrawer.index as number]}
            onClose={(data?: IAdmin, isAdd?: boolean) => {
              if (data) {
                if (isAdd === true) {
                  setAdmins([data, ...admins]);
                } else {
                  const temp = admins;
                  temp[adminDrawer.index as number] = data;
                  setAdmins(temp);
                }
              }
              setAdminDrawer({ isOpen: false });
            }}
          />
        </CustomDrawer>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            setAdminDrawer({
              isOpen: true,
              index: selectedTimeSheetIndex,
            });
            handleCloseMenu();
          }}
        >
          <Pencil />
          &nbsp; Update
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeAdmin(selectedTimeSheetIndex);
            handleCloseMenu();
          }}
        >
          <TrashCanOutline />
          &nbsp; Delete
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default withConfirmDialogContext(AdminMain);
