import React from 'react';
import { List, Tooltip } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Link } from 'react-router-dom';
import {
  OfficeBuilding,
  AccountGroupOutline,
  HomeOutline,
  CogOutline,
} from 'mdi-material-ui';
import { parseJwt } from '../../services/UtilService';
import { IAdmin } from '../../vm';

interface SideMenuProps {
  isMenuOpened: boolean;
}

const SideMenu: React.FC<SideMenuProps> = ({ isMenuOpened }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  // const [userData] = React.useState(parseJwt() as IAdmin);

  const userData = parseJwt() as IAdmin;

  React.useLayoutEffect(() => {
    console.log('sidebar layout Effect');
    const ROUTES = ['/dashboard', '/companies', '/super-admins', '/configs'];
    let index: number = -1;
    ROUTES.forEach((d, i) => {
      if (window.location.pathname.includes(d)) {
        index = i;
      }
    });
    if (index !== -1) {
      setSelectedIndex(index);
    }
  }, [window.location.pathname]);

  // console.log('userData', userData);

  return (
    <List
      className={`side-menu-list custom-side-menu ${
        isMenuOpened ? 'menu-opened' : 'menu-closed'
      }`}>
      <ListItem
        button
        component={Link}
        to='/dashboard'
        selected={selectedIndex === 0}
        className={`menu-link fs-16 fw-medium ${
          selectedIndex === 0 ? 'selected-menu' : ''
        }`}
        onClick={() => setSelectedIndex(0)}>
        <Tooltip title='Dashboard' placement='right'>
          <ListItemIcon>
            <HomeOutline />
          </ListItemIcon>
        </Tooltip>
        <ListItemText className=''>Dashboard</ListItemText>
      </ListItem>

      {[0, 2].includes(userData.role !== undefined ? userData.role : -1) && (
        <ListItem
          button
          component={Link}
          to='/companies'
          selected={selectedIndex === 1}
          className={`menu-link fs-16 fw-medium ${
            selectedIndex === 1 ? 'selected-menu' : ''
          }`}
          onClick={() => setSelectedIndex(1)}>
          <Tooltip title='Companies' placement='right'>
            <ListItemIcon>
              <OfficeBuilding />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Companies</ListItemText>
        </ListItem>
      )}
      {userData.role === 1 && (
        <ListItem
          button
          component={Link}
          to={`/companies/${
            userData.companyIds && userData.companyIds[0]
          }/details`}
          selected={selectedIndex === 1}
          className={`menu-link fs-16 fw-medium ${
            selectedIndex === 1 ? 'selected-menu' : ''
          }`}
          onClick={() => setSelectedIndex(1)}>
          <Tooltip title='Companies' placement='right'>
            <ListItemIcon>
              <OfficeBuilding />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Company</ListItemText>
        </ListItem>
      )}
      {userData.role === 0 && (
        <ListItem
          button
          component={Link}
          to={`/super-admins`}
          selected={selectedIndex === 2}
          className={`menu-link fs-16 fw-medium ${
            selectedIndex === 2 ? 'selected-menu' : ''
          }`}
          onClick={() => setSelectedIndex(2)}>
          <Tooltip title='Super Admins' placement='right'>
            <ListItemIcon>
              <AccountGroupOutline />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Super Admins</ListItemText>
        </ListItem>
      )}
      {userData.role === 0 && (
        <ListItem
          button
          component={Link}
          to={`/configs`}
          selected={selectedIndex === 3}
          className={`menu-link fs-16 fw-medium ${
            selectedIndex === 3 ? 'selected-menu' : ''
          }`}
          onClick={() => setSelectedIndex(3)}>
          <Tooltip title='Configurations' placement='right'>
            <ListItemIcon>
              <CogOutline />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Configurations</ListItemText>
        </ListItem>
      )}
    </List>
  );
};

export default SideMenu;
