import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
// import { ICompany } from "../../../vm";
import { Tabs, Tab, Grid, Typography, Button } from "@material-ui/core";
import ProjectMain from "../../projects/ProjectMain";
import PartnerUserMain from "../users/PartnerUserMain";
import Loading from "../../common/Loading";
import { ToastContext } from "../../common/ToastProvider";
import { searchPartners } from "../../../services/PartnerService";
// import { getACompany } from "../../../services/CompanyService";
import ClientDashBoardMain from "./ClientDashBoardMain";
import { ArrowLeft } from "mdi-material-ui";

const ClientTab: React.FC = () => {
  const { tabName, companyId, clientId } = useParams();
  const history = useHistory();
  const [isLoading, setLoading] = React.useState(false);
  const { showToast } = React.useContext(ToastContext);
  const [selectedTab, setSelectedTab] = React.useState(tabName);
  // const [companyDetails, setCompanyDetails] = React.useState<ICompany>(
  //   null as any
  // );
  const [clientDetails, setClientDetails] = React.useState<any>(null as any);

  React.useEffect(() => {
    console.log("companyId", companyId);
    setLoading(true);
    const fetchPartner = async () => {
      const result = await searchPartners({
        partnerId: clientId,
        companyId: companyId,
      });
      if (result?.success) {
        setClientDetails(result.data[0] as any);
      } else {
        showToast(
          result?.message
            ? result.message
            : "Error while fetching client details",
          "error"
        );
      }
    };
    fetchPartner();
    // const fetchCompany = async () => {
    //   const result = await getACompany(companyId);
    //   if (result?.success) {
    //     setCompanyDetails(result.data as any);
    //   } else {
    //     showToast(
    //       result?.message ? result.message : "Error while fetching company",
    //       "error"
    //     );
    //   }
    // };
    // fetchCompany();
    setLoading(false);
  }, []);

  const redirectToBack = () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item>
              <Typography className="title" variant="h5">
                <Button variant="outlined" onClick={redirectToBack}>
                  <ArrowLeft /> Back
                </Button>{" "}
                &nbsp; Client Name - {clientDetails?.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => {
          setSelectedTab(newValue);
          history.replace(
            `/companies/${companyId}/clients/${clientId}/${newValue}`
          );
        }}
        className="overflow-auto tabs-container"
        aria-label="client tabs"
      >
        <Tab
          className={`tab-menu ${
            selectedTab === "dashboard" ? "tab-selected" : ""
          }`}
          label="Dashboard"
          value="dashboard"
        />
        <Tab
          className={`tab-menu ${
            selectedTab === "projects" ? "tab-selected" : ""
          }`}
          label="Projects"
          value="projects"
        />
        <Tab
          className={`tab-menu ${
            selectedTab === "users" ? "tab-selected" : ""
          }`}
          label="Contacts"
          value="users"
        />
      </Tabs>
      {selectedTab === "dashboard" && (
        <ClientDashBoardMain clientId={clientId} />
      )}
      {selectedTab === "projects" && <ProjectMain partnerId={clientId} />}
      {selectedTab === "users" && <PartnerUserMain partnerId={clientId} />}
    </React.Fragment>
  );
};

export default ClientTab;
