import * as React from "react";
import { Formik } from "formik";
import { IProject } from "../../vm";
import {
  FormControl,
  Button,
  TextField,
  Grid,
  InputLabel,
  Typography,
} from "@material-ui/core";
import {
  addAProject,
  updateAProject,
} from "../../services/projects/ProjectService";
import { ToastContext } from "../common/ToastProvider";
import { DATE_PICKER_FORMAT, CURRENCY } from "../../Constant";
import moment from "moment";
import AsyncSelect from "react-select/async";
import Loading from "../common/Loading";
import { debounce } from "../../services/UtilService";

interface onCloseFunc {
  (data?: IProject, isAdd?: boolean): void;
}

interface IAddOrUpdateProjectProps {
  onClose: onCloseFunc;
  project?: IProject;
  companyId: string;
  clientId: string;
}

interface IReactSelectOptions {
  value: any;
  label: any;
}

// const getUserSelectOptions = (user: any) => {
//   return {
//     value: user?._id,
//     label: getUserFullName(user),
//   };
// };

const currency_values = JSON.parse(CURRENCY);

const DEFAULT_CURRENCY_OPTIONS: Array<IReactSelectOptions> = [
  "united-states-dollar",
  "united-kingdom-pound",
  "euro-member-countries",
  "india-rupee",
].map((x) => {
  return {
    label: `${currency_values[x].short_name}(${currency_values[x].code})`,
    value: x,
  };
});

const AddOrUpdateProject: React.FC<IAddOrUpdateProjectProps> = ({
  onClose,
  project,
  companyId,
  clientId,
}) => {
  const { showToast } = React.useContext(ToastContext);
  const [isLoading, setLoading] = React.useState(false);

  // const fetchUsers = async (inputValue: string) => {
  //   const result = await searchUsers({
  //     search: inputValue,
  //     companyId: companyId,
  //     offset: 0,
  //     limit: 10,
  //   });

  //   if (result?.success) {
  //     return result.data.map((d) => ({ value: d._id, label: d.fullName }));
  //   } else {
  //     showToast(
  //       result?.message ? result.message : 'Error while getting users',
  //       'error'
  //     );
  //   }
  //   return [];
  // };

  const searchCurrency = async (input: any, callback: Function) => {
    if (
      input === null ||
      input === undefined ||
      input === "" ||
      input.trim().length <= 1
    ) {
      callback(null, { options: [] });
    } else {
      const currencyArray: Array<IReactSelectOptions> = [];
      Object.keys(currency_values).map((ele) =>
        currencyArray.push({
          label: `${currency_values[ele].short_name}(${currency_values[ele].code})`,
          value: ele,
        })
      );
      callback(
        currencyArray.filter((i) =>
          i.label.toLowerCase().includes(input.toLowerCase())
        )
      );
    }
  };
  // const fetchApprover = async (inputValue?: string) => {
  //   if (inputValue && inputValue.length > 0) {
  //     return await fetchUsers(inputValue);
  //   }
  //   return [];
  // };

  // const fetchHRs = async (inputValue?: string) => {
  //   if (inputValue && inputValue.length > 0) {
  //     return await fetchUsers(inputValue);
  //   }
  //   return [];
  // };

  return (
    <div className="p-16">
      {isLoading && <Loading message="processing..." />}
      <br />
      <Formik
        initialValues={
          project
            ? {
                ...project,
                // hr_data: getUserSelectOptions(project?.hr_data),
                client_data: {
                  value: project?.client_data?._id,
                  label: project?.client_data?.name,
                },
                currencyData: project.currency
                  ? {
                      label: `${currency_values[project.currency].short_name}(${
                        currency_values[project.currency].code
                      })`,
                      value: project.currency,
                    }
                  : undefined,
                // approver_data: project?.approver_data.map((d: any) =>
                //   getUserSelectOptions(d)
                // ),
              }
            : {
                companyId: companyId,
                name: "",
                startDate: "",
                endDate: "",
                approverIds: [],
                hrId: "",
                SOWNumber: "",
                clientId: clientId,
                // hr_data: undefined,
                client_data: undefined,
                currency: undefined,
                currencyData: undefined,
                // approver_data: [],
              }
        }
        validate={(values: IProject) => {
          const errors: any = {};
          if (!values.name) errors.name = "Required";
          if (!values.startDate) errors.startDate = "Required";
          if (!values.endDate) errors.endDate = "Required";
          // if (!values.hrId) errors.hrId = 'Required';
          // if (!values.clientId) errors.clientId = "Required";
          if (!values.SOWNumber) errors.SOWNumber = "Required";
          if (!values.currency) errors.currency = "Required";
          // if (!values.approverIds) errors.approverIds = 'Required';
          // if (values.approverIds && values.approverIds.length === 0)
          //   errors.approverIds = 'Please Select at least on Approver ';

          return errors;
        }}
        onSubmit={async (
          values: IProject,
          { setSubmitting }: { setSubmitting: Function }
        ) => {
          setLoading(true);
          console.log("project", values);
          const obj = { ...values };

          let result;
          if (obj._id) {
            result = await updateAProject(obj._id, obj);
          } else {
            result = await addAProject(obj);
          }

          if (result?.success) {
            onClose(result.data, obj._id ? false : true);

            showToast(
              `${obj._id ? "Updated" : "Added"} successfully`,
              "success"
            );
          } else {
            showToast(
              result?.message ? result.message : "Error while adding project",
              "error"
            );
          }
          setSubmitting(false);
          setLoading(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl required fullWidth>
                  <TextField
                    size="small"
                    name="name"
                    label="Title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.name && touched.name ? true : false}
                    helperText={errors.name && touched.name && errors.name}
                    value={values.name}
                  />
                </FormControl>
              </Grid>

              {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                <InputLabel className="custom-react-select-label">
                  Client
                </InputLabel>
                <AsyncSelect
                  className="async-select react-select"
                  cacheOptions
                  value={values.client_data}
                  defaultOptions
                  onBlur={handleBlur}
                  placeholder="Search by name"
                  loadOptions={fetchClient}
                  onChange={(newValue: any) => {
                    setFieldValue("clientId", newValue?.value);
                    setFieldValue("client_data", newValue || {});
                  }}
                />
                {errors.clientId && touched.clientId && (
                  <Typography variant="caption" color="error">
                    {errors.clientId}
                  </Typography>
                )}
              </Grid> */}

              {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                <InputLabel className='custom-react-select-label'>
                  HR
                </InputLabel>
                <AsyncSelect
                  className='async-select react-select'
                  cacheOptions
                  defaultOptions
                  value={values.hr_data}
                  placeholder='Search by name, email'
                  loadOptions={fetchHRs}
                  onChange={(newValue: any) => {
                    setFieldValue('hrId', newValue?.value);
                    setFieldValue('hr_data', newValue || {});
                  }}
                />
                {errors.hrId && touched.hrId && (
                  <Typography variant='caption' color='error'>
                    {errors.hrId}
                  </Typography>
                )}
              </Grid> */}

              {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                <InputLabel className='custom-react-select-label'>
                  Approvers
                </InputLabel>
                <AsyncSelect
                  className='async-select react-select'
                  cacheOptions
                  isMulti
                  defaultOptions
                  value={values.approver_data}
                  placeholder='Search by name'
                  loadOptions={fetchApprover}
                  onChange={(newValue: any) => {
                    // console.log('newValue', newValue);
                    const approvalIds = newValue
                      ? newValue.map((d: any) => d.value)
                      : [];
                    setFieldValue('approverIds', approvalIds);
                    setFieldValue('approver_data', newValue || []);
                  }}
                />
                {errors.approverIds && touched.approverIds && (
                  <Typography variant='caption' color='error'>
                    {errors.approverIds}
                  </Typography>
                )}
              </Grid> */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl required fullWidth>
                  <TextField
                    size="small"
                    name="SOWNumber"
                    label="SOW Number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.SOWNumber && touched.SOWNumber ? true : false}
                    helperText={
                      errors.SOWNumber && touched.SOWNumber && errors.SOWNumber
                    }
                    value={values.SOWNumber}
                  />
                </FormControl>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <InputLabel className="custom-react-select-label">
                  Currency
                </InputLabel>
                <AsyncSelect
                  isSearchable
                  defaultValue={values.currencyData}
                  className="async-select react-select"
                  placeholder="Search/Select Currency"
                  autoload={true}
                  isClearable={true}
                  loadOptions={debounce(searchCurrency, 500)}
                  defaultOptions={DEFAULT_CURRENCY_OPTIONS}
                  onChange={(newValue) => {
                    setFieldValue("currency", newValue?.value);
                    setFieldValue("currencyData", newValue || {});
                  }}
                />
                {errors.currency && touched.currency && (
                  <Typography variant="caption" color="error">
                    &nbsp; &nbsp; {errors.currency}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    name="startDate"
                    type="date"
                    label="Start Date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.startDate && touched.startDate ? true : false}
                    helperText={
                      errors.startDate && touched.startDate && errors.startDate
                    }
                    value={moment(values.startDate).format(DATE_PICKER_FORMAT)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    name="endDate"
                    type="date"
                    label="End Date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.endDate && touched.endDate ? true : false}
                    helperText={
                      errors.endDate && touched.endDate && errors.endDate
                    }
                    value={moment(values.endDate).format(DATE_PICKER_FORMAT)}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <br />
            <br />
            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  className="cancel-btn"
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  className="add-btn"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {project ? "Update" : "Add"}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddOrUpdateProject;
