import * as React from 'react';
import { IHoliday } from '../../vm';

import { ToastContext } from '../common/ToastProvider';
import { ConfirmDialogContext } from '../common/ConfirmDialogProvider';
import { useParams } from 'react-router-dom';
import { getHolidays, saveHolidays } from '../../services/HolidayService';
import Loading from '../common/Loading';
import {
  Grid,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  FormControl,
} from '@material-ui/core';
import Empty from '../common/Empty';
import { TableHederCell } from '../common/Library';
import { TrashCanOutline } from 'mdi-material-ui';
import { Formik } from 'formik';
import moment from 'moment';
import { DATE_FORMAT_2 } from '../../Constant';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { DotsVertical } from 'mdi-material-ui';

const getSortedArray = (arr: IHoliday[]) => {
  return arr.sort(function (aa: IHoliday, bb: IHoliday) {
    const a = new Date(aa.date);
    const b = new Date(bb.date);
    return a > b ? 1 : a < b ? -1 : 0;
  });
};

interface IHolidaysMainProps {}

const HolidaysMain: React.FC<IHolidaysMainProps> = () => {
  const { showConfirmDialog } = React.useContext(ConfirmDialogContext);
  const { showToast } = React.useContext(ToastContext);
  const { companyId } = useParams();
  const [isLoading, setLoading] = React.useState(false);
  const [holidays, setHolidays] = React.useState([] as IHoliday[]);

  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );

  const [selectedTimeSheetIndex, setSelectedTimeSheetIndex] = React.useState<
    number
  >(-1);

  React.useEffect(() => {
    searchHoliday();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchHoliday = async () => {
    setLoading(true);
    const result = await getHolidays(companyId);
    if (result?.success) {
      setHolidays(result.data);
    } else {
      showToast(
        result?.message ? result.message : 'Error while getting holidays list',
        'error'
      );
    }
    setLoading(false);
  };

  const removeHoliday = async (index: number) => {
    showConfirmDialog('Are you sure', 'Do you want to delete?', async () => {
      setLoading(true);
      const tempHolidays = [...holidays];
      tempHolidays.splice(index, 1);

      const result = await saveHolidays(companyId, tempHolidays);
      if (result && result.success) {
        setHolidays(tempHolidays);
        showToast(
          'Holiday removed successfully',
          'success'
        );
      } else {
        showToast(
          result?.message ? result.message : 'Error while deleting holiday',
          'error'
        );
      }
      setLoading(false);
    });
  };

  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setSelectedTimeSheetIndex(index);
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSelectedTimeSheetIndex(-1);
    setAnchorElMenu(null);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading message='Loading...' />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper className='card'>
            <Formik
              initialValues={{
                name: '',
                date: '',
              }}
              validate={(values: IHoliday) => {
                const errors: any = {};
                if (!values.name) errors.name = 'Required';
                if (!values.date) errors.date = 'Required';

                return errors;
              }}
              onSubmit={async (
                values: IHoliday,
                {
                  setSubmitting,
                  resetForm,
                }: { setSubmitting: Function; resetForm: Function }
              ) => {
                setLoading(true);
                console.log('user', values);
                const obj = { ...values };
                const tempHolidays = getSortedArray([...holidays, obj]);
                const result = await saveHolidays(companyId, tempHolidays);
                if (result && result.success) {
                  setHolidays(tempHolidays);
                  resetForm();
                  showToast("Holidays updated successfully","success");
                } else {
                  showToast(
                    result?.message
                      ? result.message
                      : 'Error while adding holiday',
                    'error'
                  );
                }

                setSubmitting(false);
                setLoading(false);
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid alignItems='center' container spacing={2}>
                    <Grid item className='flex-grow'>
                      <FormControl required fullWidth>
                        <TextField
                          size='small'
                          name='name'
                          label='Holiday Name'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.name && touched.name ? true : false}
                          helperText={
                            errors.name && touched.name && errors.name
                          }
                          value={values.name}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                      <FormControl required fullWidth>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          size='small'
                          name='date'
                          label='Select Date'
                          type='date'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.date && touched.date ? true : false}
                          helperText={
                            errors.date && touched.date && errors.date
                          }
                          value={moment(values.date).format('YYYY-MM-DD')}
                        />
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={8} sm={4} md={1} lg={1}> */}
                    <Grid item>
                      <Button
                        type='submit'
                        variant='contained'
                        className='add-btn'
                        color='primary'
                        // fullWidth
                        disabled={isSubmitting}>
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {holidays.length === 0 ? (
            <Empty message="No Holidays Yet!!"/>
          ) : (
            <TableContainer component={Paper} className='timesheet-table'>
              <Table aria-label='Company table'>
                <TableHead>
                  <TableRow>
                    <TableHederCell>Holiday Name</TableHederCell>
                    <TableHederCell>Date</TableHederCell>

                    <TableHederCell align='right'>Actions</TableHederCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {holidays.map((holiday, index: number) => (
                    <TableRow key={index}>
                      <TableCell component='th' scope='row'>
                        {holiday.name}
                      </TableCell>
                      <TableCell>
                        {moment(holiday.date).format(DATE_FORMAT_2)}
                      </TableCell>
                      <TableCell align='right'>
                        <Button
                          aria-controls='simple-menu'
                          aria-haspopup='true'
                          onClick={(e) => handleClickMenu(e, index)}>
                          <DotsVertical />
                        </Button>
                        {/* 
                        <Grid container justify="flex-end">
                          <Grid item>
                            <Tooltip title="Remove Holiday">
                              <IconButton
                                color="primary"
                                onClick={() => removeHoliday(index)}
                              >
                                <TrashCanOutline />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid> */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
      <Menu
        id='simple-menu'
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}>
        <MenuItem
          onClick={() => {
            removeHoliday(selectedTimeSheetIndex);
            handleCloseMenu();
          }}>
          <TrashCanOutline />
          &nbsp; Delete
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default HolidaysMain;
