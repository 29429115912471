import React from 'react';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import { Close } from 'mdi-material-ui';

type snackType = 'success' | 'warn' | 'error' | 'default';
interface IDispatch {
  message?: string;
  type?: snackType;
  dismiss?: boolean;
}
interface dispatchFunc {
  (params: IDispatch): void;
}

interface showToastFunc {
  (message: string, type: snackType, options?: Object): void;
}
interface IState {
  visible: boolean;
  message: string;
  // icon: string;
  color?: string;
}

export interface IToast{
  showToast: showToastFunc;
}

export const ToastContext = React.createContext<IToast>({
  // show: () => {},
  showToast: () => {},
});

export const ToastProvider: React.FC<any> = (props) => {
  const [state, dispatch]: [IState, dispatchFunc] = React.useReducer(
    (prevState: any, action: IDispatch) => {
      let color;

      if (action.dismiss === true) {
        return {
          ...prevState,
          visible: false,
        };
      }

      switch (action.type) {
        case 'success':
          color = '#357a38';
          break;
        case 'warn':
          color = '#ffa000';
          break;
        case 'error':
          color = '#d32f2f';
          break;
        default:
          color = '#313131';
          break;
      }

      return {
        ...prevState,
        visible: true,
        // icon,
        color: color,
        message: action.message,
      };
    },
    { visible: false, message: '' }
  );
  const toastContext = React.useMemo(
    () => ({
      // show: (message: string, type: snackType) => {
      //   dispatch({ type, message });
      // },
      showToast: (message: string, type: snackType) => {
        dispatch({ type, message });
      },
    }),
    []
  );
  return (
    <ToastContext.Provider value={toastContext}>
      <>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={state.visible}
          onClose={() => dispatch({ dismiss: true })}
          autoHideDuration={3000}>
          <SnackbarContent
            // onClose={this.handleClose}
            style={{ backgroundColor: state.color }}
            message={<span id='toast'>{state.message}</span>}
            action={[
              <IconButton
                key='close'
                aria-label='Close'
                color='inherit'
                onClick={() => dispatch({ dismiss: true })}>
                <Close />
              </IconButton>,
            ]}
          />
        </Snackbar>
        {props.children}
      </>
    </ToastContext.Provider>
  );
};

export const withToastContext = (ChildComponent: any) => (props: any) => {
  const { showToast } = React.useContext(ToastContext);
  return <ChildComponent {...props} showToast={ showToast } />;
};

/************************* old class component********************************* */

// import React from 'react';
// import SnackbarContent from '@material-ui/core/SnackbarContent';
// import Snackbar from '@material-ui/core/Snackbar';
// import IconButton from '@material-ui/core/IconButton';

// import { Close } from 'mdi-material-ui';

// export interface IToastContext {}

// const ToastContext = React.createContext<IToastContext>({});

// export interface ToastProviderProps {}

// type snackType = 'success' | 'warn' | 'error' | 'default';
// interface showFun {
//   (message: string, type: snackType, options?: Object): void;
// }

// export interface ToastProviderState {
//   color: string;
//   options: any;
//   open: boolean;
//   show: showFun;
//   message: string;
// }

// export class ToastProvider extends React.Component<
//   ToastProviderProps,
//   ToastProviderState
// > {
//   constructor(props: ToastProviderProps) {
//     super(props);
//     this.state = {
//       open: false,
//       options: {},
//       color: '',
//       message: '',
//       show: (message: string, type: snackType, options?: Object) => {
//         let color;
//         switch (type) {
//           case 'success':
//             color = '#357a38';
//             break;
//           case 'warn':
//             color = '#ffa000';
//             break;
//           case 'error':
//             color = '#d32f2f';
//             break;

//           default:
//             color = '#313131';
//             break;
//         }
//         this.setState({
//           open: !this.state.open,
//           color: color,
//           message,
//           options,
//         });
//         setTimeout(() => {
//           this.setState({
//             open: false,
//           });
//         }, 3000);
//       },
//     };
//   }
//   handleClose = () => {
//     this.setState({
//       open: false,
//     });
//   };
//   render() {
//     return (
//       <ToastContext.Provider
//         value={{
//           ...this.state,
//         }}>
//         <>
//           <Snackbar
//             anchorOrigin={{
//               vertical: 'top',
//               horizontal: 'right',
//             }}
//             open={this.state.open}
//             autoHideDuration={4000}>
//             <SnackbarContent
//               // onClose={this.handleClose}
//               style={{ backgroundColor: this.state.color }}
//               message={<span id='toast'>{this.state.message}</span>}
//               action={[
//                 <IconButton
//                   key='close'
//                   aria-label='Close'
//                   color='inherit'
//                   onClick={() => this.setState({ open: false })}>
//                   <Close />
//                 </IconButton>,
//               ]}
//             />
//           </Snackbar>
//           {this.props.children}
//         </>
//       </ToastContext.Provider>
//     );
//   }
// }

// export const withToastContext = (ChildComponent: any) => (props: any) => (
//   <ToastContext.Consumer>
//     {(context) => <ChildComponent {...props} toast={context} />}
//   </ToastContext.Consumer>
// );
