import * as React from "react";
import { Formik } from "formik";
import { IPartnerUser } from "../../../vm";
import { FormControl, Button, TextField, Grid, InputLabel, Typography } from "@material-ui/core";
import {
  addAPartnerUser,
  updateAPartnerUser,
} from "../../../services/PartnerUserService";
import { ToastContext } from "../../common/ToastProvider";

import Loading from "../../common/Loading";
import { PHONE_REGEX ,COUNTRY_CODES} from "../../../Constant";
import ReactSelect from "react-select";

interface onCloseFunc {
  (data?: IPartnerUser, isAdd?: boolean): void;
}

interface IAddOrUpdatePartnerUserProps {
  onClose: onCloseFunc;
  partner?: IPartnerUser;
  partnerId: string;
}
const country_codes_values: Array<string> = JSON.parse(COUNTRY_CODES);

const AddOrUpdatePartnerUser: React.FC<IAddOrUpdatePartnerUserProps> = ({
  onClose,
  partner,
  partnerId,
}) => {
  const { showToast } = React.useContext(ToastContext);
  const [isLoading, setLoading] = React.useState(false);
  return (
    <div className="p-16">
      {isLoading && <Loading message="processing..." />}
      <Formik
        initialValues={
          partner
            ? partner
            : {
                name: "",
                partnerId: partnerId,
                email: "",
                phone: "",
                countryCode: "+1",
              }
        }
        validate={(values: IPartnerUser) => {
          const errors: any = {};
          if (!values.name) errors.name = "Required";
          //   if (!values.lastName) errors.lastName = 'Required';
          if (!values.email) errors.email = "Required";
            if (!values.phone) errors.phone = 'Required';
            if (values.phone && PHONE_REGEX.test(values.phone) === false) {
              errors.phone = "Please enter phone number of format 9876543210";
            }
            if (values.phone != null) {
              if (!values.countryCode) errors.countryCode = "Required";
            }
          //   if (!values.address) errors.address = 'Required';
          //   if (!values.type) errors.type = 'Required';
          //   if (values._id == null && !values.password)
          //     errors.password = 'Required';

          return errors;
        }}
        onSubmit={async (
          values: IPartnerUser,
          { setSubmitting }: { setSubmitting: Function }
        ) => {
          setLoading(true);
          console.log("user", values);
          const obj = { ...values };

          let result;
          if (obj._id) {
            result = await updateAPartnerUser(obj._id, obj);
          } else {
            result = await addAPartnerUser(obj);
          }

          if (result?.success) {
            onClose(result.data, obj._id ? false : true);

            showToast(
              `${obj._id ? "Updated" : "Added"} successfully`,
              "success"
            );
          } else {
            showToast(
              result?.message ? result.message : "Error while adding contact",
              "error"
            );
          }
          setSubmitting(false);
          setLoading(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="name"
                    label="Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.name && touched.name ? true : false}
                    helperText={errors.name && touched.name && errors.name}
                    value={values.name}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="email"
                    type="email"
                    label="Email"
                    autoComplete="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email ? true : false}
                    helperText={errors.email && touched.email && errors.email}
                    value={values.email}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className="custom-react-select-form mt-16i"
              >
                <InputLabel className="custom-react-select-label">
                  Country code
                </InputLabel>
                <ReactSelect
                  // isSearchable
                  value={{
                    value: values.countryCode,
                    label: values.countryCode,
                  }}
                  className="async-select react-select"
                  placeholder="Country code"
                  // autoload={true}
                  isClearable={false}
                  options={country_codes_values.map((x) => {
                    return {
                      label: x,
                      value: x,
                    };
                  })}
                  // defaultOptions={['+1', '+44', '+91'].map((x) => {
                  //   return {
                  //     label: x,
                  //     value: x,
                  //   };
                  // })}
                  onChange={(newValue: any) => {
                    console.log("newValue", newValue);
                    setFieldValue("countryCode", newValue?.value);
                  }}
                />
                {errors.countryCode && (
                  <Typography variant="caption" color="error">
                    &nbsp; &nbsp;{errors.countryCode}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="phone"
                    label="Phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.phone && touched.phone ? true : false}
                    helperText={errors.phone && touched.phone && errors.phone}
                    value={values.phone}
                  />
                </FormControl>
              </Grid>

              {/* {values._id == null && (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl margin='normal' required fullWidth>
                    <TextField
                      size='small'
                      name='password'
                      type='password'
                      label='Password'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.password && touched.password ? true : false}
                      helperText={
                        errors.password && touched.password && errors.password
                      }
                      value={values.password}
                    />
                  </FormControl>
                </Grid>
              )} */}
            </Grid>
            <br />

            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <Button
                  className="cancel-btn"
                  variant="outlined"
                  color="secondary"
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className="add-btn"
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {partner ? "Update" : "Add"}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddOrUpdatePartnerUser;
