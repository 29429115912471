import React from "react";
import Routes from "./Routes";
import { ConfirmDialogProvider } from "./components/common/ConfirmDialogProvider";
import { ToastProvider } from "./components/common/ToastProvider";
import { ThemeProvider } from "@material-ui/core";
import { CompanyProvider } from "./components/common/CompanyProvider";
import { THEME } from "./Styles";
import "./components/auth/un-auth.css";

const App: React.FC = () => {
  return (
    <React.Fragment>
      <ThemeProvider theme={THEME}>
        <ConfirmDialogProvider>
          <ToastProvider>
            <CompanyProvider>
              <Routes />
            </CompanyProvider>
          </ToastProvider>
        </ConfirmDialogProvider>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
