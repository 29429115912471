import { IStandardAPIResponse, ICompany } from '../vm';
import { httpClient } from './UtilService';

export async function getCompanyByOrigin(): Promise<
  IStandardAPIResponse<ICompany>
> {
  try {
    let res = await httpClient<ICompany>(
      `company-details-by-admin-origin?origin=${window.location.origin}`,
      'GET'
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
