import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import NavBar from "./components/common/NavBar";
import RoutesMain from "./RoutesMain";

export interface RoutesProps {}

const Routes: React.FC<RoutesProps> = () => {
  return (
    <Router>
      <div style={{ display: "flex" }}>
        <CssBaseline />
        <NavBar />
        <RoutesMain />
      </div>
    </Router>
  );
};

export default Routes;
