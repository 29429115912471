import React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  removeToken,
  isTokenExpired,
  parseJwt,
} from "../../services/UtilService";
import withWidth from "@material-ui/core/withWidth";
import { ChevronLeft, ChevronRight, Logout } from "mdi-material-ui";
import { ClassProps } from "../../vm";
import SideMenu from "./SideMenu";
import { Avatar } from "@material-ui/core";
import { USER_ROLES } from "../../Constant";
import "./sidemenu.css";
const drawerWidth = 280;

export interface NavBarProps extends RouteComponentProps, ClassProps {
  width?: string;
}

export interface NavBarState {
  open: boolean;
  openPopOver: boolean;
  anchorE1: HTMLButtonElement | null;
  userData: any;
}

class NavBar extends React.Component<NavBarProps, NavBarState> {
  constructor(props: NavBarProps) {
    super(props);
    this.state = {
      open:
        this.props.width === "sm" || this.props.width === "xs" ? false : true,
      openPopOver: false,
      anchorE1: null,
      userData: parseJwt(),
    };
  }
  componentDidMount = () => {
    this.setState({ userData: parseJwt() });
  };
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: !this.state.open });
  };
  handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorE1: event.currentTarget,
      openPopOver: true,
      userData: parseJwt(),
    });
  };
  handleClosePopover = () => {
    this.setState({ anchorE1: null, openPopOver: false });
  };

  render() {
    const { classes } = this.props;
    let tokenData = parseJwt();
    document.title = tokenData&&tokenData.role===1?"Company Admin Portal":"Admin Portal"
    return (
      <>
        {!isTokenExpired() ? (
          <>
            {/* <AppBar
              color="default"
              position="absolute"
              className={`${classes.appBar} ${
                this.state.open && classes.appBarShift
              }`}
            >
              <Toolbar
                disableGutters={!this.state.open}
                className={classes.toolbar}
                variant="dense"
              >
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.handleDrawerOpen}
                  className={`${classes.menuButton} ${
                    this.state.open && classes.menuButtonHidden
                  }`}
                >
                  <Menu />
                </IconButton>
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  className={classes.title}
                >
                  Timesheet Portal
                </Typography>

                <Grid>
                  <Button
                    aria-describedby="simple-popover"
                    color="inherit"
                    onClick={this.handlePopover}
                  >
                    <AccountCircle />
                  </Button>
                  <Popover
                    id="simple-popover"
                    open={this.state.openPopOver}
                    anchorEl={this.state.anchorE1}
                    onClose={this.handleClosePopover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Paper className="p-16">
                      <Grid container spacing={1}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item>
                              <Avatar>
                                {this.state.userData
                                  ? this.state.userData.firstName.charAt(0)
                                  : ""}
                              </Avatar>
                            </Grid>
                            <Grid item>
                              <Typography variant="h5">
                                {this.state.userData?.firstName +
                                  (this.state.userData?.lastName
                                    ? ` ${this.state.userData.lastName}`
                                    : "")}
                              </Typography>
                              <Typography variant="caption">
                                {USER_ROLES[this.state.userData?.role || 0]}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Button
                            fullWidth
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              removeToken();
                              this.handleClosePopover();
                              this.props.history.push("/");
                            }}
                          >
                            Logout
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Popover>
                </Grid>
              </Toolbar>
            </AppBar> */}
            <Drawer
              variant={
                this.props.width
                  ? this.props.width === "sm" || this.props.width === "xs"
                    ? "permanent"
                    : "permanent"
                  : "permanent"
              }
              classes={{
                paper: `${classes.drawerPaper} ${
                  !this.state.open && classes.drawerPaperClose
                } side-menu`,
              }}
              open={this.state.open}
            >
              <div className="d-flex flex-column side-menu-container">
                <div>
                  {this.state.open ? (
                    <div className="time-sheet-header">{tokenData&&tokenData.role===1?"Company Admin Portal":"Admin Portal"}</div>
                  ) : (
                    <div className="time-sheet-header xs">{tokenData&&tokenData.role===1?"CAP":"AP"}</div>
                  )}

                  <br />

                  <div className="d-flex flex-column align-items-center">
                    <Avatar className="profile-pic">
                      {tokenData ? tokenData.firstName.charAt(0) : ""}
                    </Avatar>
                    {this.state.open && (
                      <React.Fragment>
                        <p className="text-white fs-16 m-0 mt-8">
                          {`${tokenData?.firstName} ${tokenData?.lastName}`}
                        </p>
                        <p className="text-white fs-14 m-0 mt-8">
                          {USER_ROLES[tokenData?.role || 0]}
                        </p>
                      </React.Fragment>
                    )}
                  </div>
                  <br />
                  <SideMenu isMenuOpened={this.state.open} />
                </div>
                <div>
                  <Divider />
                  <div className="d-flex justify-content-between">
                    <Button
                      className="text-white logout-btn"
                      onClick={() => {
                        removeToken();
                        this.handleClosePopover();
                        this.props.history.push("/");
                      }}
                    >
                      <Logout />
                      {/* <img src="/images/icons/logout.svg" alt="logout" /> */}
                      {this.state.open ? "Logout" : ""}
                    </Button>
                    {this.props.width !== "sm" && this.props.width !== "xs" && (
                      <div className={classes.toolbarIcon}>
                        <IconButton
                          className="text-white"
                          onClick={this.handleDrawerClose}
                        >
                          {this.state.open ? <ChevronLeft /> : <ChevronRight />}
                        </IconButton>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Drawer>
          </>
        ) : (
          <React.Fragment>
            {/* <AppBar
              position="absolute"
              color="default"
              className={`${classes.appBar} header-not-authenticated`}
            >
              <Toolbar variant="dense">
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <img
                      src="images/she-safe-logo.png"
                      alt=""
                      className="logo"
                    />
                  </Grid>
                  <Grid item className="fs-20">
                    <Link
                      component="a"
                      color="inherit"
                      href="/"
                      target="_blank"
                    >
                      Home
                    </Link>
                    &nbsp; &nbsp;
                    <Link
                      component="a"
                      color="inherit"
                      href="/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </Link>
                    &nbsp; &nbsp;
                    <Link
                      component="a"
                      color="inherit"
                      href="/terms-of-use"
                      target="_blank"
                    >
                      Terms and conditions
                    </Link>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar> */}
          </React.Fragment>
        )}
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    logoImage: {
      height: 48,
      marginRight: 16,
      marginTop: 8,
      marginBottom: 8,
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      ...theme.mixins.toolbar,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      minHeight: "48px !important",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: "none",
      border: "1px solid #ddd",
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 100,
      [theme.breakpoints.down("sm")]: {
        width: 80,
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      height: "100vh",
      overflow: "auto",
    },
    chartContainer: {
      marginLeft: -22,
    },
    tableContainer: {
      height: 320,
    },
    h5: {
      marginBottom: theme.spacing(2),
    },
  });
export default withStyles(styles)(withWidth()(withRouter(NavBar)));
