import * as React from 'react';
import { AlertCircleOutline } from 'mdi-material-ui';

interface IEmptyProps {
  message?: string;
}
const Empty: React.FC<IEmptyProps> = ({ message }) => {
  return (
    <React.Fragment>
      <br />
      <br />
      <br />
      <br />
      <div className='center' style={{ color: '#9e9e9e' }}>
        <AlertCircleOutline fontSize='large' />
        &nbsp;
        <h1>{message ? message : 'No Data Yet!!'}</h1>
      </div>
    </React.Fragment>
  );
};

export default Empty;
