import * as React from 'react';
import {
  getAllDocsOfAUser,
  updateDocsStatus,
  getCategoryDoc,
  updateCategoryDocsStatus,
} from '../../services/UserDocService';
import {
  IUserDoc,
  IUpdateDoc,
  updateDocStatus,
  IDrawerOpen,
  IAdmin,
} from '../../vm';
import {
  DOC_TITLE,
  DOC_STATUS,
  USER_CATEGORY,
  DATE_FORMAT_1,
} from '../../Constant';
import { ToastContext } from '../common/ToastProvider';
import Loading from '../common/Loading';
import Empty from '../common/Empty';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import { TableHederCell } from '../common/Library';
import RequestUserDoc from './RequestUserDoc';
import CustomDrawer from '../common/CustomDrawer';
import moment from 'moment';
import { parseJwt } from '../../services/UtilService';

const TEMP: { [Key: string]: string } = {
  approved: 'Approve',
  rejected: 'Reject',
};

const colorDict: { [Key: string]: string } = {
  rejected: 'red',
  approved: 'green',
  requested: 'orange',
};

interface IUserDocsMainProps {
  userId: string;
  category: 'citizen' | 'permanent-resident' | 'EAD' | 'H1B';
  onClose: Function;
}

const UserDocsMain: React.FC<IUserDocsMainProps> = ({
  userId,
  onClose,
  category,
}) => {
  const { showToast } = React.useContext(ToastContext);
  const [isLoading, setLoading] = React.useState(false);
  const [docs, setDocs] = React.useState([] as IUserDoc[]);
  const userData = parseJwt() as IAdmin;
  const [statusDict, setStatusDict] = React.useState(
    {} as { [Key: string]: { status?: updateDocStatus; comment?: string } }
  );
  const [catDoc, setCatDoc] = React.useState({
    // type: 'H1B',
    type: category,
    userId: userId,
    // status: 'submitted',
    comment: '',
    [category === 'permanent-resident' ? 'pr' : category]: {
      status: 'requested',
      type: category === 'H1B' ? 'H4' : undefined,
    },
  } as any);

  const [error, setError] = React.useState({} as any);

  const [catDocStatus, setCatDocStatus] = React.useState({
    status: 'select',
    comment: '',
  });
  const [decRegDrawer, setDocRegDrawer] = React.useState({
    isOpen: false,
  } as IDrawerOpen);

  React.useEffect(() => {
    category != null && getCatDoc();
    getAllDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCatDoc = async () => {
    const result = await getCategoryDoc({ userId, type: category as string });
    if (result?.success) {
      if (result.data) {
        setCatDoc(result.data);
        setCatDocStatus({
          comment: result.data.comment || '',
          status:
            result.data[
              result.data.type === 'permanent-resident'
                ? 'pr'
                : result.data.type
            ]?.status || 'select',
        });
      }
    } else {
      showToast(
        result?.message ? result.message : 'Error while getting user docs',
        'error'
      );
    }
  };

  const getAllDocs = async () => {
    setLoading(true);
    const result = await getAllDocsOfAUser(userId);
    if (result?.success) {
      setDocs(result.data);
    } else {
      showToast(
        result?.message ? result.message : 'Error while getting user docs',
        'error'
      );
    }
    setLoading(false);
  };
  const updateStatus = async () => {
    setLoading(true);
    const tempError: any = {};
    const tempDocs: IUpdateDoc[] = [];

    Object.keys(statusDict).forEach((d) => {
      if (statusDict[d]?.status) {
        tempDocs.push({
          _id: d,
          status: statusDict[d]?.status as updateDocStatus,
          comment: statusDict[d]?.comment,
        });
        if (statusDict[d]?.status === 'rejected' && !statusDict[d]?.comment) {
          tempError[d] = 'Required';
        }
      }
    });

    if (Object.keys(tempError).length > 0) {
      setError(tempError);
      setLoading(false);
      return;
    }

    if (catDocStatus.status === 'rejected') {
      if (!catDocStatus.comment) {
        tempError[catDoc._id] = 'Required';
      }
    }
    if (Object.keys(tempError).length > 0) {
      setError(tempError);
      setLoading(false);
      return;
    }

    if (tempDocs.length > 0) {
      const result = await updateDocsStatus({ docs: tempDocs });
      if (result?.success) {
        const temp = docs.map((d) => {
          if (
            statusDict[d._id as string] != null &&
            statusDict[d._id as string]?.status != null
          ) {
            d.status = statusDict[d._id as string]?.status as updateDocStatus;
            d.comment = statusDict[d._id as string]?.comment;
          }
          return d;
        });
        setDocs(temp);
        showToast("Docs updated successfully", "success");
      } else {
        showToast(
          result?.message ? result.message : 'Error while updating docs',
          'error'
        );
      }
    }
    // todo update category doc status
    if (catDocStatus.status !== 'select') {
      const catResult = await updateCategoryDocsStatus(catDoc._id, {
        ...catDocStatus,
        userId,
        type: catDoc.type,
      });
      if (catResult?.success) {
        setCatDoc(catResult.data);
      } else {
        showToast(
          catResult?.message
            ? catResult.message
            : 'Error while updating user docs',
          'error'
        );
      }
    }

    setLoading(false);
  };

  // let disableSubmitBTN = docs.find((d) => d.status !== 'approved') == null;
  // if (
  //   catDoc[catDoc.type === 'permanent-resident' ? 'pr' : catDoc.type]
  //     ?.status === 'approved'
  // ) {
  //   disableSubmitBTN = false;
  // }
  // console.log(
  //   'ddddddd',
  //   catDoc[catDoc.type === 'permanent-resident' ? 'pr' : catDoc.type]?.status
  // );
  // console.log('CC', docs.find((d) => d.status !== 'approved') == null);
  // console.log(
  //   'BBB',
  //   docs.find((d) => d.status !== 'approved') == null ||
  //     catDoc[catDoc.type === 'permanent-resident' ? 'pr' : catDoc.type]
  //       ?.status !== 'approved'
  // );
  return (
    <div className='p-16'>
      {isLoading && <Loading />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify='flex-end' spacing={1}>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                className='add-btn'
                disabled={userData.role !== 1 ? true : false}
                onClick={() => setDocRegDrawer({ isOpen: true })}>
                Request new documents
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {docs.length === 0 ? (
            <Empty message='No Documents Yet!!' />
          ) : (
            <>
              {/*  <TableContainer component={Paper}> */}
              <Table aria-label='Company table'>
                <TableHead>
                  <TableRow>
                    <TableHederCell width={250}>Document Name</TableHederCell>
                    <TableHederCell>Document</TableHederCell>
                    <TableHederCell width={200}>Status</TableHederCell>
                    <TableHederCell width={250}>Any Reason</TableHederCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {docs.map((doc) => (
                    <React.Fragment key={doc._id}>
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {['custom-doc', 'custom-text'].includes(doc.type)
                            ? doc.customTitle
                            : DOC_TITLE[doc.type]}
                        </TableCell>
                        <TableCell>
                          {doc.doc ? (
                            doc.type === 'custom-text' ? (
                              doc.doc
                            ) : (
                              <Link
                                href={doc.docUrl}
                                target='_blank'
                                rel='noreferrer'>
                                View Document
                              </Link>
                            )
                          ) : (
                            'N/A'
                          )}
                        </TableCell>
                        <TableCell>
                          {doc.status === 'submitted' && userData.role === 1 ? (
                            <FormControl size='small' required>
                              <Select
                                value={
                                  statusDict[doc._id as string]?.status ||
                                  'none'
                                }
                                renderValue={() => (
                                  <div className='capitalize'>
                                    {TEMP[
                                      statusDict[doc._id as string]
                                        ?.status as updateDocStatus
                                    ] || <em>Select</em>}
                                  </div>
                                )}
                                onChange={(event: any) => {
                                  const tempDict = statusDict;
                                  if (tempDict[doc._id as string] == null) {
                                    tempDict[doc._id as string] = {};
                                  }
                                  tempDict[doc._id as string].status =
                                    event.target.value;
                                  if (event.target.value === '')
                                    delete tempDict[doc._id as string];

                                  setStatusDict(tempDict);
                                  console.log('statusDict', statusDict);
                                }}>
                                {/* <MenuItem value=''>None</MenuItem> */}
                                <MenuItem value='rejected'>Reject</MenuItem>

                                <MenuItem value='approved'>Approve</MenuItem>
                              </Select>
                            </FormControl>
                          ) : (
                            <div
                              className={`capitalize ${
                                colorDict[doc.status as string] || ''
                              }`}>
                              {DOC_STATUS[doc.status as string]}
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          {doc.status === 'submitted' && userData.role === 1 ? (
                            <>
                              <FormControl fullWidth>
                                <TextField
                                  size='small'
                                  name='comment'
                                  label='Any Reason'
                                  // disabled={statusDict[doc._id as string] == null}
                                  onChange={(event: any) => {
                                    const tempDict = statusDict;
                                    if (tempDict[doc._id as string] == null) {
                                      tempDict[doc._id as string] = {};
                                    }
                                    tempDict[doc._id as string].comment =
                                      event.target.value;
                                    setStatusDict(tempDict);
                                  }}
                                  error={error[doc._id as string]?true:false}
                                  helperText={error[doc._id as string]?error[doc._id as string]:''}
                                  value={statusDict[doc._id as string]?.comment}
                                />
                              </FormControl>
                              {/* {error[doc._id as string] && (
                                <Typography variant='caption' color='error'>
                                  {error[doc._id as string]}
                                </Typography>
                              )} */}
                            </>
                          ) : (
                            doc.comment || 'N/A'
                          )}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
              {/* </TableContainer> */}
            </>
          )}
        </Grid>
        {category != null && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <fieldset className='custom-fields'>
              <legend>{USER_CATEGORY[catDoc.type]}:</legend>
              <Grid container spacing={1}>
                {catDoc.type === 'citizen' && (
                  <>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography className='custom-title'>
                        Social Security &nbsp;
                      </Typography>
                      <Typography>
                        {catDoc?.citizen?.socialSecurity || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography className='custom-title'>
                        Document &nbsp;
                      </Typography>
                      {catDoc?.citizen?.doc ? (
                        <Link
                          href={catDoc.citizen.docUrl}
                          target='_blank'
                          rel='noreferrer'>
                          View Document
                        </Link>
                      ) : (
                        <Typography>N/A</Typography>
                      )}
                    </Grid>
                  </>
                )}

                {catDoc.type === 'permanent-resident' && (
                  <>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography className='custom-title'>
                        Country of Citizenship :&nbsp;
                      </Typography>
                      <Typography>
                        {catDoc?.pr?.countryOfCitizenship || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography className='custom-title'>
                        Issue Date :&nbsp;
                      </Typography>

                      <Typography>
                        {catDoc?.pr?.issueDate
                          ? moment(catDoc?.pr?.issueDate).format(DATE_FORMAT_1)
                          : 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography className='custom-title'>
                        Expiry Date :&nbsp;
                      </Typography>

                      <Typography>
                        {catDoc?.pr?.expiryDate
                          ? moment(catDoc?.pr?.expiryDate).format(DATE_FORMAT_1)
                          : 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography className='custom-title'>
                        Document &nbsp;
                      </Typography>
                      {catDoc?.pr?.doc ? (
                        <Link
                          href={catDoc.pr.docUrl}
                          target='_blank'
                          rel='noreferrer'>
                          View Document
                        </Link>
                      ) : (
                        <Typography>N/A</Typography>
                      )}
                    </Grid>
                  </>
                )}

                {catDoc.type === 'EAD' && (
                  <>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography className='custom-title'>
                        Country of Citizenship :&nbsp;
                      </Typography>
                      <Typography>
                        {catDoc?.EAD?.countryOfCitizenship || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography className='custom-title'>
                        EAD issue Date :&nbsp;
                      </Typography>
                      <Typography>
                        {catDoc?.EAD?.EADCard?.issueDate
                          ? moment(catDoc?.H1B?.EADCard?.issueDate).format(
                              DATE_FORMAT_1
                            )
                          : 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography className='custom-title'>
                        EAD Expiry Date :&nbsp;
                      </Typography>
                      <Typography>
                        {catDoc?.EAD?.EADCard?.expiryDate
                          ? moment(catDoc?.H1B?.EADCard?.expiryDate).format(
                              DATE_FORMAT_1
                            )
                          : 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography className='custom-title'>
                        EAD Document :&nbsp;
                      </Typography>
                      {catDoc?.EAD?.EADCard?.doc ? (
                        <Link
                          href={catDoc.EAD.EADCard.docUrl}
                          target='_blank'
                          rel='noreferrer'>
                          View Document
                        </Link>
                      ) : (
                        <Typography>N/A</Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography className='custom-title'>
                        Passport issuing country :&nbsp;
                      </Typography>
                      <Typography>
                        {catDoc?.EAD?.Passport?.issuingCountry || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography className='custom-title'>
                        Passport Issue Date :&nbsp;
                      </Typography>
                      <Typography>
                        {catDoc?.EAD?.passport?.issueDate
                          ? moment(catDoc?.H1B?.passport?.issueDate).format(
                              DATE_FORMAT_1
                            )
                          : 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography className='custom-title'>
                        Passport Expiry Date :&nbsp;
                      </Typography>
                      <Typography>
                        {catDoc?.EAD?.passport?.expiryDate
                          ? moment(catDoc?.H1B?.passport?.expiryDate).format(
                              DATE_FORMAT_1
                            )
                          : 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography className='custom-title'>
                        Passport Document :&nbsp;
                      </Typography>
                      {catDoc?.EAD?.passport?.doc ? (
                        <Link
                          href={catDoc.EAD.passport.docUrl}
                          target='_blank'
                          rel='noreferrer'>
                          View Document
                        </Link>
                      ) : (
                        <Typography>N/A</Typography>
                      )}
                    </Grid>
                  </>
                )}
                {catDoc.type === 'H1B' && (
                  <>
                    <>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Typography className='custom-title'>
                          Passport Issuing country :&nbsp;
                        </Typography>
                        <Typography>
                          {catDoc?.H1B?.passport?.issuingCountry || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Typography className='custom-title'>
                          Passport Issue Date :&nbsp;
                        </Typography>
                        <Typography>
                          {catDoc?.H1B?.passport?.issueDate
                            ? moment(catDoc?.H1B?.passport?.issueDate).format(
                                DATE_FORMAT_1
                              )
                            : 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Typography className='custom-title'>
                          Passport Expiry Date :&nbsp;
                        </Typography>
                        <Typography>
                          {catDoc?.H1B?.passport?.expiryDate
                            ? moment(catDoc?.H1B?.passport?.expiryDate).format(
                                DATE_FORMAT_1
                              )
                            : 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Typography className='custom-title'>
                          Passport Document :&nbsp;
                        </Typography>
                        {catDoc?.H1B?.passport?.doc ? (
                          <Link
                            href={catDoc.H1B.passport.docUrl}
                            target='_blank'
                            rel='noreferrer'>
                            View Document
                          </Link>
                        ) : (
                          <Typography className='custom-title'>N/A</Typography>
                        )}
                      </Grid>
                    </>
                    {catDoc?.H1B?.isH4Dependent === true && (
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Typography className='custom-title'>
                          No of H4 Dependents *&nbsp;
                        </Typography>
                        <Typography>
                          {catDoc?.H1B?.noOfH4Dependents || 'N/A'}
                        </Typography>
                      </Grid>
                    )}
                  </>
                )}

                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={1} justify='flex-end'> */}
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography className='custom-title'>
                    Status :&nbsp;
                  </Typography>
                  {catDoc[
                    catDoc.type === 'permanent-resident' ? 'pr' : catDoc.type
                  ].status === 'submitted' && userData.role === 1 ? (
                    <FormControl size='small' required>
                      <Select
                        renderValue={() => (
                          <div className='capitalize'>
                            {TEMP[catDocStatus.status] || <em>Select</em>}
                          </div>
                        )}
                        value={catDocStatus.status}
                        onChange={(event: any) => {
                          // console.log('catStatus', event.target.value);
                          setCatDocStatus({
                            ...catDocStatus,
                            status: event.target.value,
                          });
                        }}>
                        <MenuItem value='rejected'>Reject</MenuItem>
                        <MenuItem value='approved'>Approve</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <div
                      className={`capitalize ${
                        colorDict[
                          catDoc[
                            catDoc.type === 'permanent-resident'
                              ? 'pr'
                              : catDoc.type
                          ].status as string
                        ] || ''
                      }`}>
                      {
                        DOC_STATUS[
                          catDoc[
                            catDoc.type === 'permanent-resident'
                              ? 'pr'
                              : catDoc.type
                          ].status as string
                        ]
                      }
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography className='custom-title'>
                    Any Reason :&nbsp;
                  </Typography>
                  {catDoc[
                    catDoc.type === 'permanent-resident' ? 'pr' : catDoc.type
                  ].status === 'submitted' && userData.role === 1 ? (
                    <>
                      <FormControl fullWidth>
                        <TextField
                          size='small'
                          name='comment'
                          label='Reason'
                          // disabled={statusDict[doc._id as string] == null}
                          onChange={(event: any) => {
                            setCatDocStatus({
                              ...catDocStatus,
                              comment: event.target.value,
                            });
                            // setCatDoc({
                            //   ...catDoc,
                            //   comment: event.target.value,
                            // });
                          }}
                          value={catDocStatus.comment}
                        />
                      </FormControl>
                      {error[catDoc._id as string] && (
                        <Typography variant='caption' color='error'>
                          {error[catDoc._id as string]}
                        </Typography>
                      )}
                    </>
                  ) : (
                    catDoc.comment || 'N/A'
                  )}
                </Grid>
                {/* </Grid>
              </Grid> */}
              </Grid>
            </fieldset>
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <br />
          <br />
          <Grid container justify='flex-end' spacing={1}>
            <Grid item>
              <Button
                variant='outlined'
                color='primary'
                className='cancel-btn'
                onClick={() => onClose()}>
                Cancel
              </Button>
            </Grid>
            {/* <Grid item>
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => setDocRegDrawer({ isOpen: true })}>
                Request for documents
              </Button>
            </Grid> */}
            <Grid item>
              <Button
                className='add-btn'
                variant='contained'
                color='primary'
                disabled={
                  userData.role === 1
                    ? docs.find((d) => d.status !== 'approved') == null &&
                      catDoc[
                        catDoc.type === 'permanent-resident'
                          ? 'pr'
                          : catDoc.type
                      ]?.status === 'approved'
                    : true
                }
                // disabled={disableSubmitBTN}
                onClick={updateStatus}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {decRegDrawer.isOpen && (
        <CustomDrawer
          title={`Request New Documents`}
          onClose={() => {
            setDocRegDrawer({ isOpen: false });
          }}>
          <RequestUserDoc
            onClose={(data?: IUserDoc[]) => {
              if (data && data.length > 0) {
                const temp = [...docs, ...data];
                setDocs(temp);
              }
              setDocRegDrawer({ isOpen: false });
            }}
            userId={userId}
          />
        </CustomDrawer>
      )}
    </div>
  );
};

export default UserDocsMain;
