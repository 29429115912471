import * as React from "react";
import {
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ClassProps } from "./vm";
import {
  AuthenticatedRoute,
  SuperAdminAuthenticatedRoute,
} from "./components/common/AuthenticatedRoute";
import Page404 from "./pages/common/Page404";
import LoginPage from "./pages/auth/LoginPage";
import DashboardPage from "./pages/dash/DashboardPage";
import CompanyPage from "./pages/companies/CompanyPage";
import CompanyTab from "./components/companies/CompanyTab";
import SuperAdminMain from "./components/superAdmins/SuperAdminMain";
import UpdateOrganizationalDetails from "./components/configs/UpdateOrganizationalDetails";
import ClientTab from "./components/partners/clients/ClientTab";
import { isTokenExpired } from "./services/UtilService";

export interface RoutesMainProps extends RouteComponentProps, ClassProps {}

const RoutesMain: React.SFC<RoutesMainProps> = ({ classes }) => {
  let hasTokenExpired = isTokenExpired();
  return (
    <main
      className={`${classes.content} ${hasTokenExpired ? "not-auth-bg" : ""}`}
    >
      {/* <div className={classes.appBarSpacer} /> */}
      <Switch>
        <Route path="/" exact component={LoginPage} />

        <AuthenticatedRoute path="/dashboard" exact component={DashboardPage} />

        <SuperAdminAuthenticatedRoute
          path="/companies"
          exact
          component={CompanyPage}
        />
        <SuperAdminAuthenticatedRoute
          path="/super-admins"
          exact
          component={SuperAdminMain}
        />
        <SuperAdminAuthenticatedRoute
          path="/configs"
          exact
          component={UpdateOrganizationalDetails}
        />

        <AuthenticatedRoute
          path="/companies/:companyId/:tabName"
          exact
          component={CompanyTab}
        />

        <AuthenticatedRoute
          path="/companies/:companyId/clients/:clientId/:tabName"
          exact
          component={ClientTab}
        />

        <Route path="" exact component={Page404} />
      </Switch>
    </main>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    appBarSpacer: { height: 16 },
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
      padding: 40,
      [theme.breakpoints.down("sm")]: {
        padding: 8,
      },
      backgroundColor: "#fbfbfb",
    },
  });
export default withStyles(styles)(withRouter(RoutesMain));
