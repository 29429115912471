import * as React from "react";
import { Formik } from "formik";
import { ICompanyAdmin, ICompany } from "../../vm";
import {
  FormControl,
  Button,
  TextField,
  Grid,
  Typography,
  debounce,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import {
  addCompanyAndAdmin,
  updateACompany,
} from "../../services/CompanyService";
import { ToastContext } from "../common/ToastProvider";
import Loading from "../common/Loading";
import {
  sanitizeUrl,
  getSignedUrls,
  uploadFile,
  deleteFiles,
} from "../../services/UtilService";

import { US_ZIP_CODE_REGEX, COUNTRY_CODES, PHONE_REGEX } from "../../Constant";
import ReactSelect from "react-select";
import CropDialog from "../common/CropDialog";

interface onCloseFunc {
  (data?: ICompany): void;
}

interface IAddCompanyAndAdminProps {
  onClose: onCloseFunc;
}

const AddCompanyAndAdmin: React.FC<IAddCompanyAndAdminProps> = ({
  onClose,
}) => {
  const { showToast } = React.useContext(ToastContext);
  const [isLoading, setLoading] = React.useState(false);
  const [cropDialog, setCropDialog] = React.useState({
    isOpen: false,
    file: undefined,
  });

  const country_codes_values = JSON.parse(COUNTRY_CODES);

  return (
    <div className="p-16">
      {isLoading && <Loading message="processing..." />}
      <Formik
        initialValues={{
          type: "",
          name: "",
          url: "",
          customerUrl: "",
          timesheetUrl: "",
          address: { street: "", pin: "", city: "", country: "", state: "" },
          countryCode: "+1",
          phone: "",
          email: "",
          logoImage: "",
          logoImageURL: "",
          selectedLogo: undefined,
          admin: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
          },
        }}
        validate={(values) => {
          const errors: ICompanyAdmin = {};
          if (!values.name) errors.name = "Required";
          if (!values.url) errors.url = "Required";
          if (!values.customerUrl) errors.customerUrl = "Required";
          if (!values.timesheetUrl) errors.timesheetUrl = "Required";
          if (!values.email) errors.email = "Required";
          if (values.phone && PHONE_REGEX.test(values.phone) === false) {
            errors.phone = "Please enter phone number of format 9876543210";
          }
          if (values.phone != null) {
            if (!values.countryCode) errors.countryCode = "Required";
          }
          // if (!values.logoImageURL) {
          //   if (!values.selectedLogo) errors.selectedLogo = 'Required';
          // }
          // if (!values.address) errors.address = {};
          if (!values.address.street) {
            if (!errors.address) errors.address = {};
            errors.address.street = "Required";
          }
          if (!values.address.pin) {
            if (!errors.address) errors.address = {};
            errors.address.pin = "Required";
          }
          if (
            values.address.pin &&
            US_ZIP_CODE_REGEX.test(values.address.pin) === false
          ) {
            if (!errors.address) errors.address = {};
            errors.address.pin = "Invalid zip code";
          }
          if (!values.address.city) {
            if (!errors.address) errors.address = {};
            errors.address.city = "Required";
          }
          if (!values.address.state) {
            if (!errors.address) errors.address = {};
            errors.address.state = "Required";
          }
          if (!values.address.country) {
            if (!errors.address) errors.address = {};
            errors.address.country = "Required";
          }

          // if (!values.admin) errors.admin = {};
          if (!values.admin.firstName) {
            if (!errors.admin) errors.admin = {};
            errors.admin.firstName = "Required";
          }
          if (!values.admin.lastName) {
            if (!errors.admin) errors.admin = {};
            errors.admin.lastName = "Required";
          }
          if (!values.admin.email) {
            if (!errors.admin) errors.admin = {};
            errors.admin.email = "Required";
          }
          if (!values.admin.password) {
            if (!errors.admin) errors.admin = {};
            errors.admin.password = "Required";
          }

          return errors;
        }}
        onSubmit={async (values: ICompanyAdmin, { setSubmitting }) => {
          setLoading(true);
          console.log("submitting");
          const obj = { ...values };
          obj.url = sanitizeUrl(values.url as string);
          obj.customerUrl = sanitizeUrl(values.customerUrl as string);
          obj.timesheetUrl = sanitizeUrl(values.timesheetUrl as string);
          if (!obj.countryCode) {
            delete obj.countryCode;
          }

          let result = await addCompanyAndAdmin(obj);

          if (result?.success) {
            if (obj.selectedLogo != null) {
              const fileName = `${
                result.data._id
              }/logos/${new Date().getTime()}-${obj.selectedLogo.name
                .toLowerCase()
                .replace(/ /g, "")}`;
              const result2 = await getSignedUrls({ filePaths: [fileName] });
              if (result2?.success) {
                await uploadFile(fileName, result2.data[0], obj.selectedLogo);
              } else {
                showToast("Error while getting Signed URL", "error");
                setLoading(false);
                setSubmitting(false);
                return;
              }
              // todo delete old logo
              if (obj.logoImage != null) {
                await deleteFiles({ filePaths: [obj.logoImage] });
              }
              obj.logoImage = fileName;
            } else {
              obj.logoImage = "";
            }

            let updateRes = await updateACompany(
              result.data?._id as string,
              obj
            );

            if (updateRes?.success) {
              onClose(updateRes.data);
            } else {
              showToast(
                updateRes?.message
                  ? updateRes.message
                  : "Error while updating company",
                "error"
              );
            }

            
            // setCompanies(result.data);
            showToast("Added successfully", "success");
          } else {
            showToast(
              result?.message ? result.message : "Error while adding company",
              "error"
            );
          }
          setSubmitting(false);
          setLoading(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    id="name"
                    name="name"
                    label="Company Name"
                    autoComplete="name"
                    autoFocus
                    onChange={handleChange}
                    error={errors.name && touched.name ? true : false}
                    helperText={errors.name && touched.name && errors.name}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="type"
                    label="Type"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.type && touched.type ? true : false}
                    helperText={errors.type && touched.type && errors.type}
                    value={values.type}
                  />
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="email"
                    type="email"
                    label="Email"
                    autoComplete="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email ? true : false}
                    helperText={errors.email && touched.email && errors.email}
                    value={values.email}
                  />
                </FormControl>
              </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    className="custom-react-select-form mt-16i"
                  >
                    <InputLabel className="custom-react-select-label">
                      Country code
                    </InputLabel>
                    <ReactSelect
                      value={{
                        value: values.countryCode,
                        label: values.countryCode,
                      }}
                      className="async-select react-select"
                      placeholder="Country code"
                      isClearable={false}
                      options={country_codes_values.map((x: any) => {
                        return {
                          label: x,
                          value: x,
                        };
                      })}
                      onChange={(newValue: any) => {
                        setFieldValue("countryCode", newValue?.value);
                      }}
                    />
                    {errors.countryCode && (
                      <Typography variant="caption" color="error">
                        &nbsp; &nbsp;{errors.countryCode}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        size="small"
                        name="phone"
                        label="Phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.phone && touched.phone ? true : false}
                        helperText={
                          errors.phone && touched.phone && errors.phone
                        }
                        value={values.phone}
                      />
                    </FormControl>
                  </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="url"
                    label="Company URL"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.url && touched.url ? true : false}
                    helperText={errors.url && touched.url && errors.url}
                    value={values.url}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="customerUrl"
                    label="User URL"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.customerUrl && touched.customerUrl ? true : false
                    }
                    helperText={
                      errors.customerUrl &&
                      touched.customerUrl &&
                      errors.customerUrl
                    }
                    value={values.customerUrl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="timesheetUrl"
                    label="Timesheet URL"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.timesheetUrl && touched.timesheetUrl ? true : false
                    }
                    helperText={
                      errors.timesheetUrl &&
                      touched.timesheetUrl &&
                      errors.timesheetUrl
                    }
                    value={values.timesheetUrl}
                  />
                </FormControl>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                {!values.logoImageURL ? (
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Logo"
                      type="file"
                      name="selectedLogo"
                      onChange={(event: any) =>
                        {
                          if (
                            event.target.files &&
                            event.target.files.length > 0
                          ) {
                            setCropDialog({
                              isOpen: true,
                              file: event.target.files,
                            });
                          }
                          setFieldValue("selectedLogo", event.target.files[0])
                        }
                      }
                      InputProps={{
                        inputProps: {
                          accept: "image/*",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={
                        errors.selectedLogo &&
                        touched.selectedLogo &&
                        errors.selectedLogo
                          ? true
                          : false
                      }
                      // helperText={errors.selectedLogo}
                    />
                    <FormHelperText>
                        Preferred image ratio 128px X 128px
                      </FormHelperText>
                  </FormControl>
                ) : (
                  <React.Fragment>
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container spacing={2} justify="space-between">
                          <Grid item>
                            <Typography variant="h6">Logo</Typography>
                          </Grid>
                          {/* <Grid item>
                            <Button
                              color='secondary'
                              size='small'
                              variant='outlined'
                              onClick={() => {
                                setFieldValue('logoImageURL', '');
                              }}>
                              Remove
                            </Button>
                          </Grid> */}
                        </Grid>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="logo-image-view">
                          <img
                            alt="logo"
                            className="w-100"
                            src={values.logoImageURL}
                          />
                          <span
                            onClick={() => {
                              setFieldValue("logoImageURL", "");
                              setFieldValue("selectedLogo", null);
                            }}
                          >
                            <svg
                              role="img"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="times-circle"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                              ></path>
                            </svg>
                          </span>
                        </div>
                        {/* <img
                          src={values.logoImageURL}
                          alt='log'
                          className='logo-image'
                        /> */}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>

              {/* <br />
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <fieldset>
                  <legend>Company Address:</legend>
                  <Grid container spacing={1}> */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="address.street"
                    label="Street"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors?.address?.street && touched?.address?.street
                        ? true
                        : false
                    }
                    helperText={
                      errors?.address?.street &&
                      touched?.address?.street &&
                      errors.address.street
                    }
                    value={values.address.street}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="address.pin"
                    label="Zip Code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors?.address?.pin && touched?.address?.pin
                        ? true
                        : false
                    }
                    helperText={
                      errors?.address?.pin &&
                      touched?.address?.pin &&
                      errors.address.pin
                    }
                    value={values.address.pin}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="address.city"
                    label="City"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors?.address?.city && touched?.address?.city
                        ? true
                        : false
                    }
                    helperText={
                      errors?.address?.city &&
                      touched?.address?.city &&
                      errors.address.city
                    }
                    value={values.address.city}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="address.state"
                    label="State"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors?.address?.state && touched?.address?.state
                        ? true
                        : false
                    }
                    helperText={
                      errors?.address?.state &&
                      touched?.address?.state &&
                      errors.address.state
                    }
                    value={values.address.state}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="address.country"
                    label="Country"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors?.address?.country && touched?.address?.country
                        ? true
                        : false
                    }
                    helperText={
                      errors?.address?.country &&
                      touched?.address?.country &&
                      errors.address.country
                    }
                    value={values.address.country}
                  />
                </FormControl>
              </Grid>

              {/* </Grid>
                </fieldset>
              </Grid>
              <br /> */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <fieldset className="custom-fields">
                  <legend>Admin Details:</legend>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <FormControl margin="normal" required fullWidth>
                        <TextField
                          size="small"
                          name="admin.firstName"
                          label="First Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            errors?.admin?.firstName &&
                            touched?.admin?.firstName
                              ? true
                              : false
                          }
                          helperText={
                            errors?.admin?.firstName &&
                            touched?.admin?.firstName &&
                            errors.admin.firstName
                          }
                          value={values.admin.firstName}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <FormControl margin="normal" required fullWidth>
                        <TextField
                          size="small"
                          name="admin.lastName"
                          label="Last Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            errors?.admin?.lastName && touched?.admin?.lastName
                              ? true
                              : false
                          }
                          helperText={
                            errors?.admin?.lastName &&
                            touched?.admin?.lastName &&
                            errors.admin.lastName
                          }
                          value={values.admin.lastName}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControl margin="normal" required fullWidth>
                        <TextField
                          size="small"
                          name="admin.email"
                          type="email"
                          label="Email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            errors?.admin?.email && touched?.admin?.email
                              ? true
                              : false
                          }
                          helperText={
                            errors?.admin?.email &&
                            touched?.admin?.email &&
                            errors.admin.email
                          }
                          value={values.admin.email}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControl margin="normal" required fullWidth>
                        <TextField
                          size="small"
                          name="admin.password"
                          label="Password"
                          type="password"
                          autoComplete="current-password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            errors?.admin?.password && touched?.admin?.password
                              ? true
                              : false
                          }
                          helperText={
                            errors?.admin?.password &&
                            touched?.admin?.password &&
                            errors.admin.password
                          }
                          value={values.admin.password}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </fieldset>
              </Grid>
            </Grid>
            <br />
            <br />
            <br />

            <Grid container justify="flex-end" alignItems="center" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  className="cancel-btn"
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  className="btn"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
            <br />
            {cropDialog.isOpen && (
              <CropDialog
                showToast={showToast}
                handleClose={(file: any) => {
                  console.log("file cropped", file);
                  if (file) {
                    setFieldValue("selectedLogo", file);
                  } else {
                    let logoFile: any = document.getElementById("logoImage");
                    if (logoFile) {
                      logoFile.value = "";
                    }
                    setFieldValue("selectedLogo", undefined);
                  }
                  setCropDialog({
                    isOpen: false,
                    file: undefined,
                  });
                }}
                aspectRatio={1 / 1}
                file={cropDialog.file}
              />
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddCompanyAndAdmin;
