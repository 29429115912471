import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { Formik } from "formik";
// import { IUser } from '../../vm';
import {
  withStyles,
  Theme,
  createStyles,
  Typography,
  FormControl,
  Button,
  TextField,
  Grid,
} from "@material-ui/core";
import { ClassProps } from "../../vm";
import {
  updateOrganizationalDetails,
  getOrganizationalDetails,
} from "../../services/account/AdminService";
import { ToastContext } from "../common/ToastProvider";
import Loading from "../common/Loading";
// import { useHistory } from 'react-router-dom';
import { sanitizeUrl } from "../../services/UtilService";

interface UpdateOrganizationalDetailsProps extends ClassProps {}

const UpdateOrganizationalDetails: React.FC<UpdateOrganizationalDetailsProps> = ({
  classes,
}) => {
  //   console.log('rendering');
  // const history = useHistory();
  const { showToast } = React.useContext(ToastContext);
  const [details, setDetails] = React.useState({
    _id: undefined,
    url: "",
  } as any);

  const [isLoading, setLoading] = React.useState(false);

  React.useLayoutEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    setLoading(true);
    const result = await getOrganizationalDetails();
    if (result?.success) {
      setDetails({ ...details, ...result.data });
    } else {
      showToast(
        result?.message || "Error while getting organizational details",
        "error"
      );
    }
    setLoading(false);
  };

  return (
    <div className="ph-16">
      {/* <main className={classes.main}> */}
      {isLoading && <Loading message="processing..." />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography className="title" variant="h5">
            Organization Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper className={classes.paper}>
            <div className="w-100">
              <Formik
                enableReinitialize={true}
                initialValues={details}
                validate={(values: any) => {
                  const errors: any = {};
                  if (!values.url) errors.url = "Required";

                  return errors;
                }}
                onSubmit={async (
                  values: any,
                  { setSubmitting }: { setSubmitting: Function }
                ) => {
                  setLoading(true);
                  console.log("user", values);
                  const obj = { ...values };
                  obj.url = sanitizeUrl(values.url as string);

                  const result = await updateOrganizationalDetails(obj);

                  if (result?.success) {
                    showToast(`Updated successfully`, "success");
                  } else {
                    showToast(
                      result?.message
                        ? result.message
                        : "Error while updating details",
                      "error"
                    );
                  }
                  setSubmitting(false);
                  setLoading(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  // setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormControl margin="normal" required fullWidth>
                          <TextField
                            size="small"
                            name="url"
                            type="url"
                            label="URL"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.url && touched.url ? true : false}
                            helperText={errors.url && touched.url && errors.url}
                            value={values.url}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <br />

                    <Grid container justify="flex-end" spacing={2}>
                      {/* <Grid item>
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={() => {}}>
                    Cancel
                  </Button>
                </Grid> */}
                      <Grid item>
                        <Button
                          type="submit"
                          variant="contained"
                          className="add-btn"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </div>
          </Paper>
        </Grid>
      </Grid>
      {/* </main> */}
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    main: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      maxWidth: "600px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${
        theme.spacing() * 3
      }px`,
    },
    logo: {
      height: "86px",
    },
    avatar: {
      margin: theme.spacing(),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      //   width: "100%",
      marginTop: theme.spacing(),
    },
    submit: {
      marginTop: theme.spacing(3),
    },
  });

export default withStyles(styles)(UpdateOrganizationalDetails);
