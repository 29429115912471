import * as React from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
// import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Loading from "../common/Loading";
import Empty from "../common/Empty";
import { ToastContext } from "../common/ToastProvider";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import {
  IPartner,
  IPartnerSearch,
  IDrawerOpen,
  IConfirmDialog,
} from "../../vm";
import { searchPartners, removeAPartner } from "../../services/PartnerService";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Fab from "@material-ui/core/Fab";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { TableHederCell } from "../common/Library";
import {
  Pencil,
  TrashCanOutline,
  ChevronRight,
  ChevronLeft,
  AccountGroup,
  FileDocument,
  Magnify,
  Account,
} from "mdi-material-ui";
import { PARTNER_TYPE, DOC_STATUS_DROPDOWN } from "../../Constant";

import CustomDrawer from "../common/CustomDrawer";

import AddOrUpdatePartner from "./AddOrUpdatePartner";
import PartnerUserMain from "./users/PartnerUserMain";
import UserDocsMain from "../users/UserDocsMain";
import Menu from "@material-ui/core/Menu";
import { DotsVertical } from "mdi-material-ui";
import { InputAdornment, useMediaQuery, useTheme } from "@material-ui/core";

interface IPartnerMainProps extends IConfirmDialog {}

const PartnerMain: React.FC<IPartnerMainProps> = ({ confirmDialog }) => {
  const query = new URLSearchParams(useLocation().search);
  const { showToast } = React.useContext(ToastContext);
  const { companyId } = useParams();
  const [isLoading, setLoading] = React.useState(false);
  const [partners, setPartners] = React.useState([] as IPartner[]);
  const [partnerDrawer, setPartnerDrawer] = React.useState({
    isOpen: false,
  } as IDrawerOpen);
  const [userDrawer, setUserDrawer] = React.useState({
    isOpen: false,
  } as IDrawerOpen);
  const [docDrawer, setDocDrawer] = React.useState({
    isOpen: false,
  } as IDrawerOpen);
  const theme = useTheme();
  const isGraterThanMD = useMediaQuery(theme.breakpoints.up("sm"));

  const [isFilterOpen, setFilterOpen] = React.useState(false);

  const [partnerSearch, setPartnerSearch] = React.useState({
    companyId: "",
    limit: 10,
    offset: 0,
    type: "all",
    docStatus: "all",
  } as IPartnerSearch);

  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );

  const [selectedTimeSheetIndex, setSelectedTimeSheetIndex] = React.useState<
    number
  >(-1);

  React.useEffect(() => {
    const type: any = query.get("type");
    getPartners(undefined, { type });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPartners = async (offset?: number, querySearch?: { type?: any }) => {
    setLoading(true);
    const temp = { ...partnerSearch, companyId: companyId };
    if (offset != null) {
      temp.offset = offset;
    }
    if (querySearch != null) {
      if (querySearch.type != null) temp.type = querySearch.type;
    }

    setPartnerSearch({ ...temp });

    if (temp.type === "all") delete temp.type;
    if (!temp.name) delete temp.name;
    if (temp.docStatus === "all" || temp.type === "client")
      delete temp.docStatus;

    const result = await searchPartners(temp);

    if (result?.success) {
      setPartners(result.data);
    } else {
      showToast(
        result?.message ? result.message : "Error while getting partners list",
        "error"
      );
    }
    setLoading(false);
  };

  const removePartner = async (index: number) => {
    confirmDialog.show("Are you sure", "Do you want to delete?", async () => {
      setLoading(true);
      const usersList = [...partners];
      const result = await removeAPartner(usersList[index]._id as string);
      if (result?.success) {
        usersList.splice(index, 1);
        setPartners(usersList);
        showToast("Partner removed successfully", "success");
      } else {
        showToast(
          result?.message ? result.message : "Error while removing partner",
          "error"
        );
      }
      setLoading(false);
    });
  };

  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setSelectedTimeSheetIndex(index);
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSelectedTimeSheetIndex(-1);
    setAnchorElMenu(null);
  };

  const FilterActions = () => {
    return (
      <Grid container spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className="search-btn fs-14 fw-medium"
            onClick={() => getPartners()}
          >
            Search
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            className="reset-btn fs-14 fw-medium"
            onClick={() => {
              setPartnerSearch({
                ...partnerSearch,
                name: "",
                type: "all",
                docStatus: "all",
              });
            }}
          >
            Reset
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            className="close-btn fs-14 fw-medium"
            onClick={() => {
              setFilterOpen(false);
            }}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      {isLoading && <Loading message="Loading..." />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="flex-end" alignItems="center">
            {/* <Grid item>
              <Typography variant='h5'>Users</Typography>
            </Grid> */}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className="add-btn"
                onClick={() => {
                  setPartnerDrawer({ isOpen: true });
                }}
              >
                Add Partner
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          {isFilterOpen ? (
            <React.Fragment>
              <Paper className="p-16 card">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item className="flex-grow">
                        <FormControl fullWidth>
                          <TextField
                            size="small"
                            name="name"
                            autoFocus
                            placeholder="Search by Name"
                            onChange={(event: any) => {
                              setPartnerSearch({
                                ...partnerSearch,
                                name: event.target.value,
                              });
                            }}
                            variant="standard"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Magnify />
                                </InputAdornment>
                              ),
                            }}
                            value={partnerSearch.name}
                          />
                        </FormControl>
                      </Grid>
                      {isGraterThanMD && (
                        <Grid>
                          <FilterActions />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <FormControl size="small" fullWidth>
                      <InputLabel className="select-label" htmlFor="type">
                        Type
                      </InputLabel>
                      <Select
                        value={partnerSearch.type}
                        name="type"
                        onChange={(event: any) => {
                          setPartnerSearch({
                            ...partnerSearch,
                            type: event.target.value,
                          });
                        }}
                        inputProps={{
                          id: "type",
                        }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        {Object.keys(PARTNER_TYPE).map((index: string) => (
                          <MenuItem value={index} key={index}>
                            {PARTNER_TYPE[index]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {partnerSearch.type !== "client" && (
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <FormControl size="small" fullWidth>
                        <InputLabel className="select-label" htmlFor="type">
                          Document Status
                        </InputLabel>
                        <Select
                          onChange={(event: any) => {
                            setPartnerSearch({
                              ...partnerSearch,
                              docStatus: event.target.value,
                            });
                          }}
                          value={partnerSearch.docStatus}
                          name="docStatus"
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          {Object.keys(DOC_STATUS_DROPDOWN).map(
                            (index: string) => (
                              <MenuItem value={index} key={index}>
                                {DOC_STATUS_DROPDOWN[index]}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {!isGraterThanMD && (
                    <Grid item xs={12} sm={6} md={3} lg={2}>
                      <FilterActions />
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Paper
                onClick={() => setFilterOpen(true)}
                className="p-16 card pointer"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        name="name"
                        placeholder="Search by Name"
                        variant="standard"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Magnify />
                            </InputAdornment>
                          ),
                        }}
                        value={partnerSearch.name}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </React.Fragment>
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          {partners.length === 0 ? (
            <Empty message="No Partners Yet!!" />
          ) : (
            <TableContainer component={Paper} className="timesheet-table">
              <Table aria-label="Company table">
                <TableHead>
                  <TableRow>
                    <TableHederCell>Company Name</TableHederCell>
                    <TableHederCell>Email</TableHederCell>
                    <TableHederCell>Type</TableHederCell>
                    <TableHederCell>Document Status</TableHederCell>
                    <TableHederCell>Enabled</TableHederCell>

                    <TableHederCell align="right">Actions</TableHederCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {partners.map((partner, index: number) => (
                    <TableRow key={partner._id}>
                      <TableCell component="th" scope="row">
                        {partner.name}
                      </TableCell>
                      <TableCell>{partner.email}</TableCell>
                      <TableCell>{PARTNER_TYPE[partner.type]}</TableCell>
                      <TableCell>
                        {partner.type === "client"
                          ? "NA"
                          : partner.docStatus
                          ? DOC_STATUS_DROPDOWN[partner.docStatus]
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {partner.isEnabled?'Yes':'No'}
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(e) => handleClickMenu(e, index)}
                        >
                          <DotsVertical />
                        </Button>
                        {/*
                        <Grid container justify='flex-end'>
                          {partner.type === 'service-provider' && (
                            <Grid item>
                              <Tooltip title='Documents'>
                                <IconButton
                                  onClick={() =>
                                    setDocDrawer({
                                      isOpen: true,
                                      index: index,
                                    })
                                  }>
                                  <FileDocument />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                          <Grid item>
                            <Tooltip title='View Users'>
                              <IconButton
                                // color='secondary'
                                onClick={() => {
                                  setUserDrawer({
                                    isOpen: true,
                                    index: index,
                                  });
                                }}>
                                <AccountGroup />
                              </IconButton>
                            </Tooltip>
                          </Grid>

                          <Grid item>
                            <Tooltip title='Update Partner'>
                              <IconButton
                                color='secondary'
                                onClick={() =>
                                  setPartnerDrawer({
                                    isOpen: true,
                                    index: index,
                                  })
                                }>
                                <Pencil />
                              </IconButton>
                            </Tooltip>
                          </Grid>

                          <Grid item>
                            <Tooltip title='Remove Partner'>
                              <IconButton
                                color='primary'
                                onClick={() => removePartner(index)}>
                                <TrashCanOutline />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                         */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="flex-end" spacing={2}>
            {partnerSearch.offset > 0 && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() => {
                    getPartners(partnerSearch.offset - partnerSearch.limit);
                  }}
                >
                  <ChevronLeft />
                </Fab>
              </Grid>
            )}
            {partners.length >= partnerSearch.limit && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() => {
                    getPartners(partnerSearch.offset + partnerSearch.limit);
                  }}
                >
                  <ChevronRight />
                </Fab>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {partnerDrawer.isOpen && (
        <CustomDrawer
          title={`${partnerDrawer.index != null ? "Update" : "Add"} Partner`}
          onClose={() => {
            setPartnerDrawer({ isOpen: false });
          }}
        >
          <AddOrUpdatePartner
            companyId={companyId}
            partner={partners[partnerDrawer.index as number]}
            onClose={(data?: IPartner, isAdd?: boolean) => {
              if (data) {
                if (isAdd === true) {
                  setPartners([data, ...partners]);
                } else {
                  const temp = partners;
                  temp[partnerDrawer.index as number] = data;
                  setPartners(temp);
                }
              }
              setPartnerDrawer({ isOpen: false });
            }}
          />
        </CustomDrawer>
      )}
      {userDrawer.isOpen && (
        <CustomDrawer
          title={`List of contacts belonging to ${
            partners[userDrawer.index as number].name
          }`}
          variant="large"
          onClose={() => {
            setUserDrawer({ isOpen: false });
          }}
        >
          <PartnerUserMain
            partnerId={partners[userDrawer.index as number]._id}
            // onClose={() => {
            //   setUserDrawer({ isOpen: false });
            // }}
          />
        </CustomDrawer>
      )}
      {docDrawer.isOpen && (
        <CustomDrawer
          title={`User Documents`}
          variant="large"
          onClose={() => {
            setDocDrawer({ isOpen: false });
          }}
        >
          <UserDocsMain
            onClose={() => {
              setDocDrawer({ isOpen: false });
            }}
            userId={partners[docDrawer.index as number]._id as string}
            category={undefined as any}
          />
        </CustomDrawer>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
      >
        {partners[selectedTimeSheetIndex]?.type === "service-provider" && (
          <MenuItem
            onClick={() => {
              setDocDrawer({
                isOpen: true,
                index: selectedTimeSheetIndex,
              });
              handleCloseMenu();
            }}
          >
            <FileDocument /> &nbsp; Documents
          </MenuItem>
        )}
        {partners[selectedTimeSheetIndex]?.type === "service-provider" ? (
          <MenuItem
            onClick={() => {
              setUserDrawer({
                isOpen: true,
                index: selectedTimeSheetIndex,
              });
              handleCloseMenu();
            }}
          >
            <AccountGroup /> &nbsp; View Contacts
          </MenuItem>
        ) : (
          <MenuItem
            component={Link}
            to={`/companies/${companyId}/clients/${partners[selectedTimeSheetIndex]?._id}/dashboard`}
            onClick={() => {
              handleCloseMenu();
            }}
          >
            <Account /> &nbsp; View Client
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setPartnerDrawer({
              isOpen: true,
              index: selectedTimeSheetIndex,
            });
            handleCloseMenu();
          }}
        >
          <Pencil />
          &nbsp; Update
        </MenuItem>
        <MenuItem
          onClick={() => {
            removePartner(selectedTimeSheetIndex);
            handleCloseMenu();
          }}
        >
          <TrashCanOutline />
          &nbsp; Delete
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default withConfirmDialogContext(PartnerMain);
