import * as React from "react";
import { Formik } from "formik";
import { IAdmin, ICompany } from "../../vm";
import {
  FormControl,
  Button,
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  addASuperAdmin,
  updateASuperAdmin,
} from "../../services/account/SuperAdminService";
import { ToastContext } from "../common/ToastProvider";

import Loading from "../common/Loading";
import AsyncSelect from "react-select/async";
import { searchCompanies } from "../../services/CompanyService";

const ROLES: { [Key: number]: string } = {
  0: "Super Admin",
  2: "Monitoring Admin",
};

interface onCloseFunc {
  (data?: IAdmin, isAdd?: boolean): void;
}

interface IAddOrUpdateSuperAdminProps {
  onClose: onCloseFunc;
  admin?: IAdmin;
}

const AddOrUpdateSuperAdmin: React.FC<IAddOrUpdateSuperAdminProps> = ({
  onClose,
  admin,
}) => {
  const { showToast } = React.useContext(ToastContext);
  const [isLoading, setLoading] = React.useState(false);
  const [companiesDic, setCompaniesDic] = React.useState<any>({});

  React.useEffect(() => {
    getCompanies();
    if (admin) {
      admin.companyIds = admin.companyIds ? admin.companyIds : [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCompanies = async () => {
    setLoading(true);
    
    const result = await searchCompanies();
    if (result?.success) {
      let a = result.data.reduce((acc: any, ele: ICompany) => {
        let key = ele._id ? ele._id : "";
        if (!acc[key]) {
          acc[key] = "";
        }
        acc[key] = ele.name;
        return acc;
      }, {});
      if(admin){
        let tempCompanyIds:any=[];
        if(admin.companyIds&&admin.companyIds.length>0){
          admin.companyIds.forEach((e)=>{
            tempCompanyIds.push({value:e,label:a[e]})
          })
          admin.tempCompanyIds=tempCompanyIds
        }else{
          admin.tempCompanyIds=[]
        }
      }
      setCompaniesDic(a);
    } else {
      showToast(
        result?.message ? result.message : "Error while getting company list",
        "error"
      );
    }
    setLoading(false);
  };

  const fetchClient = async (inputValue?: string) => {
    let a: Array<{ value: string; label: string }> = [];
    if (inputValue && inputValue.length > 0) {
      Object.keys(companiesDic).forEach((e) => {
        if (companiesDic[e].toLowerCase().includes(inputValue)) {
          a.push({ value: e, label: companiesDic[e] });
        }
      });
      return a;
    } else {
      return [];
    }
  };

  return (
    <div className="p-16">
      {isLoading && <Loading message="processing..." />}
      <Formik
        initialValues={
          admin
            ? admin
            : {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                role: 0,
                companyIds: [],
              }
        }
        validate={(values: IAdmin) => {
          const errors: any = {};
          if (!values.firstName) errors.firstName = "Required";
          if (!values.lastName) errors.lastName = "Required";
          if (!values.email) errors.email = "Required";
          if (values._id == null && !values.password)
            errors.password = "Required";
          if (values.role === 2 && values.companyIds?.length === 0) {
            errors.companyId = "Select atleast 1 company";
          }
          return errors;
        }}
        onSubmit={async (
          values: IAdmin,
          { setSubmitting }: { setSubmitting: Function }
        ) => {
          setLoading(true);
          console.log("user", values);
          const obj = { ...values };
          if (obj.role === 0) {
            obj.companyIds = [];
          }
          delete obj.tempCompanyIds;
          delete obj.companyId;
          console.log("testobj", obj);
          let result
          if (obj._id) {
            result = await updateASuperAdmin(obj._id, obj);
          } else {
            result = await addASuperAdmin(obj);
          }

          if (result?.success) {
            onClose(result.data, obj._id ? false : true);

            showToast(
              `${obj._id ? "Updated" : "Added"} successfully`,
              "success"
            );
          } else {
            showToast(
              result?.message ? result.message : "Error while adding admin",
              "error"
            );
          }
          setSubmitting(false);
          setLoading(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="firstName"
                    label="First Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.firstName && touched.firstName ? true : false}
                    helperText={
                      errors.firstName && touched.firstName && errors.firstName
                    }
                    value={values.firstName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="lastName"
                    label="Last Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.lastName && touched.lastName ? true : false}
                    helperText={
                      errors.lastName && touched.lastName && errors.lastName
                    }
                    value={values.lastName}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="email"
                    type="email"
                    label="Email"
                    autoComplete="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email ? true : false}
                    helperText={errors.email && touched.email && errors.email}
                    value={values.email}
                  />
                </FormControl>
              </Grid>

              {values._id == null && (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl margin="normal" required fullWidth>
                    <TextField
                      size="small"
                      name="password"
                      type="password"
                      label="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.password && touched.password ? true : false}
                      helperText={
                        errors.password && touched.password && errors.password
                      }
                      value={values.password}
                    />
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel className="select-label">Role</InputLabel>
                  <Select
                    name="role"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.role}
                  >
                    {Object.keys(ROLES).map((ele, index) => (
                      <MenuItem key={index} value={Number(ele)}>
                        {ROLES[Number(ele)]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {values.role === 2 && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <InputLabel className="custom-react-select-label">
                    Company
                  </InputLabel>
                  <AsyncSelect
                    isMulti={true}
                    className="async-select react-select"
                    cacheOptions
                    value={values.tempCompanyIds}
                    defaultOptions
                    onBlur={handleBlur}
                    placeholder="Search by name"
                    loadOptions={fetchClient}
                    onChange={(newValue: any) => {
                      let a:Array<string>=[];
                      if(newValue&&newValue.length>0){
                        newValue.forEach((element:any) => {
                          a.push(element.value)
                        });
                      }
                      setFieldValue("companyIds",a)
                      setFieldValue("tempCompanyIds", newValue || []);
                    }}
                  />
                  {errors.companyId && (
                    <Typography variant="caption" color="error">
                      {errors.companyId}
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>

            <br />
            <br />
            <br />

            <Grid container justify="flex-end" alignItems="center" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  className="cancel-btn"
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  className="btn"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {admin ? "Update" : "Add"}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddOrUpdateSuperAdmin;
