import * as React from 'react';
import { ICompany } from '../../vm';
import { Paper, Typography, Grid, Button } from '@material-ui/core';
import { DEFAULT_COMPANY_LOGO } from '../../Constant';

interface ICompanyCard {
  company: ICompany;
  onViewCompany: Function;
  removeHoliday: Function;
}
const CompanyCard: React.FC<ICompanyCard> = ({
  company,
  onViewCompany,
  removeHoliday,
}) => {
  return (
    <Paper className='p-16 company-card' elevation={3}>
      <div className='d-flex'>
        <div className='image-container hide-xs'>
          <img
            src={
              company.logoImageURL ? company.logoImageURL : DEFAULT_COMPANY_LOGO
            }
            // src="https://prod-jobs.s3.amazonaws.com/5e70feb873e9d32ec08c61e9/1584463587363-mentourlogomess.jpg"
            alt='logo'
          />
        </div>
        <div className='company-details'>
          <Typography className='company-name' variant='h4'>
            {company.name}
          </Typography>
          <p>
            <span className='fw-regular fs-14 text-primary'>Email</span>
            <br />
            <span className='email'>{company.email}</span>
          </p>
          {company.url && (
            <p className='url-link'>
              <span className='fw-regular fs-14 text-primary'>Company URL</span>
              <br />
              <a
                href={company.url}
                target='_blank'
                rel='noopener noreferrer'
                className='email anchor-color word-wrap-after'>
                {company.url}
              </a>
            </p>
          )}
          {company.customerUrl && (
            <p className='url-link'>
              <span className='fw-regular fs-14 text-primary'>User URL</span>
              <br />
              <a
                href={company.customerUrl}
                target='_blank'
                rel='noopener noreferrer'
                className='email anchor-color word-wrap-after'>
                {company.customerUrl}
              </a>
            </p>
          )}
          {company.timesheetUrl && (
            <p className='url-link'>
              <span className='fw-regular fs-14 text-primary'>
                Timesheet URL
              </span>
              <br />
              <a
                href={company.timesheetUrl}
                target='_blank'
                rel='noopener noreferrer'
                className='email anchor-color word-wrap-after'>
                {company.timesheetUrl}
              </a>
            </p>
          )}
        </div>
      </div>
      <Grid container justify='flex-end' spacing={2}>
        <Grid item>
          <Button
            variant='text'
            color='secondary'
            className='cancel-btn'
            size='small'
            onClick={() => removeHoliday()}>
            Delete
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant='contained'
            color='primary'
            className='add-btn'
            size='small'
            onClick={() => onViewCompany(company)}>
            View
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CompanyCard;
