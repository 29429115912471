import {
  IStandardAPIResponse,
  ICompanyAdmin,
  ICompany,
  ICompanySearch,
} from '../vm';
import { httpClient } from './UtilService';

export async function addCompanyAndAdmin(
  obj: ICompanyAdmin
): Promise<IStandardAPIResponse<ICompany>> {
  try {
    let res = await httpClient<ICompany>(`admin/companies`, 'PUT', obj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getDashForCompanies(
  searchObj?: any
): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`dash-super-admin`, 'GET', searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getDashForSuperAdmin(
  searchObj?: any
): Promise<
  IStandardAPIResponse<{
    '1099': number;
    w2: number;
    partners: number;
    users: number;
    'service-provider': number;
    client: number;
  }>
> {
  try {
    let res = await httpClient<{
      '1099': number;
      w2: number;
      partners: number;
      users: number;
      'service-provider': number;
      client: number;
    }>(`dashboard`, 'POST', searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function searchCompanies(
  searchObj?: ICompanySearch
): Promise<IStandardAPIResponse<ICompany[]>> {
  try {
    let res = await httpClient<ICompany[]>(`admin/companies`, 'GET', searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getACompany(
  companyId: string
): Promise<IStandardAPIResponse<ICompany>> {
  try {
    let res = await httpClient<ICompany>(`admin/companies/${companyId}`, 'GET');
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function updateACompany(
  companyId: string,
  company: ICompany
): Promise<IStandardAPIResponse<ICompany>> {
  try {
    let res = await httpClient<ICompany>(
      `admin/companies/${companyId}`,
      'PATCH',
      company
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function deleteACompany(
  companyId: string
): Promise<IStandardAPIResponse<ICompany>> {
  try {
    let res = await httpClient<ICompany>(
      `admin/companies/${companyId}`,
      'DELETE'
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
