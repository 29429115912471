import { IStandardAPIResponse, IHoliday } from '../vm';
import { httpClient } from './UtilService';

export async function saveHolidays(
  companyId: string,
  obj: IHoliday[]
): Promise<IStandardAPIResponse<Array<IHoliday>>> {
  try {
    let res = await httpClient<Array<IHoliday>>(
      `companies/${companyId}/holidays`,
      'PATCH',
      obj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getHolidays(
  companyId: string
): Promise<IStandardAPIResponse<Array<IHoliday>>> {
  try {
    let res = await httpClient<Array<IHoliday>>(
      `companies/${companyId}/holidays`,
      'GET'
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
