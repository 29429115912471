import * as React from 'react';
import { Formik } from 'formik';
import { IProjectUser } from '../../../vm';
import {
  FormControl,
  Button,
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import {
  addAProjectUser,
  updateAProjectUser,
} from '../../../services/projects/ProjectUserService';
import { ToastContext } from '../../common/ToastProvider';
import { PROJECT_USER_TYPE } from '../../../Constant';
import InputAdornment from '@material-ui/core/InputAdornment';
// import { RS_THEME } from '../../../Styles';
import AsyncSelect from 'react-select/async';
import Loading from '../../common/Loading';
import { searchUsers } from '../../../services/account/UserService';
import { getUserFullName } from '../../../services/UtilService';

interface onCloseFunc {
  (data?: IProjectUser, isAdd?: boolean): void;
}

interface IAddOrUpdateProjectUserProps {
  onClose: onCloseFunc;
  user?: IProjectUser;
  projectId: string;
  companyId: string;
  currencyCode: string;
}

const getUserSelectOptions = (user: any) => {
  return {
    value: user?._id,
    label: getUserFullName(user),
  };
};

const AddOrUpdateProjectUser: React.FC<IAddOrUpdateProjectUserProps> = ({
  onClose,
  user,
  projectId,
  companyId,
  currencyCode,
}) => {
  const { showToast } = React.useContext(ToastContext);
  const [isLoading, setLoading] = React.useState(false);

  const fetchUsers = async (inputValue?: string) => {
    if (inputValue && inputValue.length > 0) {
      const result = await searchUsers({
        search: inputValue,
        companyId: companyId,
        offset: 0,
        limit: 10,
        dontShowHr: true,
      });

      if (result?.success) {
        return result.data.map((d) => ({ value: d._id, label: d.fullName }));
      } else {
        showToast(
          result?.message ? result.message : 'Error while getting users',
          'error'
        );
      }
    }
    return [];
  };

  return (
    <div className='p-16'>
      {isLoading && <Loading message='processing...' />}
      <Formik
        initialValues={
          user
            ? {
                ...user,
                user_data: getUserSelectOptions(user?.user_data),
              }
            : {
                userId: '',
                projectId: projectId,
                type: 'normal',
                user_data: undefined,
                costPerHour: undefined,
              }
        }
        validate={(values: IProjectUser) => {
          const errors: any = {};
          if (!values.userId) errors.userId = 'Required';
          if (!values.type) errors.type = 'Required';

          if (['normal', 'owner'].includes(values.type)) {
            if (!values.costPerHour) errors.costPerHour = 'Required';
          }

          return errors;
        }}
        onSubmit={async (
          values: IProjectUser,
          { setSubmitting }: { setSubmitting: Function }
        ) => {
          setLoading(true);
          console.log('project User', values);
          const obj = { ...values };

          let result;
          if (obj._id) {
            result = await updateAProjectUser(obj._id, obj);
          } else {
            result = await addAProjectUser(obj);
          }

          if (result?.success) {
            onClose(result.data, obj._id ? false : true);

            showToast(
              `${obj._id ? 'Updated' : 'Added'} successfully`,
              'success'
            );
          } else {
            showToast(
              result?.message ? result.message : 'Error while adding associate',
              'error'
            );
          }
          setSubmitting(false);
          setLoading(false);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <InputLabel className='custom-react-select-label'>
                  User
                </InputLabel>
                <AsyncSelect
                  isDisabled={values._id ? true : false}
                  className='async-select react-select'
                  cacheOptions
                  // theme={RS_THEME}
                  defaultOptions
                  onBlur={handleBlur}
                  value={values.user_data}
                  placeholder='Search by name, email'
                  loadOptions={fetchUsers}
                  onChange={(newValue: any) => {
                    setFieldValue('userId', newValue?.value);
                    setFieldValue('user_data', newValue || {});
                  }}
                />
                {errors.userId && touched.userId && (
                  <Typography variant='caption' color='error'>
                    {errors.userId}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl size='small' fullWidth required>
                  <InputLabel className='select-label' htmlFor='type'>
                    Type
                  </InputLabel>
                  <Select
                    error={errors.type && touched.type ? true : false}
                    value={values.type}
                    name='type'
                    onChange={handleChange}
                    inputProps={{
                      id: 'type',
                    }}>
                    {Object.keys(PROJECT_USER_TYPE).map((index: string) => (
                      <MenuItem value={index} key={index}>
                        {PROJECT_USER_TYPE[index]}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.type && touched.type && (
                    <Typography variant='caption' color='error'>
                      &nbsp; &nbsp;{errors.type}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              {values.type !== 'hr' && (
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <FormControl required fullWidth>
                    <TextField
                      size='small'
                      type='number'
                      name='costPerHour'
                      label='Cost Per Hour'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        startAdornment: currencyCode ? (
                          <InputAdornment position='start'>
                            {currencyCode}
                          </InputAdornment>
                        ) : null,
                      }}
                      error={
                        errors.costPerHour && touched.costPerHour ? true : false
                      }
                      helperText={
                        errors.costPerHour &&
                        touched.costPerHour &&
                        errors.costPerHour
                      }
                      value={values.costPerHour}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <br />

            <Grid container justify='flex-end' spacing={2}>
              <Grid item>
                <Button
                  variant='outlined'
                  color='secondary'
                  className='cancel-btn'
                  onClick={() => onClose()}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type='submit'
                  className='add-btn'
                  variant='contained'
                  color='primary'
                  disabled={isSubmitting}>
                  {user ? 'Update' : 'Add'}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddOrUpdateProjectUser;
