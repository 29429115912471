import {
    IStandardAPIResponse,
    IEmailConfig,
  } from '../vm';
  import { httpClient } from './UtilService';
  
  export async function addOrUpdateEmailConfig(
    obj: IEmailConfig
  ): Promise<IStandardAPIResponse<IEmailConfig>> {
    try {
      let res = await httpClient<IEmailConfig>(`email-configs`, 'PATCH', obj);
      return res;
    } catch (err) {
      return err && err.response ? err.response.data : undefined;
    }
  }
  export async function getEmailConfigs(
    companyId: string
  ): Promise<IStandardAPIResponse<IEmailConfig>> {
    try {
      let res = await httpClient<IEmailConfig>(`email-configs/${companyId}`, 'GET');
      return res;
    } catch (err) {
      return err && err.response ? err.response.data : undefined;
    }
  }