import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import { TableHederCell } from "../common/Library";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {
  askForDocs,
  searchCustomFields,
  updateCustomFields,
} from "../../services/UserDocService";
import { TrashCanOutline } from "mdi-material-ui";
import { DOC_TITLE } from "../../Constant";
import { IUserDoc, docType } from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import Loading from "../common/Loading";
import { useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";

interface onCloseFunc {
  (data?: IUserDoc[]): void;
}

interface IRequestUserDocProps {
  userId: string;
  onClose: onCloseFunc;
}

const RequestUserDoc: React.FC<IRequestUserDocProps> = ({
  userId,
  onClose,
}) => {
  const { showToast } = React.useContext(ToastContext);
  const [isLoading, setLoading] = React.useState(false);
  const [allReqDocs, setAllReqDocs] = React.useState([] as IUserDoc[]);
  const { companyId } = useParams();
  const [docReg, setDocReg] = React.useState({
    userId: userId,
    status: "requested",
    type: "custom-doc",
    customTitle: "",
  } as IUserDoc);

  const [options, setOptions] = React.useState(
    [] as Array<{ value: string; label: string }>
  );

  React.useLayoutEffect(() => {
    const getCustomFields = async () => {
      const result = await searchCustomFields(companyId);
      if (result?.success) {
        const temp = result.data
          ? result.data.fields.map((d) => {
              return { value: d, label: d };
            })
          : [];
        setOptions(temp);
      } else {
        showToast(
          result?.message || "Error while getting custom fields",
          "error"
        );
      }
    };
    getCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addOrUpdateCustomFields = async () => {
    const fields = allReqDocs.map((d) => d.customTitle) as string[];
    const result = await updateCustomFields(companyId, { fields: fields });
    if (result?.success) {
      showToast("Updated custom fields successfully", "success");
    } else {
      showToast(
        result?.message || "Error while updating custom Fields",
        "error"
      );
    }
  };

  const askForDocuments = async () => {
    if (allReqDocs.length > 0) {
      setLoading(true);
      const result = await askForDocs({ docs: allReqDocs });
      if (result?.success) {
        // todo update custom fields
        await addOrUpdateCustomFields();
        onClose(result.data);
      } else {
        showToast(
          result?.message
            ? result.message
            : "Error while requesting for documents",
          "error"
        );
      }
      setLoading(false);
    } else {
      showToast("Please add custom document request", "error");
    }
  };

  return (
    <div className="p-16">
      {isLoading && <Loading message="processing..." />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {allReqDocs.length > 0 && (
            <Table aria-label="Company table">
              <TableHead>
                <TableRow>
                  <TableHederCell>Type</TableHederCell>
                  <TableHederCell>Title</TableHederCell>
                  <TableHederCell align="right">Actions</TableHederCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allReqDocs.map((doc, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {DOC_TITLE[doc.type]}
                    </TableCell>
                    <TableCell>{doc.customTitle}</TableCell>
                    <TableCell align="right">
                      <Tooltip title="Remove">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            const tempDocs = [...allReqDocs];
                            tempDocs.splice(index, 1);
                            setAllReqDocs(tempDocs);
                          }}
                        >
                          <TrashCanOutline />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormControl size="small" fullWidth required>
                <InputLabel className="select-label" htmlFor="type">
                  Type
                </InputLabel>
                <Select
                  value={docReg.type}
                  name="type"
                  onChange={(event) => {
                    setDocReg({
                      ...docReg,
                      type: event.target.value as docType,
                    });
                  }}
                  inputProps={{
                    id: "type",
                  }}
                >
                  <MenuItem value="custom-doc">
                    {DOC_TITLE["custom-doc"]}
                  </MenuItem>
                  <MenuItem value="custom-text">
                    {DOC_TITLE["custom-text"]}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {/* <InputLabel className='custom-react-select-label'>
                Title
              </InputLabel> */}
              <FormControl required fullWidth>
                <CreatableSelect
                  isClearable
                  value={
                    docReg.customTitle
                      ? { value: docReg.customTitle, label: docReg.customTitle }
                      : null
                  }
                  className="async-select react-select"
                  placeholder="Document title"
                  onChange={(newValue: any) => {
                    // console.log('onChange', newValue);
                    setDocReg({
                      ...docReg,
                      customTitle: newValue?.value,
                    });
                  }}
                  options={options}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  if (docReg.customTitle) {
                    const tempDocs = [...allReqDocs];
                    tempDocs.push(docReg);
                    setAllReqDocs(tempDocs);
                    setDocReg({ ...docReg,type: "custom-doc", customTitle: "" });
                  } else {
                    showToast("Please add title", "error");
                  }
                }}
              >
                + add
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <br /> <br />
          <Grid container justify="flex-end" spacing={1}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={askForDocuments}
              >
                Request
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default RequestUserDoc;
