import * as React from "react";
import { Dialog, Grid, Button } from "@material-ui/core";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

export interface CropDialogProps {
  handleClose: Function;
  aspectRatio: number;
  file: any;
  showToast: Function;
}

const CropDialog: React.SFC<CropDialogProps> = (props) => {
  const [upImg, setUpImg] = React.useState(null as any);
  const imgRef = React.useRef(null);
  const previewCanvasRef = React.useRef(null as any);
  const [crop, setCrop] = React.useState({
    unit: "%",
    width: 30,
    aspect: props.aspectRatio,
  } as any);

  React.useEffect(() => {
    if (props.file && props.file.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(props.file[0]);
    }
  }, [props.file]);

  const pixelRatio = 8;

  const [completedCrop, setCompletedCrop] = React.useState(null as any);

  React.useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image: any = imgRef.current;
    const canvas: any = previewCanvasRef.current;
    const crop: any = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const onLoad = React.useCallback((img) => {
    imgRef.current = img;
  }, []);

  const getResizedCanvas = (canvas: any, newWidth: any, newHeight: any) => {
    const tmpCanvas = document.createElement("canvas");
    tmpCanvas.width = newWidth;
    tmpCanvas.height = newHeight;

    const ctx: any = tmpCanvas.getContext("2d");
    ctx.drawImage(
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      0,
      0,
      newWidth,
      newHeight
    );

    return tmpCanvas;
  };

  const onCrop = () => {
    console.log("previewCanvasRef", previewCanvasRef);

    let previewCanvas = previewCanvasRef.current;

    if (!crop || !previewCanvas) {
      return;
    }

    if (crop.width === 0 && crop.height === 0) {
      props.showToast("Please crop the image", "error");
      return;
    }
    const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

    canvas.toBlob(
      (blob: any) => {
        if (props.file && props.file.length > 0) {
          console.log(props.file);
          blob.name = props.file[0].name;
        }
        props.handleClose(blob);
        // const previewUrl = window.URL.createObjectURL(blob);

        // const anchor = document.createElement("a");
        // anchor.download = "cropPreview.png";
        // anchor.href = URL.createObjectURL(blob);
        // anchor.click();

        // window.URL.revokeObjectURL(previewUrl);
      },
      "image/png",
      1
    );
  };

  return (
    <React.Fragment>
      <Dialog
        onClose={() => props.handleClose()}
        aria-labelledby="simple-dialog-title"
        open={true}
        maxWidth="md"
      >
        <Grid container spacing={2} className="crop-tool-container">
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ReactCrop
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop as any}
              onChange={(c: any) => setCrop(c)}
              onComplete={(c: any) => setCompletedCrop(c)}
            />
          </Grid>
          <Grid item lg={12}>
            <div style={{ display: "none" }}>
              <canvas
                ref={previewCanvasRef}
                style={{
                  width: completedCrop?.width ?? 0,
                  height: completedCrop?.height ?? 0,
                }}
              />
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2} justify="flex-end">
              <Grid item>
                <Button
                  className="cancel-btn"
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  className="add-btn"
                  onClick={() => onCrop()}
                >
                  Crop
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
};

export default CropDialog;
