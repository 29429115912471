import * as React from 'react';
import CompanyMain from '../../components/companies/CompanyMain';

const CompanyPage: React.FC = () => (
  <React.Fragment>
    <CompanyMain />
  </React.Fragment>
);

export default CompanyPage;
