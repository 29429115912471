import * as React from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
// import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Loading from "../common/Loading";
import Empty from "../common/Empty";
import { ToastContext } from "../common/ToastProvider";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import {
  IProject,
  IProjectSearch,
  IDrawerOpen,
  IConfirmDialog,
} from "../../vm";
import {
  searchProjects,
  removeAProject,
} from "../../services/projects/ProjectService";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Fab from "@material-ui/core/Fab";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { TableHederCell } from "../common/Library";
import {
  Pencil,
  TrashCanOutline,
  ChevronRight,
  ChevronLeft,
  AccountGroup,
} from "mdi-material-ui";
import { DATE_FORMAT_2 } from "../../Constant";
import moment from "moment";
import CustomDrawer from "../common/CustomDrawer";
import AddOrUpdateProject from "./AddOrUpdateProject";
import ProjectUserMain from "./users/ProjectUserMain";
import Menu from "@material-ui/core/Menu";
import { DotsVertical } from "mdi-material-ui";
interface IProjectMainProps extends IConfirmDialog {}

const ProjectMain: React.FC<IProjectMainProps> = ({ confirmDialog }) => {
  const { showToast } = React.useContext(ToastContext);
  const { companyId, clientId } = useParams();
  const [isLoading, setLoading] = React.useState(false);
  const [projects, setProjects] = React.useState([] as IProject[]);

  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );

  const [selectedTimeSheetIndex, setSelectedTimeSheetIndex] = React.useState<
    number
  >(-1);

  const [projectDrawer, setProjectDrawer] = React.useState({
    isOpen: false,
  } as IDrawerOpen);
  const [projectUserDrawer, setProjectUserDrawer] = React.useState({
    isOpen: false,
  } as IDrawerOpen);

  const [partnerSearch, setPartnerSearch] = React.useState({
    companyId: "",
    limit: 10,
    offset: 0,
  } as IProjectSearch);

  React.useEffect(() => {
    // const type: any = query.get('type');
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProjects = async (offset?: number) => {
    setLoading(true);
    const temp = { ...partnerSearch, companyId: companyId, clientId: clientId };
    if (offset != null) {
      temp.offset = offset;
    }

    setPartnerSearch({ ...temp });

    const result = await searchProjects(temp);

    if (result?.success) {
      setProjects(result.data);
    } else {
      showToast(
        result?.message ? result.message : "Error while getting project list",
        "error"
      );
    }
    setLoading(false);
  };

  const removeProject = async (index: number) => {
    confirmDialog.show("Are you sure", "Do you want to delete?", async () => {
      setLoading(true);
      const usersList = [...projects];
      const result = await removeAProject(usersList[index]._id as string);
      if (result?.success) {
        usersList.splice(index, 1);
        setProjects(usersList);
        showToast("Project removed successfully", "success");
      } else {
        showToast(
          result?.message ? result.message : "Error while removing project",
          "error"
        );
      }
      setLoading(false);
    });
  };

  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setSelectedTimeSheetIndex(index);
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSelectedTimeSheetIndex(-1);
    setAnchorElMenu(null);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading message="Loading..." />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="flex-end" alignItems="center">
            {/* <Grid item>
              <Typography variant='h5'>Users</Typography>
            </Grid> */}
            <Grid item>
              <Button
                className="add-btn"
                variant="contained"
                color="primary"
                onClick={() => {
                  setProjectDrawer({ isOpen: true });
                }}
              >
                Add Project
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper className="card">
            <Grid container spacing={2}>
              <Grid item className="flex-grow">
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    name="name"
                    label="Search by title"
                    onChange={(event: any) => {
                      setPartnerSearch({
                        ...partnerSearch,
                        name: event.target.value,
                      });
                    }}
                    value={partnerSearch.name}
                  />
                </FormControl>
              </Grid>

              <Grid item>
                <Button
                  className="search-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => getProjects()}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          {projects.length === 0 ? (
            <Empty message="No Projects Yet!!" />
          ) : (
            <TableContainer component={Paper} className="timesheet-table">
              <Table aria-label="Company table">
                <TableHead>
                  <TableRow>
                    <TableHederCell>Title</TableHederCell>
                    {/* <TableHederCell>Client</TableHederCell> */}
                    {/* <TableHederCell>HR</TableHederCell> */}
                    <TableHederCell>Start Date</TableHederCell>
                    <TableHederCell>End Date</TableHederCell>

                    <TableHederCell align="right">Actions</TableHederCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projects.map((project, index: number) => (
                    <TableRow key={project._id}>
                      <TableCell component="th" scope="row">
                        {project.name}
                      </TableCell>
                      {/* <TableCell component="th" scope="row">
                        {project?.client_data?.name || "N/A"}
                      </TableCell> */}
                      {/* <TableCell component='th' scope='row'>
                        {getUserFullName(project?.hr_data)}
                      </TableCell> */}
                      <TableCell>
                        {moment(project.startDate).format(DATE_FORMAT_2)}
                      </TableCell>
                      <TableCell>
                        {moment(project.endDate).format(DATE_FORMAT_2)}
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(e) => handleClickMenu(e, index)}
                        >
                          <DotsVertical />
                        </Button>
                        {/* 
                        <Grid container justify="flex-end">
                          <Grid item>
                            <Tooltip title="View Users">
                              <IconButton
                                onClick={() =>
                                  setProjectUserDrawer({
                                    isOpen: true,
                                    index: index,
                                  })
                                }
                              >
                                <AccountGroup />
                              </IconButton>
                            </Tooltip>
                          </Grid>

                          <Grid item>
                            <Tooltip title="Update Project">
                              <IconButton
                                color="secondary"
                                onClick={() =>
                                  setProjectDrawer({
                                    isOpen: true,
                                    index: index,
                                  })
                                }
                              >
                                <Pencil />
                              </IconButton>
                            </Tooltip>
                          </Grid>

                          <Grid item>
                            <Tooltip title="Remove Project">
                              <IconButton
                                color="primary"
                                onClick={() => removeProject(index)}
                              >
                                <TrashCanOutline />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="flex-end" spacing={2}>
            {partnerSearch.offset > 0 && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() => {
                    getProjects(partnerSearch.offset - partnerSearch.limit);
                  }}
                >
                  <ChevronLeft />
                </Fab>
              </Grid>
            )}
            {projects.length >= partnerSearch.limit && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() => {
                    getProjects(partnerSearch.offset + partnerSearch.limit);
                  }}
                >
                  <ChevronRight />
                </Fab>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {projectDrawer.isOpen && (
        <CustomDrawer
          title={`${projectDrawer.index != null ? "Update" : "Add"} Project`}
          onClose={() => {
            setProjectDrawer({ isOpen: false });
          }}
        >
          <AddOrUpdateProject
            companyId={companyId}
            clientId={clientId}
            project={projects[projectDrawer.index as number]}
            onClose={(data?: IProject, isAdd?: boolean) => {
              if (data) {
                if (isAdd === true) {
                  setProjects([data, ...projects]);
                } else {
                  const temp = projects;
                  temp[projectDrawer.index as number] = data;
                  setProjects(temp);
                }
              }
              setProjectDrawer({ isOpen: false });
            }}
          />
        </CustomDrawer>
      )}
      {projectUserDrawer.isOpen && (
        <CustomDrawer
          title={`${projects[projectUserDrawer.index as number].name} Associates`}
          variant="medium"
          onClose={() => {
            setProjectUserDrawer({ isOpen: false });
          }}
        >
          <ProjectUserMain
            projectId={
              projects[projectUserDrawer.index as number]._id as string
            }
            currency={
              projects[projectUserDrawer.index as number].currency as string
            }
          />
        </CustomDrawer>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            setProjectUserDrawer({
              isOpen: true,
              index: selectedTimeSheetIndex,
            });
            handleCloseMenu();
          }}
        >
          <AccountGroup /> &nbsp; View Associates
        </MenuItem>
        <MenuItem
          onClick={() => {
            setProjectDrawer({
              isOpen: true,
              index: selectedTimeSheetIndex,
            });
            handleCloseMenu();
          }}
        >
          <Pencil /> &nbsp; Update
        </MenuItem>

        <MenuItem
          onClick={() => {
            removeProject(selectedTimeSheetIndex);
            handleCloseMenu();
          }}
        >
          <TrashCanOutline />
          &nbsp; Delete
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default withConfirmDialogContext(ProjectMain);
