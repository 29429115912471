import {
  IStandardAPIResponse,
  IUserDoc,
  IUpdateDocArr,
  ICustomField,
  ICategoryDocs,
} from '../vm';
import { httpClient } from './UtilService';

export async function updateCategoryDocsStatus(
  catDocId: string,
  docs: any
): Promise<IStandardAPIResponse<ICategoryDocs>> {
  try {
    let res = await httpClient<ICategoryDocs>(
      `user-category-docs/${catDocId}`,
      'PATCH',
      docs
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getCategoryDoc(obj: {
  userId: string;
  type: string;
}): Promise<IStandardAPIResponse<ICategoryDocs>> {
  try {
    let res = await httpClient<ICategoryDocs>(`user-category-docs`, 'GET', obj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function searchCustomFields(
  companyId: string
): Promise<IStandardAPIResponse<ICustomField>> {
  try {
    let res = await httpClient<ICustomField>(
      `company/${companyId}/custom-fields`,
      'GET'
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function updateCustomFields(
  companyId: string,
  obj: {
    fields: string[];
  }
): Promise<IStandardAPIResponse<ICustomField>> {
  try {
    let res = await httpClient<ICustomField>(
      `company/${companyId}/custom-fields`,
      'PUT',
      obj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function askForADoc(
  obj: IUserDoc
): Promise<IStandardAPIResponse<IUserDoc>> {
  try {
    let res = await httpClient<IUserDoc>(
      `admin/user-docs/ask-for-a-doc`,
      'PUT',
      obj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function askForDocs(obj: {
  docs: IUserDoc[];
}): Promise<IStandardAPIResponse<IUserDoc[]>> {
  try {
    let res = await httpClient<IUserDoc[]>(
      `admin/user-docs/ask-for-docs`,
      'PUT',
      obj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function updateDocsStatus(
  obj: IUpdateDocArr
): Promise<IStandardAPIResponse<IUserDoc[]>> {
  try {
    let res = await httpClient<IUserDoc[]>(
      `admin/user-docs/status`,
      'PATCH',
      obj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function updateADocStatus(
  docId: string,
  obj: { status: string }
): Promise<IStandardAPIResponse<IUserDoc[]>> {
  try {
    let res = await httpClient<IUserDoc[]>(
      `admin/user-docs/${docId}/status`,
      'PATCH',
      obj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getAllDocsOfAUser(
  userId: string
): Promise<IStandardAPIResponse<IUserDoc[]>> {
  try {
    let res = await httpClient<IUserDoc[]>(`users/${userId}/user-docs`, 'GET');
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
