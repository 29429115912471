import * as React from "react";
import { IClientDash } from "../../../vm";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { clientDashReport } from "../../../services/PartnerService";
import { withToastContext, IToast } from "../../common/ToastProvider";

export interface ClientDashBoardMainProps extends IToast {
  clientId: string;
}

export interface ClientDashBoardMainState {
  isLoading: boolean;
  dashData: IClientDash;
}

class ClientDashBoardMain extends React.Component<
  ClientDashBoardMainProps,
  ClientDashBoardMainState
> {
  constructor(props: ClientDashBoardMainProps) {
    super(props);
    this.state = {
      isLoading: false,
      dashData: {
        projectCount: 0,
        userCount: 0,
      },
    };
  }

  componentDidMount = async () => {
    await this.getClientDashDetails();
  };

  getClientDashDetails = async () => {
    this.setState({ isLoading: true });
    let result = await clientDashReport(this.props.clientId);
    if (result?.success) {
      this.setState({ dashData: result.data, isLoading: false });
    } else {
      this.setState({ isLoading: false });
      this.props.showToast(
        result?.message || "Error while getting dashboard details",
        "error"
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <br />
        <br />
        <Grid container spacing={3}>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Total Projects
                </Typography>
                <Typography variant="h2">
                  {this.state.dashData.projectCount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Total Contacts
                </Typography>
                <Typography variant="h2">
                  {this.state.dashData.userCount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withToastContext(ClientDashBoardMain);
