import { IStandardAPIResponse, IPartner, IClientDash } from '../vm';
import { httpClient } from './UtilService';

export async function addAPartner(
  obj: IPartner
): Promise<IStandardAPIResponse<IPartner>> {
  try {
    let res = await httpClient<IPartner>(`partners`, 'PUT', obj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function updateAPartner(
  partnerId: string,
  obj: IPartner
): Promise<IStandardAPIResponse<IPartner>> {
  try {
    let res = await httpClient<IPartner>(`partners/${partnerId}`, 'PATCH', obj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function removeAPartner(
  partnerId: string
): Promise<IStandardAPIResponse<IPartner>> {
  try {
    let res = await httpClient<IPartner>(`partners/${partnerId}`, 'DELETE');
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function searchPartners(
  //   partnerId: string,
  obj: any
): Promise<IStandardAPIResponse<Array<IPartner>>> {
  try {
    let res = await httpClient<Array<IPartner>>(`partners`, 'GET', obj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function clientDashReport(
  clientId: string
): Promise<IStandardAPIResponse<IClientDash>> {
  try {
    let res = await httpClient<IClientDash>(`client-dash-report`, 'GET', {clientId});
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
