import * as React from "react";
import { useParams, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Formik } from "formik";
// import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Loading from "../common/Loading";
import Empty from "../common/Empty";
import { ToastContext } from "../common/ToastProvider";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import { IUser, IUserSearch, IDrawerOpen, IConfirmDialog } from "../../vm";
import {
  searchUsers,
  removeAUser,
  addUsers,
  updateAUserRole,
  sendEmailInviteForUsers,
} from "../../services/account/UserService";
import { exportExcelSheet, getUserFullName } from "../../services/UtilService";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { TableHederCell } from "../common/Library";
import {
  Pencil,
  FileDocument,
  TrashCanOutline,
  ChevronRight,
  ChevronLeft,
  AccountEdit,
  Magnify,
  Download,
} from "mdi-material-ui";
import {
  USER_TYPE,
  EMP_TYPE,
  DOC_STATUS,
  DOC_STATUS_DROPDOWN,
  USER_CATEGORY,
  CAT_FOR_1099,
  SAMPLE_USERS,
} from "../../Constant";
import CustomDrawer from "../common/CustomDrawer";
import AddOrUpdateUser from "./AddOrUpdateUser";
import UserDocsMain from "./UserDocsMain";
import * as XLSX from "xlsx";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import { DotsVertical } from "mdi-material-ui";
import { InputAdornment, Checkbox } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

interface IUserMainProps extends IConfirmDialog {}

const UserMain: React.FC<IUserMainProps> = ({ confirmDialog }) => {
  const query = new URLSearchParams(useLocation().search);
  const { showToast } = React.useContext(ToastContext);
  const { companyId } = useParams();
  const [isLoading, setLoading] = React.useState(false);
  const [users, setUsers] = React.useState([] as IUser[]);

  const [selectedUsersForInvite, setSelectedUsersForInvite] = React.useState(
    [] as string[]
  );

  const [userDrawer, setUserDrawer] = React.useState({
    isOpen: false,
  } as IDrawerOpen);
  const [docDrawer, setDocDrawer] = React.useState({
    isOpen: false,
  } as IDrawerOpen);
  const [userRoleDialog, setUserRoleDialog] = React.useState({
    isOpen: false,
  } as IDrawerOpen);
  const [importDialog, setImportDialog] = React.useState({
    isOpen: false,
  } as IDrawerOpen);
  const theme = useTheme();
  const isGraterThanMD = useMediaQuery(theme.breakpoints.up("sm"));

  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );

  const [selectedTimeSheetIndex, setSelectedTimeSheetIndex] = React.useState<
    number
  >(-1);

  const [isFilterOpen, setFilterOpen] = React.useState(false);

  const [userSearch, setUserSearch] = React.useState({
    companyId: "",
    limit: 10,
    offset: 0,
    startDate: "",
    endDate: "",
    type: "user",
    empType: "all",
    userType: "all",
    docStatus: "all",
  } as IUserSearch);

  const [errorListDrawer, setErrorListDrawer] = React.useState({
    isOpen: false,
    error: [],
  } as { isOpen: boolean; error: IUser[] });

  const [errorInviteDrawer, setErrorInviteDrawer] = React.useState({
    isOpen: false,
    errorsList: [],
  } as { isOpen: boolean; errorsList: any[] });

  React.useEffect(() => {
    const type: any = query.get("type");
    const userType: any = query.get("userType");
    const startDate: any = query.get("startDate");
    const endDate: any = query.get("endDate");
    // console.log('type : ', type, 'user type', userType);

    getUsers(undefined, { type, userType, endDate, startDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUsers = async (
    offset?: number,
    querySearch?: { type?: any; userType?: any; startDate?: any; endDate?: any }
  ) => {
    setLoading(true);
    setSelectedUsersForInvite([]);
    const temp = { ...userSearch };
    if (offset != null) {
      temp.offset = offset;
    }
    if (querySearch != null) {
      if (querySearch.type != null) temp.type = querySearch.type;
      if (querySearch.userType != null) temp.userType = querySearch.userType;
      if (querySearch.endDate != null) temp.endDate = querySearch.endDate;
      if (querySearch.startDate != null) temp.startDate = querySearch.startDate;
    }

    setUserSearch({ ...temp });

    if (temp.type === "all") delete temp.type;
    if (temp.userType === "all") delete temp.userType;
    if (temp.empType === "all") delete temp.empType;
    if (temp.type === "partner") {
      delete temp.userType;
      delete temp.empType;
    }

    const result = await searchUsers({ ...temp, companyId: companyId });
    if (result?.success) {
      setUsers(result.data);
    } else {
      showToast(
        result?.message ? result.message : "Error while getting associates list",
        "error"
      );
    }
    setLoading(false);
  };

  const removeUser = async (index: number) => {
    confirmDialog.show("Are you sure", "Do you want to delete?", async () => {
      setLoading(true);
      const usersList = [...users];
      const result = await removeAUser(usersList[index]._id as string);
      if (result?.success) {
        usersList.splice(index, 1);
        setUsers(usersList);
        showToast("Removed associate successfully", "success");
      } else {
        showToast(
          result?.message ? result.message : "Error while removing associate",
          "error"
        );
      }
      setLoading(false);
    });
  };

  const importUsers = async (event: any) => {
    setLoading(true);
    console.log("event.target.files", event.target.files[0]);
    // var files = e.target.files, f = files[0];
    const reader = new FileReader();
    reader.onload = async (evt: any) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr: any = evt.target.result;
      const wb: any = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname: any = wb.SheetNames[0];
      const ws: any = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data: Array<Array<string>> = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        blankrows: false,
      });
      /* Update state */
      console.log("Data>>>", data);
      // todo do BE call for update
      const userList: IUser[] = [];
      let isError = false;
      let errorMsg;
      for (let i = 1; i < data.length; i++) {
        const d = data[i];
        const obj: any = {
          firstName: d[0] || "",
          middleName: d[1] || "",
          lastName: d[2] || "",
          companyId: companyId,
          email: d[3],
          countryCode: d[4] ? "+" + String(d[4]).trim() : "",
          phone: d[5],
          type: "user",
          userType: d[6] ? String(d[6]).toLowerCase() : "",
          empType: d[7] ? String(d[7]).toLowerCase() : "",
          category: d[8] ? String(d[8]).trim() : "",
          address: {
            street: d[9] ? String(d[9]) : "",
            pin: d[10] ? String(d[10]) : "",
            city: d[11] ? String(d[11]) : "",
            state: d[12] ? String(d[12]) : "",
            country: d[13] ? String(d[13]) : "",
          },
          isEnabled: true,
        };
        console.log("obj", obj);
        if (!["user", "partner"].includes(obj.type)) {
          isError = true;
          errorMsg = `Invalid Type at row ${i + 1}`;
          break;
        }
        if (obj.type === "user") {
          if (!["employee", "consultant"].includes(obj.empType as string)) {
            isError = true;
            errorMsg = `Invalid EmpType at row ${i + 1}`;
            break;
          }
          if (!["w2", "1099"].includes(String(obj.userType))) {
            isError = true;
            errorMsg = `Invalid UserType at row ${i + 1}`;
            break;
          }
          if (
            !["citizen", "permanent-resident", "EAD", "H1B"].includes(
              obj.category as string
            )
          ) {
            isError = true;
            errorMsg = `Invalid Category at row ${i + 1}`;
            break;
          }
          if (obj.userType === "1099") {
            if (!CAT_FOR_1099.includes(obj.category as string)) {
              isError = true;
              errorMsg = `Invalid Category for user type 1099 at row ${
                i + 1
              }. User 1099 can have 'citizen' or 'permanent-resident' as category.`;
              break;
            }
          }
        }
        userList.push(obj);
      }
      if (isError === true) {
        setLoading(false);
        return showToast(errorMsg || "Invalid data format", "error");
      }

      console.log("userList>>>", userList);

      const result = await addUsers(companyId, userList);
      if (result?.success) {
        if (result?.data?.errorList && result.data.errorList.length > 0) {
          if (userList.length > result?.data?.errorList.length) {
            showToast("Some associates imported successfully", "success");
            getUsers(0);
          }
          setErrorListDrawer({ isOpen: true, error: result.data.errorList });
        } else {
          showToast("Associates imported successfully", "success");
          getUsers(0);
        }
        setImportDialog({ isOpen: false });
        // setErrorListDrawer({ isOpen: true, error: userList });
      } else {
        setLoading(false);
        showToast(result?.message || "Error while importing associates", "error");
      }
      setLoading(false);
      // event.target.value = '';
    };
    reader.readAsBinaryString(event.target.files[0]);
  };

  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setSelectedTimeSheetIndex(index);
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSelectedTimeSheetIndex(-1);
    setAnchorElMenu(null);
  };

  const downloadSampleCopy = () => {
    exportExcelSheet(SAMPLE_USERS, "Sample Users Copy");
    setImportDialog({ isOpen: false });
  };

  const FilterActions = () => {
    return (
      <Grid container spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className="search-btn fs-14 fw-medium"
            onClick={() => getUsers(0)}
          >
            Search
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            className="reset-btn fs-14 fw-medium"
            onClick={() => {
              setUserSearch({
                ...userSearch,
                companyId: "",
                startDate: "",
                endDate: "",
                type: "all",
                empType: "all",
                userType: "all",
                docStatus: "all",
              });
            }}
          >
            Reset
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            className="close-btn fs-14 fw-medium"
            onClick={() => {
              setFilterOpen(false);
            }}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    );
  };

  const handleSetSelectedMessage = (userId: string) => {
    let selectedInvites = [...selectedUsersForInvite];
    let index = selectedUsersForInvite.indexOf(userId);
    if (index === -1) {
      selectedInvites.push(userId);
    } else {
      selectedInvites.splice(index, 1);
    }
    setSelectedUsersForInvite(selectedInvites);
  };

  const sendInviteForSelectedUsers = async () => {
    setLoading(true);
    let selectedInvites = [...selectedUsersForInvite];
    const res = await sendEmailInviteForUsers(selectedInvites);
    setLoading(false);
    if (res?.success) {
      if (res?.data?.errorList?.length > 0) {
        setErrorInviteDrawer({ isOpen: true, errorsList: res.data.errorList });
        showToast("Error while sending email invite for some users", "error");
        if(selectedInvites.length>res.data.errorList.length){
          getUsers(0);
        }
      } else {
        showToast("Email invite sent successfully ", "success");
        getUsers(0);
      }
      setSelectedUsersForInvite([]);
    } else {
      showToast(
        res?.message || "Error while sending email invite for some users",
        "error"
      );
    }
  };

  return (
    <React.Fragment>
      {isLoading && <Loading message="Loading..." />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                className="import-btn"
                variant="outlined"
                color="primary"
                component="span"
                onClick={() => {
                  setImportDialog({ ...importDialog, isOpen: true });
                }}
              >
                Import Associates
              </Button>
            </Grid>
            {/* <Grid item>
              <input
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                hidden
                // className={classes.input}
                onChange={importUsers}
                id='import-users'
                multiple
                type='file'
              />
              <label htmlFor='import-users'>
                <Button
                  className='import-btn'
                  variant='outlined'
                  color='primary'
                  component='span'>
                  Import Users
                </Button>
              </label>
            </Grid> */}
            <Grid item>
              <Button
                variant="contained"
                className="add-btn"
                color="primary"
                onClick={() => {
                  setUserDrawer({ isOpen: true });
                }}
              >
                Add Associate
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {isFilterOpen ? (
            <React.Fragment>
              <Paper className="p-16 card">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item className="flex-grow">
                        <FormControl fullWidth>
                          <TextField
                            size="small"
                            name="search"
                            autoFocus
                            placeholder="Search by name, email"
                            onChange={(event: any) => {
                              setUserSearch({
                                ...userSearch,
                                search: event.target.value,
                              });
                            }}
                            variant="standard"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Magnify />
                                </InputAdornment>
                              ),
                            }}
                            value={userSearch.search}
                          />
                        </FormControl>
                      </Grid>
                      {isGraterThanMD && (
                        <Grid>
                          <FilterActions />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <FormControl size="small" fullWidth>
                      <InputLabel className="select-label" htmlFor="type">
                        Document Status
                      </InputLabel>
                      <Select
                        onChange={(event: any) => {
                          setUserSearch({
                            ...userSearch,
                            docStatus: event.target.value,
                          });
                        }}
                        value={userSearch.docStatus}
                        name="docStatus"
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        {Object.keys(DOC_STATUS_DROPDOWN).map(
                          (index: string) => (
                            <MenuItem value={index} key={index}>
                              {DOC_STATUS_DROPDOWN[index]}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={3} lg={2}>
                <FormControl size='small' fullWidth>
                  <InputLabel className='select-label' htmlFor='type'>
                    Type
                  </InputLabel>
                  <Select
                    onChange={(event: any) => {
                      setUserSearch({
                        ...userSearch,
                        type: event.target.value,
                      });
                    }}
                    value={userSearch.type}
                    name='type'>
                    <MenuItem value={'all'}>All</MenuItem>
                    {Object.keys(TYPE).map((index: string) => (
                      <MenuItem value={index} key={index}>
                        {TYPE[index]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
                  {userSearch.type !== "partner" && (
                    <>
                      <Grid item xs={12} sm={6} md={3} lg={2}>
                        <FormControl size="small" fullWidth>
                          <InputLabel className="select-label" htmlFor="type">
                            User Type
                          </InputLabel>
                          <Select
                            onChange={(event: any) => {
                              setUserSearch({
                                ...userSearch,
                                userType: event.target.value,
                              });
                            }}
                            value={userSearch.userType}
                            name="userType"
                          >
                            <MenuItem value={"all"}>All</MenuItem>
                            {Object.keys(USER_TYPE).map((index: string) => (
                              <MenuItem value={index} key={index}>
                                {USER_TYPE[index]}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={2}>
                        <FormControl size="small" fullWidth>
                          <InputLabel className="select-label" htmlFor="type">
                            Employee Type
                          </InputLabel>
                          <Select
                            onChange={(event: any) => {
                              setUserSearch({
                                ...userSearch,
                                empType: event.target.value,
                              });
                            }}
                            value={userSearch.empType}
                            name="empType"
                          >
                            <MenuItem value={"all"}>All</MenuItem>
                            {Object.keys(EMP_TYPE).map((index: string) => (
                              <MenuItem value={index} key={index}>
                                {EMP_TYPE[index]}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <FormControl fullWidth>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        name="startDate"
                        type="date"
                        label="Start Date"
                        onChange={(event: any) => {
                          // console.log('event', event.target.value);
                          setUserSearch({
                            ...userSearch,
                            startDate: event.target.value,
                          });
                        }}
                        value={userSearch.startDate}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <FormControl fullWidth>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        name="endDate"
                        type="date"
                        label="End Date"
                        onChange={(event: any) => {
                          // console.log('event', event.target.value);
                          setUserSearch({
                            ...userSearch,
                            endDate: event.target.value,
                          });
                        }}
                        value={userSearch.endDate}
                      />
                    </FormControl>
                  </Grid>
                  {!isGraterThanMD && (
                    <Grid item xs={12} sm={6} md={3} lg={2}>
                      <FilterActions />
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Paper
                onClick={() => setFilterOpen(true)}
                className="p-16 card pointer"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        name="search"
                        placeholder="Search by name, email"
                        variant="standard"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Magnify />
                            </InputAdornment>
                          ),
                        }}
                        value={userSearch.search}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </React.Fragment>
          )}
        </Grid>
        {users.length > 0 && (
          <Grid item xs={12} className="text-right mt-16i">
            <Button
              className="add-btn"
              variant="contained"
              color="primary"
              disabled={selectedUsersForInvite.length === 0}
              onClick={() => {
                sendInviteForSelectedUsers();
              }}
            >
              Send Invite
            </Button>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {users.length === 0 ? (
            <Empty message="No Associates Yet!!" />
          ) : (
            <TableContainer component={Paper} className="timesheet-table">
              <Table aria-label="Company table">
                <TableHead>
                  <TableRow>
                    <TableHederCell></TableHederCell>
                    <TableHederCell>Full Name</TableHederCell>
                    <TableHederCell>Email</TableHederCell>
                    {/* <TableHederCell>Type</TableHederCell> */}
                    <TableHederCell>User Type</TableHederCell>
                    <TableHederCell>Employee Type</TableHederCell>
                    <TableHederCell>Enabled?</TableHederCell>
                    <TableHederCell>Verified By User?</TableHederCell>
                    <TableHederCell>Document Status</TableHederCell>
                    <TableHederCell align="right" width={250}>
                      Actions
                    </TableHederCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user, index: number) => (
                    <TableRow key={user._id}>
                      <TableCell>
                        {user.isInviteSent === false && (
                          <Checkbox
                            checked={
                              selectedUsersForInvite.findIndex(
                                (e) => e === user._id
                              ) > -1
                            }
                            onChange={() =>
                              handleSetSelectedMessage(user._id as any)
                            }
                            color="primary"
                          />
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {getUserFullName(user)}
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      {/* <TableCell>{user.type ? TYPE[user.type] : '-'}</TableCell> */}
                      <TableCell>
                        {user.userType ? USER_TYPE[user.userType] : "-"}
                      </TableCell>
                      <TableCell>
                        {user.empType ? EMP_TYPE[user.empType] : "-"}
                      </TableCell>
                      <TableCell>{user.isEnabled ? "Yes" : "No"}</TableCell>
                      <TableCell>
                        {user.isVerifiedByUser ? "Yes" : "No"}
                      </TableCell>
                      <TableCell>
                        {DOC_STATUS[user.docStatus as string] || "N/A"}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(e) => handleClickMenu(e, index)}
                        >
                          <DotsVertical />
                        </Button>
                        {/* <Grid container justify="flex-end">
                          <Grid item>
                            <Tooltip title="Update User Role">
                              <IconButton
                                onClick={() => {
                                  confirmDialog.show(
                                    "Are you sure?",
                                    "All documents associated with present role will be inactive if you update user role.",
                                    () => {
                                      setUserRoleDialog({
                                        isOpen: true,
                                        index: index,
                                      });
                                    }
                                  );
                                }}
                              >
                                <AccountEdit />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Documents">
                              <IconButton
                                onClick={() =>
                                  setDocDrawer({
                                    isOpen: true,
                                    index: index,
                                  })
                                }
                              >
                                <FileDocument />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Update User">
                              <IconButton
                                color="secondary"
                                onClick={() =>
                                  setUserDrawer({
                                    isOpen: true,
                                    index: index,
                                  })
                                }
                              >
                                <Pencil />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Remove User">
                              <IconButton
                                color="primary"
                                onClick={() => removeUser(index)}
                              >
                                <TrashCanOutline />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid> */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="flex-end" spacing={2}>
            {userSearch.offset > 0 && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() => {
                    // setUserSearch({
                    //   ...userSearch,
                    //   offset: userSearch.offset - userSearch.limit,
                    // });
                    getUsers(userSearch.offset - userSearch.limit);
                  }}
                >
                  <ChevronLeft />
                </Fab>
              </Grid>
            )}
            {users.length >= userSearch.limit && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() => {
                    // setUserSearch({
                    //   ...userSearch,
                    //   offset: userSearch.offset + userSearch.limit,
                    // });
                    getUsers(userSearch.offset + userSearch.limit);
                  }}
                >
                  <ChevronRight />
                </Fab>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {userDrawer.isOpen && (
        <CustomDrawer
          title={`${userDrawer.index != null ? "Update" : "Add"} Associate`}
          onClose={() => {
            setUserDrawer({ isOpen: false });
          }}
        >
          <AddOrUpdateUser
            companyId={companyId}
            user={users[userDrawer.index as number]}
            onClose={(data?: IUser, isAdd?: boolean) => {
              if (data) {
                if (isAdd === true) {
                  setUsers([data, ...users]);
                } else {
                  const temp = users;
                  temp[userDrawer.index as number] = data;
                  setUsers(temp);
                }
              }
              setUserDrawer({ isOpen: false });
            }}
          />
        </CustomDrawer>
      )}
      {docDrawer.isOpen && (
        <CustomDrawer
          title={`Associate Documents`}
          variant="large"
          onClose={() => {
            setDocDrawer({ isOpen: false });
          }}
        >
          <UserDocsMain
            onClose={() => {
              setDocDrawer({ isOpen: false });
            }}
            userId={users[docDrawer.index as number]._id as string}
            category={users[docDrawer.index as number].category as any}
          />
        </CustomDrawer>
      )}
      {errorListDrawer.isOpen && (
        <>
          <Dialog
            open={true}
            onClose={() => {
              setErrorListDrawer({ isOpen: false, error: [] });
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Invalid associate data
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography>
                  {`There are ${errorListDrawer.error.length} associates with invalid data.`}
                </Typography>
                <br />
                <div style={{ textAlign: "center" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="cancel-btn"
                    onClick={() => {
                      exportExcelSheet(
                        errorListDrawer.error,
                        "InvalidUserList"
                      );
                      setErrorListDrawer({ isOpen: false, error: [] });
                    }}
                  >
                    Download Error List
                  </Button>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
      )}
      {userRoleDialog.isOpen && (
        <>
          <Dialog
            open={true}
            onClose={() => {
              setUserRoleDialog({ isOpen: false, index: undefined });
            }}
            aria-labelledby="update-user-role"
            aria-describedby="update-user-description"
          >
            <DialogTitle id="update-user-role">
              Update Role for{" "}
              {`${users[userRoleDialog.index as number].firstName} ${
                users[userRoleDialog.index as number].lastName
              }`}
            </DialogTitle>
            <DialogContent>
              {/* <DialogContentText id='update-user-description'>
                <Typography>
                  {`${users[userRoleDialog.index as number].firstName} ${
                    users[userRoleDialog.index as number].lastName
                  }`}
                </Typography>
              </DialogContentText> */}

              <Formik
                initialValues={users[userRoleDialog.index as number]}
                validate={(values: IUser) => {
                  const errors: any = {};

                  if (!values.type) errors.type = "Required";
                  if (values.type === "user") {
                    if (!values.userType || (values.userType as any) === "none")
                      errors.userType = "Required";
                    if (!values.empType || (values.empType as any) === "none")
                      errors.empType = "Required";

                    if (!values.category) errors.category = "Required";

                    if (values.userType === "1099") {
                      if (!CAT_FOR_1099.includes(values.category as any)) {
                        errors.category = "Required";
                      }
                    }
                  }

                  return errors;
                }}
                onSubmit={async (
                  values: IUser,
                  { setSubmitting }: { setSubmitting: Function }
                ) => {
                  const user = users[userRoleDialog.index as number];
                  setLoading(true);

                  console.log("user", values);
                  const obj = { ...values };
                  if (obj.type === "partner") {
                    delete obj.empType;
                    delete obj.userType;
                  }
                  // obj.companyId = companyId

                  // todo user role update call
                  let role;

                  // check if type changed
                  if (obj.type === "partner" && user?.type !== "partner") {
                    role = "partner";
                  } else if (obj.type === "user") {
                    if (obj.userType !== user?.userType) {
                      role = obj.userType;
                    }
                  }

                  const isNewRole = role != null;
                  const isNewCategory = obj.category !== user?.category;

                  // if (role != null) {
                  // do BE call
                  console.log("role", role);

                  const res = await updateAUserRole(
                    obj._id as string,
                    (isNewRole ? role : obj.userType) as string,
                    obj.empType as string,
                    isNewCategory,
                    obj.category as string,
                    isNewRole
                  );
                  if (res?.success) {
                    // onClose(res.data, obj._id ? false : true);
                    const temp = users;
                    temp[userRoleDialog.index as number] = res.data;
                    setUsers(temp);
                    setUserRoleDialog({ isOpen: false, index: undefined });
                    showToast("Updated associate role successfully", "success");
                  } else {
                    showToast(
                      res?.message || "Error while updating associate role",
                      "error"
                    );
                  }
                  // }

                  setSubmitting(false);
                  setLoading(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormControl
                          margin='normal'
                          size='small'
                          fullWidth
                          required>
                          <InputLabel className='select-label' htmlFor='type'>
                            Type
                          </InputLabel>
                          <Select
                            error={errors.type && touched.type ? true : false}
                            value={values.type}
                            name='type'
                            onChange={handleChange}
                            inputProps={{
                              id: 'type',
                            }}>
                            {Object.keys(TYPE).map((index: string) => (
                              <MenuItem value={index} key={index}>
                                {TYPE[index]}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.type && touched.type && (
                            <Typography variant='caption' color='error'>
                              &nbsp; &nbsp;{errors.type}
                            </Typography>
                          )}
                        </FormControl>
                      </Grid> */}

                      {values.type === "user" && (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControl margin="normal" size="small" fullWidth>
                              <InputLabel
                                className="select-label"
                                htmlFor="userType"
                              >
                                User Type
                              </InputLabel>
                              <Select
                                error={
                                  errors.userType && touched.userType
                                    ? true
                                    : false
                                }
                                value={values.userType}
                                name="userType"
                                onChange={handleChange}
                                inputProps={{
                                  id: "userType",
                                }}
                              >
                                {/* <MenuItem value={'none'}>
                          <em>None</em>
                        </MenuItem> */}
                                {Object.keys(USER_TYPE).map((index: string) => (
                                  <MenuItem value={index} key={index}>
                                    {USER_TYPE[index]}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.userType && touched.userType && (
                                <Typography variant="caption" color="error">
                                  &nbsp; &nbsp;{errors.userType}
                                </Typography>
                              )}
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControl margin="normal" size="small" fullWidth>
                              <InputLabel
                                className="select-label"
                                htmlFor="empType"
                              >
                                Employee Type
                              </InputLabel>
                              <Select
                                error={
                                  errors.empType && touched.empType
                                    ? true
                                    : false
                                }
                                value={values.empType}
                                name="empType"
                                onChange={handleChange}
                                inputProps={{
                                  id: "empType",
                                }}
                              >
                                {/* <MenuItem value={'none'}>
                          <em>None</em>
                        </MenuItem> */}
                                {Object.keys(EMP_TYPE).map((index: string) => (
                                  <MenuItem value={index} key={index}>
                                    {EMP_TYPE[index]}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.empType && touched.empType && (
                                <Typography variant="caption" color="error">
                                  &nbsp; &nbsp;{errors.empType}
                                </Typography>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControl
                              margin="normal"
                              size="small"
                              fullWidth
                              required
                            >
                              <InputLabel
                                className="select-label"
                                htmlFor="type"
                              >
                                Category
                              </InputLabel>
                              <Select
                                error={
                                  errors.category && touched.category
                                    ? true
                                    : false
                                }
                                value={values.category}
                                name="category"
                                onChange={handleChange}
                                inputProps={{
                                  id: "category",
                                }}
                              >
                                {Object.keys(USER_CATEGORY)
                                  .filter((d) => {
                                    if (values.userType === "1099") {
                                      return CAT_FOR_1099.includes(d);
                                    }
                                    return true;
                                  })
                                  .map((index: string) => (
                                    <MenuItem value={index} key={index}>
                                      {USER_CATEGORY[index]}
                                    </MenuItem>
                                  ))}
                              </Select>
                              {errors.category && touched.category && (
                                <Typography variant="caption" color="error">
                                  &nbsp; &nbsp;{errors.category}
                                </Typography>
                              )}
                            </FormControl>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <br />

                    <Grid container justify="flex-end" spacing={2}>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="secondary"
                          className="cancel-btn"
                          onClick={() => {
                            setUserRoleDialog({
                              isOpen: false,
                              index: undefined,
                            });
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="add-btn"
                          disabled={isSubmitting}
                        >
                          update
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
              <br />
            </DialogContent>
          </Dialog>
        </>
      )}
      {importDialog.isOpen && (
        <>
          <Dialog
            open={true}
            onClose={() => {
              setImportDialog({ isOpen: false });
            }}
            aria-labelledby="update-user-role"
            aria-describedby="update-user-description"
          >
            {/* <DialogTitle id='update-user-role'>Import users</DialogTitle> */}
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden
                    // className={classes.input}
                    onChange={importUsers}
                    id="import-users"
                    multiple
                    type="file"
                  />
                  <label htmlFor="import-users">
                    <Button
                      // className='import-btn'
                      variant="contained"
                      color="primary"
                      component="span"
                      fullWidth
                    >
                      Import Associates
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    startIcon={<Download />}
                    onClick={downloadSampleCopy}
                  >
                    Download Sample
                  </Button>
                </Grid>
              </Grid>

              <br />
            </DialogContent>
          </Dialog>
        </>
      )}

      {errorInviteDrawer.isOpen && (
        <>
          <Dialog
            open={true}
            onClose={() => {
              setErrorInviteDrawer({ isOpen: false, errorsList: [] });
            }}
            aria-labelledby="error-invite-email"
            aria-describedby="error-invite-email"
          >
            <DialogContent>
              <Typography variant="h6" className="mb-8">
                Error Invites List
              </Typography>
              <Grid container spacing={2}>
                {errorInviteDrawer.errorsList.map((x, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12}>
                      <Typography>{x.firstName}</Typography>
                      <Typography>{x.email}</Typography>
                      {index + 1 !== errorInviteDrawer.errorsList.length && (
                        <Divider className="mt-8" />
                      )}
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </DialogContent>
          </Dialog>
        </>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            confirmDialog.show(
              "Are you sure?",
              "All documents associated with present role will be inactive if you update user type.",
              () => {
                setUserRoleDialog({
                  isOpen: true,
                  index: selectedTimeSheetIndex,
                });
                handleCloseMenu();
              }
            );
          }}
        >
          <AccountEdit /> &nbsp; Update User Type
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDocDrawer({
              isOpen: true,
              index: selectedTimeSheetIndex,
            });
            handleCloseMenu();
          }}
        >
          <FileDocument /> &nbsp; Documents
        </MenuItem>
        <MenuItem
          onClick={() => {
            setUserDrawer({
              isOpen: true,
              index: selectedTimeSheetIndex,
            });
            handleCloseMenu();
          }}
        >
          <Pencil />
          &nbsp; Update
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeUser(selectedTimeSheetIndex);
            handleCloseMenu();
          }}
        >
          <TrashCanOutline />
          &nbsp; Delete
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default withConfirmDialogContext(UserMain);
