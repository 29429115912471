import * as React from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
// import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Loading from "../../common/Loading";
import Empty from "../../common/Empty";
import { ToastContext } from "../../common/ToastProvider";
import { withConfirmDialogContext } from "../../common/ConfirmDialogProvider";
import {
  IProjectUser,
  IProjectUserSearch,
  IDrawerOpen,
  IConfirmDialog,
} from "../../../vm";
import {
  searchProjectUsers,
  removeAProjectUser,
} from "../../../services/projects/ProjectUserService";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TableHederCell } from "../../common/Library";
import { Pencil, TrashCanOutline } from "mdi-material-ui";
import { PROJECT_USER_TYPE, CURRENCY } from "../../../Constant";
import { getUserFullName } from "../../../services/UtilService";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { DotsVertical } from "mdi-material-ui";

import CustomDrawer from "../../common/CustomDrawer";

import AddOrUpdateProjectUser from "./AddOrUpdateProjectUser";

const currency_values = JSON.parse(CURRENCY);

interface IProjectUserUserMainProps extends IConfirmDialog {
  projectId: string;
  currency: string;
}

const ProjectUserMain: React.FC<IProjectUserUserMainProps> = ({
  confirmDialog,
  projectId,
  currency,
}) => {
  const { companyId } = useParams();
  const { showToast } = React.useContext(ToastContext);
  const [isLoading, setLoading] = React.useState(false);
  const [projectUsers, setProjectUsers] = React.useState([] as IProjectUser[]);
  const [userDrawer, setUserDrawer] = React.useState({
    isOpen: false,
  } as IDrawerOpen);

  const currencyCode: string = currency_values[currency]?.code;

  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );

  const [selectedTimeSheetIndex, setSelectedTimeSheetIndex] = React.useState<
    number
  >(-1);

  const [userSearch, setUserSearch] = React.useState({
    projectId: projectId,
    limit: 10,
    offset: 0,
  } as IProjectUserSearch);

  React.useEffect(() => {
    // const type: any = query.get('type');
    getProjectUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProjectUsers = async (offset?: number) => {
    setLoading(true);
    const temp = { ...userSearch, projectId: projectId };
    if (offset != null) {
      temp.offset = offset;
    }

    setUserSearch({ ...temp });

    const result = await searchProjectUsers(temp);

    if (result?.success) {
      setProjectUsers(result.data);
    } else {
      showToast(
        result?.message ? result.message : "Error while getting associates list",
        "error"
      );
    }
    setLoading(false);
  };

  const removeProject = async (index: number) => {
    confirmDialog.show("Are you sure", "Do you want to delete?", async () => {
      setLoading(true);
      const usersList = [...projectUsers];
      const result = await removeAProjectUser(usersList[index]._id as string);
      if (result?.success) {
        usersList.splice(index, 1);
        setProjectUsers(usersList);
        showToast("Removed associate successfully", "success");
      } else {
        showToast(
          result?.message ? result.message : "Error while removing associate",
          "error"
        );
      }
      setLoading(false);
    });
  };

  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setSelectedTimeSheetIndex(index);
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSelectedTimeSheetIndex(-1);
    setAnchorElMenu(null);
  };

  return (
    <div className="p-16">
      {isLoading && <Loading message="Loading..." />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="flex-end" alignItems="center">
            {/* <Grid item>
              <Typography variant='h5'>Users</Typography>
            </Grid> */}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className="add-btn"
                onClick={() => {
                  setUserDrawer({ isOpen: true });
                }}
              >
                Add Associate
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <FormControl fullWidth>
                <TextField
                  size='small'
                  name='name'
                  label='Search by title'
                  onChange={(event: any) => {
                    setUserSearch({
                      ...userSearch,
                      name: event.target.value,
                    });
                  }}
                  value={userSearch.name}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => getProjectUsers()}>
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid> */}

        <Grid item xs={12} sm={12} md={12} lg={12}>
          {projectUsers.length === 0 ? (
            <Empty message="No Associates Yet!!" />
          ) : (
            <TableContainer component={Paper} className="timesheet-table">
              <Table aria-label="Company table">
                <TableHead>
                  <TableRow>
                    <TableHederCell>Name</TableHederCell>
                    <TableHederCell>Type</TableHederCell>
                    <TableHederCell>Cost Per Hour</TableHederCell>

                    <TableHederCell align="right">Actions</TableHederCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectUsers.map((user, index: number) => (
                    <TableRow key={user._id}>
                      <TableCell component="th" scope="row">
                        {getUserFullName(user?.user_data)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {PROJECT_USER_TYPE[user?.type]}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {user.costPerHour
                          ? ` ${currencyCode} ${user.costPerHour}`
                          : "N/A"}
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          aria-controls="user-menu"
                          aria-haspopup="true"
                          onClick={(e) => handleClickMenu(e, index)}
                        >
                          <DotsVertical />
                        </Button>
                        {/*
                        <Grid container justify="flex-end">
                          <Grid item>
                            <Tooltip title="Update User">
                              <IconButton
                                color="secondary"
                                onClick={() =>
                                  setUserDrawer({
                                    isOpen: true,
                                    index: index,
                                  })
                                }
                              >
                                <Pencil />
                              </IconButton>
                            </Tooltip>
                          </Grid>

                          <Grid item>
                            <Tooltip title="Remove User">
                              <IconButton
                                color="primary"
                                onClick={() => removeProject(index)}
                              >
                                <TrashCanOutline />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                         */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify='flex-end' spacing={2}>
            {userSearch.offset > 0 && (
              <Grid item>
                <Fab
                  size='small'
                  color='primary'
                  onClick={() => {
                    getProjectUsers(userSearch.offset - userSearch.limit);
                  }}>
                  <ChevronLeft />
                </Fab>
              </Grid>
            )}
            {projectUsers.length >= userSearch.limit && (
              <Grid item>
                <Fab
                  size='small'
                  color='primary'
                  onClick={() => {
                    getProjectUsers(userSearch.offset + userSearch.limit);
                  }}>
                  <ChevronRight />
                </Fab>
              </Grid>
            )}
          </Grid>
        </Grid> */}
      </Grid>
      {userDrawer.isOpen && (
        <CustomDrawer
          title={`${userDrawer.index != null ? "Update" : "Add"} Associate`}
          onClose={() => {
            setUserDrawer({ isOpen: false });
          }}
        >
          <AddOrUpdateProjectUser
            currencyCode={currencyCode}
            projectId={projectId}
            companyId={companyId}
            user={projectUsers[userDrawer.index as number]}
            onClose={(data?: IProjectUser, isAdd?: boolean) => {
              if (data) {
                if (isAdd === true) {
                  setProjectUsers([data, ...projectUsers]);
                } else {
                  const temp = projectUsers;
                  temp[userDrawer.index as number] = data;
                  setProjectUsers(temp);
                }
              }
              setUserDrawer({ isOpen: false });
            }}
          />
        </CustomDrawer>
      )}
      <Menu
        id="user-menu"
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            setUserDrawer({
              isOpen: true,
              index: selectedTimeSheetIndex,
            });
            handleCloseMenu();
          }}
        >
          <Pencil />
          &nbsp; Update
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeProject(selectedTimeSheetIndex);
            handleCloseMenu();
          }}
        >
          <TrashCanOutline />
          &nbsp; Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

export default withConfirmDialogContext(ProjectUserMain);
