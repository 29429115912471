import * as React from "react";
import { Formik, FormikErrors } from "formik";
import { IPartner } from "../../vm";
import {
  FormControl,
  Button,
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { addAPartner, updateAPartner } from "../../services/PartnerService";
import { ToastContext } from "../common/ToastProvider";
import { PARTNER_TYPE, US_ZIP_CODE_REGEX, COUNTRY_CODES, PHONE_REGEX } from "../../Constant";

import Loading from "../common/Loading";
import ReactSelect from "react-select";

interface onCloseFunc {
  (data?: IPartner, isAdd?: boolean): void;
}

interface IAddOrUpdatePartnerProps {
  onClose: onCloseFunc;
  partner?: IPartner;
  companyId: string;
}

const AddOrUpdatePartner: React.FC<IAddOrUpdatePartnerProps> = ({
  onClose,
  partner,
  companyId,
}) => {
  const { showToast } = React.useContext(ToastContext);
  const [isLoading, setLoading] = React.useState(false);
  const [isSubmited, setSubmitted] = React.useState(false);

  const country_codes_values: Array<string> = JSON.parse(COUNTRY_CODES);
  return (
    <div className="p-16">
      {isLoading && <Loading message="processing..." />}
      <Formik
        initialValues={
          partner
            ? partner
            : {
                name: "",
                companyId: companyId,
                email: "",
                password: "",
                phone: "",
                isEnabled: true,
                address: {
                  street: "",
                  pin: "",
                  city: "",
                  country: "",
                  state: "",
                },
                countryCode: "+1",
                type: "service-provider", // service-provider, client
              }
        }
        validate={(values: IPartner) => {
          const errors: FormikErrors<IPartner> = {};
          if (!values.name) errors.name = "Required";
          //   if (!values.lastName) errors.lastName = 'Required';
          if (!values.email) errors.email = "Required";
          if (!values.phone) errors.phone = "Required";
          if (!values.type) errors.type = "Required";
          if (values.phone && PHONE_REGEX.test(values.phone) === false) {
            errors.phone = "Please enter phone number of format 9876543210";
          }
          if (values.phone != null) {
            if (!values.countryCode) errors.countryCode = "Required";
          }
          if (values._id == null && !values.password) {
            errors.password = "Required";
          }
          if (!values.address?.street) {
            errors.streetE = "Required";
          }
          if (!values.address?.city) {
            errors.cityE = "Required";
          }
          if (!values.address?.state) {
            errors.stateE = "Required";
          }
          if (!values.address?.country) {
            errors.countryE = "Required";
          }
          if (!values.address?.pin) {
            errors.pinE = "Required";
          }
          if (
            values.address &&
            values.address.pin &&
            US_ZIP_CODE_REGEX.test(values.address.pin) === false
          ) {
            errors.pinE = "Invalid zip code";
          }

          return errors;
        }}
        onSubmit={async (
          values: IPartner,
          { setSubmitting }: { setSubmitting: Function }
        ) => {
          setLoading(true);
          console.log("user", values);
          const obj = { ...values };
          delete obj.streetE;
          delete obj.cityE;
          delete obj.stateE;
          delete obj.pinE;
          delete obj.countryE;
          let result;
          if (obj._id) {
            result = await updateAPartner(obj._id, obj);
          } else {
            result = await addAPartner(obj);
          }

          if (result?.success) {
            onClose(result.data, obj._id ? false : true);

            showToast(
              `${obj._id ? "Updated" : "Added"} successfully`,
              "success"
            );
          } else {
            showToast(
              result?.message ? result.message : "Error while adding partner",
              "error"
            );
          }
          setSubmitting(false);
          setLoading(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="name"
                    label="Company Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.name && touched.name ? true : false}
                    helperText={errors.name && touched.name && errors.name}
                    value={values.name}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="email"
                    type="email"
                    label="Email"
                    autoComplete="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email ? true : false}
                    helperText={errors.email && touched.email && errors.email}
                    value={values.email}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl margin="normal" size="small" fullWidth required>
                  <InputLabel className="select-label" htmlFor="type">
                    Type
                  </InputLabel>
                  <Select
                    disabled={values._id ? true : false}
                    error={errors.type && touched.type ? true : false}
                    value={values.type}
                    name="type"
                    onChange={handleChange}
                    inputProps={{
                      id: "type",
                    }}
                  >
                    {Object.keys(PARTNER_TYPE).map((index: string) => (
                      <MenuItem value={index} key={index}>
                        {PARTNER_TYPE[index]}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.type && touched.type && (
                    <Typography variant="caption" color="error">
                      &nbsp; &nbsp;{errors.type}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                className="custom-react-select-form mt-16i"
              >
                <InputLabel className="custom-react-select-label">
                  Country code
                </InputLabel>
                <ReactSelect
                  value={{
                    value: values.countryCode,
                    label: values.countryCode,
                  }}
                  className="async-select react-select"
                  placeholder="Country code"
                  isClearable={false}
                  options={country_codes_values.map((x) => {
                    return {
                      label: x,
                      value: x,
                    };
                  })}
                  onChange={(newValue: any) => {
                    console.log("newValue", newValue);
                    setFieldValue("countryCode", newValue?.value);
                  }}
                />
                {errors.countryCode && (
                  <Typography variant="caption" color="error">
                    &nbsp; &nbsp;{errors.countryCode}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="phone"
                    label="Phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.phone && touched.phone ? true : false}
                    helperText={errors.phone && touched.phone && errors.phone}
                    value={values.phone}
                  />
                </FormControl>
              </Grid>

              {values._id == null ? (
                <React.Fragment>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        size="small"
                        name="password"
                        type="password"
                        label="Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors.password && touched.password ? true : false
                        }
                        helperText={
                          errors.password && touched.password && errors.password
                        }
                        value={values.password}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        size="small"
                        name="address.street"
                        label="Street"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={isSubmited&&errors?.streetE ? true : false}
                        helperText={isSubmited&&errors?.streetE && errors.streetE}
                        value={values?.address?.street}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl margin="normal" required fullWidth>
                    <TextField
                      size="small"
                      name="address.street"
                      label="Street"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={isSubmited&&errors?.streetE ? true : false}
                      helperText={isSubmited&&errors?.streetE && errors.streetE}
                      value={values?.address?.street}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="address.pin"
                    label="Zip Code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={isSubmited&&errors?.pinE ? true : false}
                    helperText={isSubmited&&errors?.pinE && errors.pinE}
                    value={values?.address?.pin}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="address.city"
                    label="City"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={isSubmited&&errors?.cityE ? true : false}
                    helperText={isSubmited&&errors?.cityE && errors.cityE}
                    value={values?.address?.city}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="address.state"
                    label="State"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={isSubmited&&errors?.stateE ? true : false}
                    helperText={isSubmited&&errors?.stateE && errors.stateE}
                    value={values?.address?.state}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    size="small"
                    name="address.country"
                    label="Country"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={isSubmited&&errors?.countryE ? true : false}
                    helperText={isSubmited&&errors?.countryE && errors.countryE}
                    value={values?.address?.country}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isEnabled}
                      onChange={handleChange}
                      value="isEnabled"
                      name="isEnabled"
                    />
                  }
                  label="Enable"
                />
              </Grid>
            </Grid>
            <br />

            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  className="cancel-btn"
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  className="add-btn"
                  color="primary"
                  onClick={() => {if(!isSubmited){setSubmitted(true)}}}
                  disabled={isSubmitting}
                >
                  {partner ? "Update" : "Add"}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddOrUpdatePartner;
