import { IUser, IUserSearch, IStandardAPIResponse, IEmailInvite } from '../../vm';
import { httpClient } from '../UtilService';

export async function addUsers(
  companyId: string,
  users: IUser[]
): Promise<IStandardAPIResponse<{ successList: IUser[]; errorList: IUser[] }>> {
  try {
    let res = await httpClient<{ successList: IUser[]; errorList: IUser[] }>(
      `import-users/${companyId}`,
      'PUT',
      users
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function addAUser(
  user: IUser
): Promise<IStandardAPIResponse<IUser>> {
  try {
    let res = await httpClient<IUser>(`admin/users/register`, 'PUT', user);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function updateAUserRole(
  userId: string,
  newRole: string,
  employeeType: string,
  isCategoryUpdate: boolean,
  newCategory: string,
  isRoleUpdate: boolean
): Promise<IStandardAPIResponse<IUser>> {
  try {
    let res = await httpClient<IUser>(`users/${userId}/role`, 'PATCH', {
      newRole,
      employeeType,
      isCategoryUpdate,
      newCategory,
      isRoleUpdate,
    });
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function updateAUser(
  userId: string,
  user: IUser
): Promise<IStandardAPIResponse<IUser>> {
  try {
    let res = await httpClient<IUser>(`admin/users/${userId}`, 'PATCH', user);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function removeAUser(
  userId: string
): Promise<IStandardAPIResponse<IUser>> {
  try {
    let res = await httpClient<IUser>(`admin/users/${userId}`, 'DELETE');
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function searchUsers(
  searchObj: IUserSearch
): Promise<IStandardAPIResponse<IUser[]>> {
  try {
    let res = await httpClient<IUser[]>(`users`, 'GET', searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function sendEmailInviteForUsers(
  userIds: string[]
): Promise<IStandardAPIResponse<IEmailInvite>> {
  try {
    let res = await httpClient<IEmailInvite>(`send-emails`, 'POST', {
      userIds: userIds
    });
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
