import { IStandardAPIResponse, IPartnerUser } from '../vm';
import { httpClient } from './UtilService';

export async function addAPartnerUser(
  obj: IPartnerUser
): Promise<IStandardAPIResponse<IPartnerUser>> {
  try {
    let res = await httpClient<IPartnerUser>(`partners/users`, 'PUT', obj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function updateAPartnerUser(
  partnerId: string,
  obj: IPartnerUser
): Promise<IStandardAPIResponse<IPartnerUser>> {
  try {
    let res = await httpClient<IPartnerUser>(
      `partners/users/${partnerId}`,
      'PATCH',
      obj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function removeAPartnerUser(
  partnerId: string
): Promise<IStandardAPIResponse<IPartnerUser>> {
  try {
    let res = await httpClient<IPartnerUser>(
      `partners/users/${partnerId}`,
      'DELETE'
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function searchPartnerUsers(
  //   partnerId: string,
  obj: any
): Promise<IStandardAPIResponse<Array<IPartnerUser>>> {
  try {
    let res = await httpClient<Array<IPartnerUser>>(
      `partners/users`,
      'GET',
      obj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
