import {
  IAdmin,
  IAdminSearch,
  IStandardAPIResponse,
  IOrganizationalDetails,
} from '../../vm';
import { httpClient } from '../UtilService';

export async function updateOrganizationalDetails(
  obj: IOrganizationalDetails
): Promise<IStandardAPIResponse<IOrganizationalDetails>> {
  try {
    let res = await httpClient<IOrganizationalDetails>(
      `organizational-details`,
      'PATCH',
      obj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getOrganizationalDetails(): Promise<
  IStandardAPIResponse<IOrganizationalDetails>
> {
  try {
    let res = await httpClient<IOrganizationalDetails>(
      `organizational-details`,
      'GET'
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function addAAdmin(
  user: IAdmin
): Promise<IStandardAPIResponse<IAdmin>> {
  try {
    let res = await httpClient<IAdmin>(`admins`, 'PUT', user);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function updateAAdmin(
  userId: string,
  user: IAdmin
): Promise<IStandardAPIResponse<IAdmin>> {
  try {
    let res = await httpClient<IAdmin>(`admins/${userId}`, 'PATCH', user);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function removeAAdmin(
  userId: string
): Promise<IStandardAPIResponse<IAdmin>> {
  try {
    let res = await httpClient<IAdmin>(`admins/${userId}`, 'DELETE');
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function searchAdmins(
  searchObj: IAdminSearch
): Promise<IStandardAPIResponse<IAdmin[]>> {
  try {
    let res = await httpClient<IAdmin[]>(`company-admins`, 'GET', searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
